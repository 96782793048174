import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { AxiosResponse } from "axios";
import { all, call, put, select, takeLatest } from "redux-saga/effects";
import { axiosCsisApi } from "@csis.com/tip/src/App";
import {
  STRINGIFY_QUERY_PARAMS,
  handleRequestError,
} from "@csis.com/tip/src/api/utils";
import { LatestSearches } from "@csis.com/tip/src/components/shared/SearchComboInput/AdvancedSearchCard/types";
import { PredefinedResultsPerPage } from "@csis.com/tip/src/models/pagination/constants";
import {
  getUserPreferences,
  getUserPreferencesForTickets,
} from "@csis.com/tip/src/userPreferences/selectors";
import {
  updateUserPreferences,
  fetchSuccess as userPreferencesFetchSuccess,
} from "@csis.com/tip/src/userPreferences/slice";
import {
  SearchPagePreferences,
  UserPreferences,
} from "@csis.com/tip/src/userPreferences/types";
import {
  generateColumnsBasedOnSettings,
  generateColumnsOrderSetting,
  generateColumnsVisibilitySetting,
} from "@csis.com/tip/src/userPreferences/utils";
import { downloadBlobForUser } from "@csis.com/tip/src/utils/downloadBlob";
import { getSelectedOrgId } from "../../Profile/Security/selectors";
import { TicketsColumns, columns } from "./Table/columns";
import { TicketsResponse } from "./api/types";
import { mapTicketsStartAndEndDateToCorrectType } from "./api/utils";
import { getTicketsColumns } from "./selectors";
import { QueryParams, TicketRow } from "./types";

interface StateSlice {
  tickets: TicketRow[] | null;
  hasNextPage: boolean;
  isTicketsPending: boolean;
  ticketsFetchError: string | null;
  columns: TicketsColumns;

  isCsvExportPending: boolean;
}
const initialState: StateSlice = {
  tickets: null,
  hasNextPage: false,
  isTicketsPending: false,
  ticketsFetchError: null,
  columns: columns,
  isCsvExportPending: false,
};

const ticketsSlice = createSlice({
  name: "tickets",
  initialState: initialState,
  reducers: {
    fetchTickets(_state, _action: PayloadAction<Partial<QueryParams>>) {
      //empty handled by saga
    },
    setTicketsPending(state) {
      state.isTicketsPending = true;
      state.ticketsFetchError = null;
      state.tickets = [];
    },
    setFetchTicketsError(state, action: PayloadAction<string>) {
      state.isTicketsPending = false;
      state.ticketsFetchError = action.payload;
      state.tickets = [];
    },
    fetchTicketsSuccess(state, action: PayloadAction<TicketRow[]>) {
      state.isTicketsPending = false;
      state.ticketsFetchError = null;
      state.tickets = action.payload;
    },
    reorderColumns(state, action: PayloadAction<TicketsColumns>) {
      state.columns = action.payload;
    },
    setHasNextPage(state, action: PayloadAction<boolean>) {
      state.hasNextPage = action.payload;
    },

    exportTicketsCsv(_state, _action: PayloadAction<Partial<QueryParams>>) {
      //empty handled by saga
    },
    setCsvExportPending(state) {
      state.isCsvExportPending = true;
    },
    setCsvExportComplete(state) {
      state.isCsvExportPending = false;
    },

    setResultsPerPageUserPreference(
      _state,
      _action: PayloadAction<PredefinedResultsPerPage>
    ) {
      //empty handled by saga
    },
    setLatestSearchesUserPreference(
      _state,
      _action: PayloadAction<LatestSearches>
    ) {
      //empty handled by saga
    },
  },
});

export default ticketsSlice.reducer;

export const {
  fetchTickets,
  setTicketsPending,
  setFetchTicketsError,
  fetchTicketsSuccess,
  reorderColumns,
  setHasNextPage,
  exportTicketsCsv,
  setCsvExportPending,
  setCsvExportComplete,
  setResultsPerPageUserPreference,
  setLatestSearchesUserPreference,
} = ticketsSlice.actions;

// Async stuff - sagas

function* fetchTicketsSaga(action: PayloadAction<Partial<QueryParams>>) {
  yield put(setTicketsPending());
  try {
    const selectedOrgId: string = yield select(getSelectedOrgId);
    const params = mapTicketsStartAndEndDateToCorrectType(action.payload);
    const paramsWithOrgId = { ...params, organization_id: selectedOrgId };

    const response: AxiosResponse<TicketsResponse> = yield call(
      axiosCsisApi.getTicketsApi10TicketGet,
      paramsWithOrgId,
      STRINGIFY_QUERY_PARAMS
    );

    yield put(fetchTicketsSuccess(response.data.payload.page));
    yield put(setHasNextPage(response.data.payload.has_next));
  } catch (e) {
    const errorMessage = handleRequestError(e);
    yield put(setFetchTicketsError(errorMessage));
  }
}

function* exportTicketsCsvSaga(action: PayloadAction<Partial<QueryParams>>) {
  yield put(setCsvExportPending());

  try {
    const selectedOrgId: string = yield select(getSelectedOrgId);
    const params = mapTicketsStartAndEndDateToCorrectType(action.payload);
    const paramsWithOrgId = { ...params, organization_id: selectedOrgId };

    const response: AxiosResponse<Blob> = yield call(
      axiosCsisApi.getExportCsvApi10TicketExportCsvGet,
      paramsWithOrgId,
      {
        format: "blob",
        ...STRINGIFY_QUERY_PARAMS,
      }
    );

    const blob = response.data;
    downloadBlobForUser(blob, "tickets_csv.csv");

    yield put(setCsvExportComplete());
  } catch (e) {
    yield put(setCsvExportComplete());
  }
}

function* reactToColumnsUpdateAndUpdateUserPreferencesSaga(
  action: PayloadAction<TicketsColumns>
) {
  // the user updated the columns so, we "update"
  // the prefs server side on the background
  const newColumns = action.payload;
  const newColumnsOrderSettings = generateColumnsOrderSetting(newColumns);
  const newColumnsVisibilitySettings =
    generateColumnsVisibilitySetting(newColumns);
  const userPrefs: UserPreferences = yield select(getUserPreferences);

  if (userPrefs) {
    const newPrefs = { ...userPrefs };

    newPrefs.tickets = {
      ...newPrefs.tickets,
      columnsVisibility: newColumnsVisibilitySettings,
      columnsOrder: newColumnsOrderSettings,
    };

    yield put(updateUserPreferences(newPrefs));
  }
}

function* reactToUserPreferencesResponse() {
  const userPrefs: SearchPagePreferences | undefined = yield select(
    getUserPreferencesForTickets
  );
  const columns: TicketsColumns = yield select(getTicketsColumns);

  if (userPrefs && userPrefs.columnsOrder && userPrefs.columnsVisibility) {
    // generate columns based on the saved user preferences
    const columnsFromSettings = generateColumnsBasedOnSettings(
      userPrefs.columnsOrder,
      userPrefs.columnsVisibility,
      columns
    );
    yield put(reorderColumns(columnsFromSettings));
  }
}

function* setResultsPerPageUserPreferenceSaga(
  action: PayloadAction<PredefinedResultsPerPage>
) {
  const userPrefs: UserPreferences = yield select(getUserPreferences);

  if (userPrefs) {
    const newPrefs = { ...userPrefs };

    newPrefs.tickets = {
      ...newPrefs.tickets,
      resultsPerPage: action.payload,
    };

    yield put(updateUserPreferences(newPrefs));
  }
}

function* setLatestSearchesUserPreferenceSaga(
  action: PayloadAction<LatestSearches>
) {
  const userPrefs: UserPreferences = yield select(getUserPreferences);

  if (userPrefs) {
    const newPrefs = { ...userPrefs };

    newPrefs.tickets = {
      ...newPrefs.tickets,
      latestSearches: action.payload,
    };

    yield put(updateUserPreferences(newPrefs));
  }
}

function* actionWatcher() {
  yield takeLatest(fetchTickets.toString(), fetchTicketsSaga);
  yield takeLatest(exportTicketsCsv.toString(), exportTicketsCsvSaga);
  yield takeLatest(
    userPreferencesFetchSuccess.toString(),
    reactToUserPreferencesResponse
  );
  yield takeLatest(
    reorderColumns.toString(),
    reactToColumnsUpdateAndUpdateUserPreferencesSaga
  );
  yield takeLatest(
    setResultsPerPageUserPreference.toString(),
    setResultsPerPageUserPreferenceSaga
  );
  yield takeLatest(
    setLatestSearchesUserPreference.toString(),
    setLatestSearchesUserPreferenceSaga
  );
}

export function* ticketsSagas() {
  yield all([actionWatcher()]);
}
