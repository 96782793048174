import { axiosCsisApi } from "@csis.com/tip/src/App";
import {
  GetIpRangesApi10OrganizationIpRangeGetParams,
  IPRangeCreateBody,
  IPRangeUpdateBody,
} from "@csis.com/tip/src/api/openapi/data-contracts";

export async function fetchIpRangesApi(
  params: GetIpRangesApi10OrganizationIpRangeGetParams
) {
  // todo change this when ready
  return axiosCsisApi.getIpRangesApi10OrganizationIpRangeGet(params);
}

export async function postIpRangesApi(data: IPRangeCreateBody) {
  return axiosCsisApi.addIpRangeApi10OrganizationIpRangePost(data);
}

export async function updateIpRangesApi(
  externalId: string,
  data: IPRangeUpdateBody
) {
  return axiosCsisApi.updateIpRangeApi10OrganizationIpRangeExternalIdPut(
    externalId,
    data
  );
}

export async function deleteIpRangesApi(
  params: { externalId: string },
  organizationId: string
) {
  return axiosCsisApi.deleteRangeApi10OrganizationIpRangeExternalIdDelete({
    ...params,
    organization_id: organizationId,
  });
}
