import axios, { AxiosResponse } from "axios";
import { axiosCsisApi, axiosInstance } from "../App";
import { getCookieValue, setCookie } from "../pages/Login/utils";
import { AUTH_API_ENDPOINTS } from "./apiEndpoints";

async function refreshAccessToken(refreshToken: string) {
  return await axiosInstance.post(
    process.env.REACT_APP_CSIS_AUTH_ENDPOINT + AUTH_API_ENDPOINTS.token,
    {
      client_id: process.env.REACT_APP_CSIS_CLIENT_ID,
      grant_type: "refresh_token",
      refresh_token: refreshToken,
    },
    {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    }
  );
}

// a simple type to store the resolve and reject functions of a promise
type QueueItem = {
  resolve: (value: string | null) => void;
  reject: (reason?: Error) => void;
};
let isRefreshing = false;
// This array is used to store items that have failed processing, allowing for retry attempts or error handling.
let failedQueue: QueueItem[] = [];

// a simple function that resolves or rejects the promises in the failedQueue array
const processQueue = (error: Error | null, token: string | null = null) => {
  failedQueue.forEach((prom) => {
    if (error) {
      prom.reject(error);
    } else {
      prom.resolve(token);
    }
  });

  failedQueue = [];
};

// This function intercepts/manages error responses with a 401 status code.
// It will attempt to renew the access token using the refresh token.
// If the refresh token has expired, it will redirect the user to the /logout page.
// If the refresh token is valid, it will establish a new access token and
// resend the original request without any user interference, ensuring a smooth user experience.
// The function also caters to situations where multiple simultaneous requests are issued
// and there's a need to wait for the new access token to be retrieved.
// This is monitored through the 'isRefreshing' variable
// and the 'failedQueue' array, which stores the requests that were unsuccessful
// due to a 401 status code and are now pending the retrieval of a new access token.
export function createAxiosResponseInterceptor() {
  axiosCsisApi.instance.interceptors.response.use(
    function (response) {
      return response;
    },
    async function (error) {
      const originalRequest = error.config;

      if (
        error.response.status === 401 &&
        !originalRequest._retry &&
        !originalRequest.url.includes(AUTH_API_ENDPOINTS.token)
      ) {
        if (isRefreshing) {
          // if we are here, it means multiple requests are being fired at the same time (and we get 401 status code in all of them)
          // so we need to wait for the new access token to be fetched in the first one
          // and once that is done, release/resolve the rest of the requests with the new token
          return new Promise(function (resolve, reject) {
            failedQueue.push({ resolve, reject });
          })
            .then((token) => {
              originalRequest.headers["Authorization"] = "Bearer " + token;
              return axios(originalRequest);
            })
            .catch((err) => {
              return Promise.reject(err);
            });
        }

        originalRequest._retry = true;
        isRefreshing = true;

        try {
          // if here, it means the access token is expired
          // we get a new access token using the refresh token
          const refreshTokenFromCookie = getCookieValue("refreshToken");
          const {
            data,
          }: AxiosResponse<{
            access_token: string;
            id_token: string;
            refresh_token?: string;
          }> = await refreshAccessToken(refreshTokenFromCookie);

          // if for whatever reason the response is ok but there is no access token
          // throw an error
          if (!data?.access_token) {
            throw new Error("No access token");
          }

          setCookie("bearerToken", data.access_token);
          axiosCsisApi.setSecurityData(data.access_token);
          // this is needed for the calls that do not use the axiosCsisApi - to be removed when all calls are using the axiosCsisApi
          axios.defaults.headers.common = {
            Authorization: `Bearer ${data.access_token}`,
          };
          originalRequest.headers["Authorization"] =
            "Bearer " + data.access_token;

          if (data.refresh_token) {
            setCookie("refreshToken", data.refresh_token);
          }

          processQueue(null, data.access_token);
          return axios(originalRequest);
        } catch (err) {
          processQueue(err as Error, null);
          // if here, it means the refresh token is expired
          // redirect user to /logout
          window.location.href = "/logout";
          return Promise.reject(err);
        } finally {
          isRefreshing = false;
        }
      }

      return Promise.reject(error);
    }
  );
}
