import { axiosCsisApi } from "@csis.com/tip/src/App";
import {
  addGroupByParamBasedOnRange,
  mapAlertsStartAndEndDateToCorrectType,
} from "@csis.com/tip/src/pages/Alerts/AlertsSearch/api/utils";
import { QueryParams } from "../../types";

export async function fetchStatusStatisticsApi(
  queryParams: Partial<QueryParams>,
  organizationId: string
) {
  const params = mapAlertsStartAndEndDateToCorrectType(queryParams);

  return axiosCsisApi.getStatisticsStatusApi10AlertStatisticsStatusGet({
    ...params,
    organization_id: organizationId,
  });
}

export async function fetchStatusStatisticsHistogramApi(
  queryParams: Partial<QueryParams>,
  organizationId: string
) {
  const params = addGroupByParamBasedOnRange(
    mapAlertsStartAndEndDateToCorrectType(queryParams)
  );

  return axiosCsisApi.getStatisticsStatusHistogramApi10AlertStatisticsStatusHistogramGet(
    {
      ...params,
      organization_id: organizationId,
    }
  );
}
