import { axiosCsisApi } from "@csis.com/tip/src/App";

export async function fetchArticleApi(id: string, organizationId: string) {
  return axiosCsisApi.getArticleApi10ArticleExternalIdGet({
    externalId: id,
    organization_id: organizationId,
  });
}

export async function fetchArticleAttachmentApi(
  articleId: string,
  attachmentId: string,
  organizationId: string
) {
  return axiosCsisApi.getAttachmentApi10ArticleExternalIdAttachmentAttachmentExternalIdGet(
    {
      attachmentExternalId: attachmentId,
      externalId: articleId,
      organization_id: organizationId,
    },
    {
      format: "blob",
    }
  );
}

export async function fetchRelatedArticlesApi(
  id: string,
  organizationId: string
) {
  return axiosCsisApi.getRelatedArticlesApi10ArticleExternalIdRelatedGet({
    externalId: id,
    limit: 5,
    organization_id: organizationId,
  });
}
