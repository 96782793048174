import { axiosCsisApi } from "@csis.com/tip/src/App";
import { STRINGIFY_QUERY_PARAMS } from "@csis.com/tip/src/api/utils";
import { mapUrlsStartAndEndDateToCorrectType2 } from "@csis.com/tip/src/pages/Products/Phishing/UrlsSearch/api/utils";
import { QueryParams } from "../../types";

export async function fetchStatusStatisticsApi(
  queryParams: Partial<QueryParams>,
  organizationId: string
) {
  const params = mapUrlsStartAndEndDateToCorrectType2(queryParams);

  return axiosCsisApi.getUrlStatsStatusApi10PhishingUrlStatisticsStatusGet(
    {
      ...params,
      organization_id: organizationId,
    },
    STRINGIFY_QUERY_PARAMS
  );
}
