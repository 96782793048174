import { axiosCsisApi } from "@csis.com/tip/src/App";
import { QueryParams } from "../../types";

export async function fetchStatusStatisticsApi(
  params: Partial<QueryParams>,
  organizationId: string
) {
  return axiosCsisApi.getVictimStatsStateApi10CompromisedDataCredentialReportStatisticsStatusGet(
    {
      ...params,
      organization_id: organizationId,
    }
  );
}
