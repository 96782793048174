import { ColumnInterface } from "@csis.com/components/src/atoms/Table/types";
import { TranslationKey } from "@csis.com/tip/src/translations/types";
import { victimsInfoKeys } from "../constants";
import { CompDataKey } from "../types";

export type VictimsColumns = ColumnInterface<CompDataKey, TranslationKey>[];

export const columns: VictimsColumns = [
  { key: victimsInfoKeys.DISPLAY_ID, name: "id", isSortable: true },
  {
    key: victimsInfoKeys.STATUS,
    name: "status",
    isSortable: true,
    isCenterAligned: true,
  },
  {
    key: victimsInfoKeys.USERNAME,
    name: "username",
    isSortable: true,
  },
  { key: victimsInfoKeys.SOURCE, name: "source", isSortable: true },
  { key: victimsInfoKeys.VARIANT, name: "variant", isSortable: true },
  { key: victimsInfoKeys.EMAIL, name: "email", isSortable: true },
  { key: victimsInfoKeys.URL, name: "url", isSortable: false },
  { key: victimsInfoKeys.RELEASED, name: "release_date", isSortable: true },
  { key: victimsInfoKeys.SUBMITTED, name: "submitted", isSortable: true },
];
