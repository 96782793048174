/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
  AddUsersToUserGroupsApi10OrganizationUserGroupExternalIdUserPutParams,
  AdminUserPreviewListResponse,
  ArtifactType,
  AuditlogEventPageDetailResponse,
  BaseResponse,
  BodyAcknowledgeEmergencyResponseCaseApi10EmergencyResponseCaseErCaseExternalIdCallbackTigerTeamPost,
  BodyUploadAttachmentApi10TicketAttachmentPost,
  BodyUploadErCaseAttachmentApi10AdminEmergencyResponseCaseErCaseExternalIdAttachmentPost,
  BodyUploadErCaseAttachmentWithSubmissionCodeApi10EmergencyResponseCaseErCaseExternalIdAttachmentPost,
  BodyUploadRetainerAttachmentApi10RetainerAttachmentPost,
  BodyUploadRetainerAttachmentByAdminApi10AdminRetainerRetainerExternalIdAttachmentPost,
  BrowserSuspiciousVisisListResponse,
  BrowserSuspiciousVisitListResponse,
  BusinessUnitPreviewPageDetailResponse,
  BusinessUnitWithRetainerPreviewPageDetailResponse,
  ChangePasswordRequestBody,
  ClientListResponse,
  CreateClientApi10OrganizationClientPostParams,
  CreateUserGroupApi10OrganizationUserGroupPostParams,
  DeleteClientApi10OrganizationClientExternalIdDeleteParams,
  DeleteErCaseAttachmentWithSubmissionCodeApi10EmergencyResponseCaseErCaseExternalIdAttachmentErCaseAttachmentExternalIdDeleteParams,
  DeleteRangeApi10OrganizationIpRangeExternalIdDeleteParams,
  DeleteUserApi10OrganizationUserExternalIdDeleteParams,
  DetailResponse,
  DetailResponseBulkUrlSubmitResponse,
  DetailResponseCsrfToken,
  DetailResponseLegacyStatsResponse,
  DetailResponsePortalnewsPreviewPage,
  DetailResponseRetainerPreview,
  DetailResponseURLFull,
  DownloadErCaseAttachmentWithSubmissionCodeApi10EmergencyResponseCaseErCaseExternalIdAttachmentErCaseAttachmentExternalIdGetParams,
  DownloadLatestReportApi10ReportReportExternalIdDownloadGetParams,
  EmailPasswordResetInput,
  EmergencyResponseCaseAttachmentsPreviewDetailResponse,
  EmergencyResponseCaseCreateBody,
  EmergencyResponseCasePreviewDetailResponse,
  EmergencyResponseCasePreviewPageDetailResponse,
  EmergencyResponseCaseUpdateBody,
  ERCaseQuestionnairePreviewDetailResponse,
  ERCaseQuestionnaireUpdateBody,
  ERCaseSubmissionPreviewDetailResponse,
  ErrorResponse,
  EscalationContactListResponse,
  EscalationContactUpdateBody,
  ExportUrlsApi10PhishingUrlExportCsvGetParams,
  ExportUrlsApi20PhishingUrlExportCsvGetParams,
  GenerateReportApi10PhishingReportGeneratePostParams,
  GenerateReportApi10ReportPostParams,
  GetAlertersApi10AlertAlerterGetParams,
  GetAlertsApi10AlertGetParams,
  GetAlertsApi20AlertGetParams,
  GetAnalysesApi10RemoteForensicsGetParams,
  GetAnalysesApi20RemoteForensicsGetParams,
  GetArticleApi10ArticleExternalIdGetParams,
  GetArticlesApi10ArticleGetParams,
  GetArticlesApi20ArticleGetParams,
  GetAsnsApi10PhishingAsnGetParams,
  GetAttachmentApi10ArticleExternalIdAttachmentAttachmentExternalIdGetParams,
  GetBrandsApi10PhishingBrandGetParams,
  GetBusinessUnitsApi10AdminBusinessUnitGetParams,
  GetBusinessUnitsWithRetainersByAdminApi10AdminRetainerGetParams,
  GetCategoriesApi10ArticleCategoryGetParams,
  GetClientRolesApi10OrganizationClientRolesGetParams,
  GetClientsApi10OrganizationClientGetParams,
  GetContentArtifactApi10PhishingContentUrlHashArtifactTypeContentIdGetParams,
  GetContentsApi10PhishingContentUrlHashGetParams,
  GetContentsApi20PhishingContentUrlHashGetParams,
  GetCountriesApi10PhishingCountryGetParams,
  GetCreditCardExportCsvApi10CompromisedDataCreditCardReportExportCsvGetParams,
  GetCreditCardExportCsvApi20CompromisedDataCreditCardReportExportCsvGetParams,
  GetCreditCardHistogramApi10CompromisedDataCreditCardReportStatisticsMatcherHistogramGetParams,
  GetCreditCardHistogramApi20CompromisedDataCreditCardReportStatisticsMatcherHistogramGetParams,
  GetCreditCardReportsApi10CompromisedDataCreditCardReportGetParams,
  GetCreditCardReportsApi20CompromisedDataCreditCardReportGetParams,
  GetCreditCardSourceStatsApi10CompromisedDataCreditCardReportStatisticsSourceGetParams,
  GetCreditCardSourceStatsApi20CompromisedDataCreditCardReportStatisticsSourceGetParams,
  GetCreditCardStatsStateApi10CompromisedDataCreditCardReportStatisticsStatusGetParams,
  GetCreditCardStatsStateApi20CompromisedDataCreditCardReportStatisticsStatusGetParams,
  GetCustomerApi10PhishingCustomerGetParams,
  GetEmergencyResponseCaseInfoApi10EmergencyResponseCaseErCaseExternalIdGetParams,
  GetEmergencyResponseCasesApi10AdminEmergencyResponseCaseGetParams,
  GetEntityApi10InvestigationEntityGetParams,
  GetEntityApi20InvestigationEntityGetParams,
  GetEntityTypesApi10InvestigationEntityTypeGetParams,
  GetEntityTypesApi20InvestigationEntityTypeGetParams,
  GetEscalationContactsApi10OrganizationEscalationContactGetParams,
  GetEventsApi10AuditlogGetParams,
  GetExportCsvApi10AlertExportCsvGetParams,
  GetExportCsvApi10InvestigationExportCsvGetParams,
  GetExportCsvApi10TicketExportCsvGetParams,
  GetExportCsvApi20InvestigationExportCsvGetParams,
  GetIpRangesApi10OrganizationIpRangeGetParams,
  GetPortalNewsApi10PortalNewsGetParams,
  GetRelatedArticlesApi10ArticleExternalIdRelatedGetParams,
  GetRelatedArticlesApi20ArticleExternalIdRelatedGetParams,
  GetReportsApi10ReportGetParams,
  GetReportsApi20ReportGetParams,
  GetRequestOptionApi10ReportRequestOptionGetParams,
  GetStatisticsAlerterApi10AlertStatisticsAlerterGetParams,
  GetStatisticsAlerterApi20AlertStatisticsAlerterGetParams,
  GetStatisticsAlerterHistogramApi10AlertStatisticsAlerterHistogramGetParams,
  GetStatisticsAlerterHistogramApi20AlertStatisticsAlerterHistogramGetParams,
  GetStatisticsInfectionTypeHistogramApi10RemoteForensicsStatisticsInfectionTypeHistogramGetParams,
  GetStatisticsInfectionTypeHistogramApi20RemoteForensicsStatisticsInfectionTypeHistogramGetParams,
  GetStatisticsInfectionVectorHistogramApi10RemoteForensicsStatisticsInfectionTypeGetParams,
  GetStatisticsInfectionVectorHistogramApi20RemoteForensicsStatisticsInfectionTypeGetParams,
  GetStatisticsPlatformApi10RemoteForensicsStatisticsPlatformGetParams,
  GetStatisticsPlatformApi20RemoteForensicsStatisticsPlatformGetParams,
  GetStatisticsPlatformHistogramApi10RemoteForensicsStatisticsPlatformHistogramGetParams,
  GetStatisticsPlatformHistogramApi20RemoteForensicsStatisticsPlatformHistogramGetParams,
  GetStatisticsSeverityApi10AlertStatisticsSeverityGetParams,
  GetStatisticsSeverityApi10TicketStatisticsSeverityGetParams,
  GetStatisticsSeverityApi20AlertStatisticsSeverityGetParams,
  GetStatisticsSeverityHistogramApi10AlertStatisticsSeverityHistogramGetParams,
  GetStatisticsSeverityHistogramApi10TicketStatisticsSeverityHistogramGetParams,
  GetStatisticsSeverityHistogramApi20AlertStatisticsSeverityHistogramGetParams,
  GetStatisticsStatusApi10AlertStatisticsStatusGetParams,
  GetStatisticsStatusApi10RemoteForensicsStatisticsStatusGetParams,
  GetStatisticsStatusApi10TicketStatisticsStatusGetParams,
  GetStatisticsStatusApi20AlertStatisticsStatusGetParams,
  GetStatisticsStatusApi20RemoteForensicsStatisticsStatusGetParams,
  GetStatisticsStatusHistogramApi10AlertStatisticsStatusHistogramGetParams,
  GetStatisticsStatusHistogramApi10RemoteForensicsStatisticsStatusHistogramGetParams,
  GetStatisticsStatusHistogramApi20AlertStatisticsStatusHistogramGetParams,
  GetStatisticsStatusHistogramApi20RemoteForensicsStatisticsStatusHistogramGetParams,
  GetStatisticsTypeApi10TicketStatisticsTypeGetParams,
  GetStatisticsTypeHistogramApi10TicketStatisticsTypeHistogramGetParams,
  GetTicketsApi10TicketGetParams,
  GetTicketsApi20TicketGetParams,
  GetTypesApi10ReportTypeGetParams,
  GetUrlApi10PhishingUrlUrlHashGetParams,
  GetUrlsApi10PhishingUrlGetParams,
  GetUrlsApi20PhishingUrlGetParams,
  GetUrlStatsLegacyApi10PhishingUrlStatisticsLegacyGetParams,
  GetUrlStatsLegacyApi20PhishingUrlStatisticsLegacyGetParams,
  GetUrlStatsStatusApi10PhishingUrlStatisticsStatusGetParams,
  GetUrlStatsStatusApi20PhishingUrlStatisticsStatusGetParams,
  GetUserGroupsApi10OrganizationUserGroupGetParams,
  GetUserRolesApi10OrganizationUserRolesGetParams,
  GetVictimExportCsvApi10CompromisedDataCredentialReportExportCsvGetParams,
  GetVictimExportCsvApi20CompromisedDataCredentialReportExportCsvGetParams,
  GetVictimHistogramApi10CompromisedDataCredentialReportStatisticsMatcherHistogramGetParams,
  GetVictimHistogramApi20CompromisedDataCredentialReportStatisticsMatcherHistogramGetParams,
  GetVictimReportsApi10CompromisedDataCredentialReportGetParams,
  GetVictimReportsApi20CompromisedDataCredentialReportGetParams,
  GetVictimSourceStatsApi10CompromisedDataCredentialReportStatisticsSourceGetParams,
  GetVictimSourceStatsApi20CompromisedDataCredentialReportStatisticsSourceGetParams,
  GetVictimStatsStateApi10CompromisedDataCredentialReportStatisticsStatusGetParams,
  GetVictimStatsStateApi20CompromisedDataCredentialReportStatisticsStatusGetParams,
  HTTPValidationError,
  IPRangeCreateBody,
  IPRangeListResponse,
  IPRangePreviewResponse,
  IPRangeUpdateBody,
  ListResponseASNPreview,
  ListResponseBrandPreview,
  ListResponseCountryPreview,
  ListResponseCustomerFull,
  ListResponsePortalAuditLogEntry,
  MDFESetting,
  NotificationDigestPreferenceSet,
  NotificationDigestPreferenceSetListResponse,
  NotificationDigestPreferenceSimpleSetListResponse,
  PortalsFrontendApiAlertsDeprecatedAppAlertDetailResponse,
  PortalsFrontendApiAlertsDeprecatedAppAlerterPreviewListResponse,
  PortalsFrontendApiAlertsDeprecatedAppAlertPreviewPageDetailResponse,
  PortalsFrontendApiAlertsDeprecatedAppAlertUpdateBody,
  PortalsFrontendApiAlertsModelAlertDetailResponse,
  PortalsFrontendApiAlertsModelAlerterPreviewListResponse,
  PortalsFrontendApiAlertsModelAlertPreviewPageDetailResponse,
  PortalsFrontendApiAlertsModelAlertUpdateBody,
  PortalsFrontendApiArticlesAppArticleCategoryDetailResponse,
  PortalsFrontendApiArticlesAppArticleDetailResponse,
  PortalsFrontendApiArticlesAppArticlePreviewPageDetailResponse,
  PortalsFrontendApiArticlesDeprecatedAppArticleCategoryDetailResponse,
  PortalsFrontendApiArticlesDeprecatedAppArticleDetailResponse,
  PortalsFrontendApiArticlesDeprecatedAppArticlePreviewPageDetailResponse,
  PortalsFrontendApiForensicsDeprecatedAppAmcacheEntryListResponse,
  PortalsFrontendApiForensicsDeprecatedAppAnalysisDetailResponse,
  PortalsFrontendApiForensicsDeprecatedAppAnalysisPatchBody,
  PortalsFrontendApiForensicsDeprecatedAppAnalysisPreviewPageDetailResponse,
  PortalsFrontendApiForensicsDeprecatedAppApplicationListResponse,
  PortalsFrontendApiForensicsDeprecatedAppConfidenceRegistryKeyListResponse,
  PortalsFrontendApiForensicsDeprecatedAppDetectionToolListResponse,
  PortalsFrontendApiForensicsDeprecatedAppDNSQueryListResponse,
  PortalsFrontendApiForensicsDeprecatedAppDownloadInfoListResponse,
  PortalsFrontendApiForensicsDeprecatedAppExternalStorageListResponse,
  PortalsFrontendApiForensicsDeprecatedAppFilesystemRemoteAdminToolListResponse,
  PortalsFrontendApiForensicsDeprecatedAppMalwareDropperListResponse,
  PortalsFrontendApiForensicsDeprecatedAppMemoryAnalysisEntryListResponse,
  PortalsFrontendApiForensicsDeprecatedAppNetConnectionListResponse,
  PortalsFrontendApiForensicsDeprecatedAppNetOpenPortsListResponse,
  PortalsFrontendApiForensicsDeprecatedAppProcessNodeListResponse,
  PortalsFrontendApiForensicsDeprecatedAppRegistryDetectionListResponse,
  PortalsFrontendApiForensicsDeprecatedAppRegistryRemoteAdminToolListResponse,
  PortalsFrontendApiForensicsDeprecatedAppResultSummaryDetailResponse,
  PortalsFrontendApiForensicsDeprecatedAppScheduledTasksListResponse,
  PortalsFrontendApiForensicsDeprecatedAppSuspiciousExtensionsListResponse,
  PortalsFrontendApiForensicsDeprecatedAppSuspiciousFilesListResponse,
  PortalsFrontendApiForensicsDeprecatedAppSuspiciousHashListResponse,
  PortalsFrontendApiForensicsModelAmcacheEntryListResponse,
  PortalsFrontendApiForensicsModelAnalysisDetailResponse,
  PortalsFrontendApiForensicsModelAnalysisPatchBody,
  PortalsFrontendApiForensicsModelAnalysisPreviewPageDetailResponse,
  PortalsFrontendApiForensicsModelApplicationListResponse,
  PortalsFrontendApiForensicsModelConfidenceRegistryKeyListResponse,
  PortalsFrontendApiForensicsModelDetectionToolListResponse,
  PortalsFrontendApiForensicsModelDNSQueryListResponse,
  PortalsFrontendApiForensicsModelDownloadInfoListResponse,
  PortalsFrontendApiForensicsModelExternalStorageListResponse,
  PortalsFrontendApiForensicsModelFilesystemRemoteAdminToolListResponse,
  PortalsFrontendApiForensicsModelMalwareDropperListResponse,
  PortalsFrontendApiForensicsModelMemoryAnalysisEntryListResponse,
  PortalsFrontendApiForensicsModelNetConnectionListResponse,
  PortalsFrontendApiForensicsModelNetOpenPortsListResponse,
  PortalsFrontendApiForensicsModelProcessNodeListResponse,
  PortalsFrontendApiForensicsModelRegistryDetectionListResponse,
  PortalsFrontendApiForensicsModelRegistryRemoteAdminToolListResponse,
  PortalsFrontendApiForensicsModelResultSummaryDetailResponse,
  PortalsFrontendApiForensicsModelScheduledTasksListResponse,
  PortalsFrontendApiForensicsModelSuspiciousExtensionsListResponse,
  PortalsFrontendApiForensicsModelSuspiciousFilesListResponse,
  PortalsFrontendApiForensicsModelSuspiciousHashListResponse,
  PortalsFrontendApiInvestigationsDeprecatedAppEntityListResponse,
  PortalsFrontendApiInvestigationsDeprecatedAppListResponseEntityTypePayload,
  PortalsFrontendApiInvestigationsModelEntityListResponse,
  PortalsFrontendApiInvestigationsRouterListResponseEntityTypePayload,
  PortalsFrontendApiPhishingDeprecatedAppBulkUrlSubmitBody,
  PortalsFrontendApiPhishingDeprecatedAppDetailResponseContentFullPage,
  PortalsFrontendApiPhishingDeprecatedAppDetailResponseURLPreviewPage,
  PortalsFrontendApiPhishingModelsBulkUrlSubmitBody,
  PortalsFrontendApiPhishingRouterDetailResponseContentFullPage,
  PortalsFrontendApiPhishingRouterDetailResponseURLPreviewPage,
  PortalsFrontendApiReportingDeprecatedModelReportPreviewPageDetailResponse,
  PortalsFrontendApiReportingDeprecatedModelReportRequest,
  PortalsFrontendApiReportingDeprecatedRouterDetailResponseReportPreview,
  PortalsFrontendApiReportingDeprecatedRouterListResponseReportType,
  PortalsFrontendApiReportingDeprecatedRouterListResponseRequestOption,
  PortalsFrontendApiReportingModelReportPreviewPageDetailResponse,
  PortalsFrontendApiReportingModelReportRequest,
  PortalsFrontendApiReportingRouterDetailResponseReportPreview,
  PortalsFrontendApiReportingRouterListResponseReportType,
  PortalsFrontendApiReportingRouterListResponseRequestOption,
  PortalsFrontendApiRetainersDeprecatedAppRetainerCreateOrUpdateBody,
  PortalsFrontendApiRetainersModelsRetainerCreateOrUpdateBody,
  PortalsFrontendApiStraydataAppBulkReportUpdateBody,
  PortalsFrontendApiStraydataAppCreditCardPageResponse,
  PortalsFrontendApiStraydataAppDetailResponseBulkReportUpdateResponse,
  PortalsFrontendApiStraydataAppDetailResponseCreditCard,
  PortalsFrontendApiStraydataAppDetailResponseVictim,
  PortalsFrontendApiStraydataAppReportUpdateBody,
  PortalsFrontendApiStraydataAppVictimPageResponse,
  PortalsFrontendApiStraydataDepredatedAppBulkReportUpdateBody,
  PortalsFrontendApiStraydataDepredatedAppCreditCardPageResponse,
  PortalsFrontendApiStraydataDepredatedAppDetailResponseBulkReportUpdateResponse,
  PortalsFrontendApiStraydataDepredatedAppDetailResponseCreditCard,
  PortalsFrontendApiStraydataDepredatedAppDetailResponseVictim,
  PortalsFrontendApiStraydataDepredatedAppReportUpdateBody,
  PortalsFrontendApiStraydataDepredatedAppVictimPageResponse,
  PortalsFrontendApiTicketsDeprecatedAppTicketCommentCreateBody,
  PortalsFrontendApiTicketsDeprecatedAppTicketCommentPreviewDetailResponse,
  PortalsFrontendApiTicketsDeprecatedAppTicketCommentPreviewListResponse,
  PortalsFrontendApiTicketsDeprecatedAppTicketFullDetailResponse,
  PortalsFrontendApiTicketsDeprecatedAppTicketPreviewPageDetailResponse,
  PortalsFrontendApiTicketsModelTicketCommentCreateBody,
  PortalsFrontendApiTicketsModelTicketCommentPreviewDetailResponse,
  PortalsFrontendApiTicketsModelTicketCommentPreviewListResponse,
  PortalsFrontendApiTicketsModelTicketFullDetailResponse,
  PortalsFrontendApiTicketsModelTicketPreviewPageDetailResponse,
  ProfileDetailResponse,
  ProfilePreviewDetailResponse,
  ResendMessageType,
  RetainerAttachmentsPreviewDetailResponse,
  RetainerCreateBody,
  RetainerPreviewDetailResponse,
  RetainerTypePreviewListResponse,
  RetainerWithBusinessUnitPreviewDetailResponse,
  RetrieveAllErCaseAttachmentsWithSubmissionCodeApi10EmergencyResponseCaseErCaseExternalIdAttachmentGetParams,
  RoleDetailListResponse,
  SetErCaseQuestionnaireDataApi10EmergencyResponseCaseErCaseExternalIdQuestionnairePatchParams,
  SetPasswordRequestBody,
  SimpleNotificationDigestPreference,
  StatisticsCountListResponse,
  StatisticsHistogramCountListResponse,
  StorageCreate,
  StorageCreateResponse,
  StorageTypePreviewResponse,
  TicketCreateBody,
  TicketFeedbackCreateBody,
  TicketFeedbackPreviewDetailResponse,
  TicketFeedbackUpdateBody,
  TicketFlagTypePreviewListResponse,
  TicketUpdateBody,
  UndeleteUserApi10OrganizationUserExternalIdUndeletePatchParams,
  UpdateClientApi10OrganizationClientExternalIdPutParams,
  UpdateClientBody,
  UpdateEscalationContactsApi10OrganizationEscalationContactPostParams,
  UpdateUserApi10OrganizationUserExternalIdPutParams,
  UpdateUserBody,
  UpdateUserGroupApi10OrganizationUserGroupExternalIdPutParams,
  UploadErCaseAttachmentWithSubmissionCodeApi10EmergencyResponseCaseErCaseExternalIdAttachmentPostParams,
  UserContactInfoUpdate,
  UserGroupChangeUsers,
  UserGroupDetailListResponse,
  UserGroupDetailResponse,
  UserGroupUpdate,
  UserListResponse,
  UserOTPDetailResponse,
  UserOTPVerification,
} from "./data-contracts";
import { ContentType, HttpClient, RequestParams } from "./http-client";

export class Api<SecurityDataType = unknown> extends HttpClient<SecurityDataType> {
  /**
   * No description
   *
   * @name IndexApi10Get
   * @summary Index
   * @request GET:/api/1.0/
   * @secure
   */
  indexApi10Get = (params: RequestParams = {}) =>
    this.request<BaseResponse, ErrorResponse>({
      path: `/api/1.0/`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags tickets
   * @name GetFlagsApi10TicketFlagGet
   * @summary Get Flags
   * @request GET:/api/1.0/ticket/flag
   * @secure
   */
  getFlagsApi10TicketFlagGet = (params: RequestParams = {}) =>
    this.request<TicketFlagTypePreviewListResponse, ErrorResponse>({
      path: `/api/1.0/ticket/flag`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags tickets
   * @name GetTicketApi10TicketExternalIdGet
   * @summary Get Ticket
   * @request GET:/api/1.0/ticket/{external_id}
   * @secure
   */
  getTicketApi10TicketExternalIdGet = (externalId: string, params: RequestParams = {}) =>
    this.request<PortalsFrontendApiTicketsDeprecatedAppTicketFullDetailResponse, ErrorResponse | HTTPValidationError>({
      path: `/api/1.0/ticket/${externalId}`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags tickets
   * @name UpdateTicketApi10TicketExternalIdPatch
   * @summary Update Ticket
   * @request PATCH:/api/1.0/ticket/{external_id}
   * @secure
   */
  updateTicketApi10TicketExternalIdPatch = (externalId: string, data: TicketUpdateBody, params: RequestParams = {}) =>
    this.request<PortalsFrontendApiTicketsDeprecatedAppTicketFullDetailResponse, ErrorResponse | HTTPValidationError>({
      path: `/api/1.0/ticket/${externalId}`,
      method: "PATCH",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags tickets
   * @name GetTicketsApi10TicketGet
   * @summary Get Tickets
   * @request GET:/api/1.0/ticket/
   * @secure
   */
  getTicketsApi10TicketGet = (query: GetTicketsApi10TicketGetParams, params: RequestParams = {}) =>
    this.request<
      PortalsFrontendApiTicketsDeprecatedAppTicketPreviewPageDetailResponse,
      ErrorResponse | HTTPValidationError
    >({
      path: `/api/1.0/ticket/`,
      method: "GET",
      query: query,
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags tickets
   * @name CreateTicketApi10TicketPost
   * @summary Create Ticket
   * @request POST:/api/1.0/ticket/
   * @secure
   */
  createTicketApi10TicketPost = (data: TicketCreateBody, params: RequestParams = {}) =>
    this.request<PortalsFrontendApiTicketsDeprecatedAppTicketFullDetailResponse, ErrorResponse | HTTPValidationError>({
      path: `/api/1.0/ticket/`,
      method: "POST",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags tickets
   * @name GetCommentsApi10TicketExternalIdCommentGet
   * @summary Get Comments
   * @request GET:/api/1.0/ticket/{external_id}/comment
   * @secure
   */
  getCommentsApi10TicketExternalIdCommentGet = (externalId: string, params: RequestParams = {}) =>
    this.request<
      PortalsFrontendApiTicketsDeprecatedAppTicketCommentPreviewListResponse,
      ErrorResponse | HTTPValidationError
    >({
      path: `/api/1.0/ticket/${externalId}/comment`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags tickets
   * @name CreateCommentApi10TicketExternalIdCommentPost
   * @summary Create Comment
   * @request POST:/api/1.0/ticket/{external_id}/comment
   * @secure
   */
  createCommentApi10TicketExternalIdCommentPost = (
    externalId: string,
    data: PortalsFrontendApiTicketsDeprecatedAppTicketCommentCreateBody,
    params: RequestParams = {},
  ) =>
    this.request<
      PortalsFrontendApiTicketsDeprecatedAppTicketCommentPreviewDetailResponse,
      ErrorResponse | HTTPValidationError
    >({
      path: `/api/1.0/ticket/${externalId}/comment`,
      method: "POST",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags tickets
   * @name GetAttachmentApi10TicketTicketExternalIdAttachmentAttachmentExternalIdGet
   * @summary Get Attachment
   * @request GET:/api/1.0/ticket/{ticket_external_id}/attachment/{attachment_external_id}
   * @secure
   */
  getAttachmentApi10TicketTicketExternalIdAttachmentAttachmentExternalIdGet = (
    ticketExternalId: any,
    attachmentExternalId: any,
    params: RequestParams = {},
  ) =>
    this.request<any, ErrorResponse | HTTPValidationError>({
      path: `/api/1.0/ticket/${ticketExternalId}/attachment/${attachmentExternalId}`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags tickets
   * @name UploadAttachmentApi10TicketAttachmentPost
   * @summary Upload Attachment
   * @request POST:/api/1.0/ticket/attachment
   * @secure
   */
  uploadAttachmentApi10TicketAttachmentPost = (
    data: BodyUploadAttachmentApi10TicketAttachmentPost,
    params: RequestParams = {},
  ) =>
    this.request<any, ErrorResponse | HTTPValidationError>({
      path: `/api/1.0/ticket/attachment`,
      method: "POST",
      body: data,
      secure: true,
      type: ContentType.FormData,
      format: "json",
      ...params,
    });
  /**
   * @description Create a feedback for a ticket once per user
   *
   * @tags tickets
   * @name CreateFeedbackApi10TicketExternalIdFeedbackPost
   * @summary Create Feedback
   * @request POST:/api/1.0/ticket/{external_id}/feedback
   * @secure
   */
  createFeedbackApi10TicketExternalIdFeedbackPost = (
    externalId: string,
    data: TicketFeedbackCreateBody,
    params: RequestParams = {},
  ) =>
    this.request<TicketFeedbackPreviewDetailResponse, ErrorResponse | HTTPValidationError>({
      path: `/api/1.0/ticket/${externalId}/feedback`,
      method: "POST",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * @description Update user feedback for a ticket
   *
   * @tags tickets
   * @name UpdateFeedbackApi10TicketExternalIdFeedbackFeedbackIdPatch
   * @summary Update Feedback
   * @request PATCH:/api/1.0/ticket/{external_id}/feedback/{feedback_id}
   * @secure
   */
  updateFeedbackApi10TicketExternalIdFeedbackFeedbackIdPatch = (
    feedbackId: string,
    externalId: string,
    data: TicketFeedbackUpdateBody,
    params: RequestParams = {},
  ) =>
    this.request<TicketFeedbackPreviewDetailResponse, ErrorResponse | HTTPValidationError>({
      path: `/api/1.0/ticket/${externalId}/feedback/${feedbackId}`,
      method: "PATCH",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags tickets
   * @name GetExportCsvApi10TicketExportCsvGet
   * @summary Get Export Csv
   * @request GET:/api/1.0/ticket/export/csv
   * @secure
   */
  getExportCsvApi10TicketExportCsvGet = (
    query: GetExportCsvApi10TicketExportCsvGetParams,
    params: RequestParams = {},
  ) =>
    this.request<any, ErrorResponse | HTTPValidationError>({
      path: `/api/1.0/ticket/export/csv`,
      method: "GET",
      query: query,
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags tickets
   * @name GetStatisticsStatusApi10TicketStatisticsStatusGet
   * @summary Get Statistics Status
   * @request GET:/api/1.0/ticket/statistics/status
   * @secure
   */
  getStatisticsStatusApi10TicketStatisticsStatusGet = (
    query: GetStatisticsStatusApi10TicketStatisticsStatusGetParams,
    params: RequestParams = {},
  ) =>
    this.request<StatisticsCountListResponse, ErrorResponse | HTTPValidationError>({
      path: `/api/1.0/ticket/statistics/status`,
      method: "GET",
      query: query,
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags tickets
   * @name GetStatisticsSeverityApi10TicketStatisticsSeverityGet
   * @summary Get Statistics Severity
   * @request GET:/api/1.0/ticket/statistics/severity
   * @secure
   */
  getStatisticsSeverityApi10TicketStatisticsSeverityGet = (
    query: GetStatisticsSeverityApi10TicketStatisticsSeverityGetParams,
    params: RequestParams = {},
  ) =>
    this.request<StatisticsCountListResponse, ErrorResponse | HTTPValidationError>({
      path: `/api/1.0/ticket/statistics/severity`,
      method: "GET",
      query: query,
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags tickets
   * @name GetStatisticsSeverityHistogramApi10TicketStatisticsSeverityHistogramGet
   * @summary Get Statistics Severity Histogram
   * @request GET:/api/1.0/ticket/statistics/severity/histogram
   * @secure
   */
  getStatisticsSeverityHistogramApi10TicketStatisticsSeverityHistogramGet = (
    query: GetStatisticsSeverityHistogramApi10TicketStatisticsSeverityHistogramGetParams,
    params: RequestParams = {},
  ) =>
    this.request<StatisticsHistogramCountListResponse, ErrorResponse | HTTPValidationError>({
      path: `/api/1.0/ticket/statistics/severity/histogram`,
      method: "GET",
      query: query,
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags tickets
   * @name GetStatisticsTypeApi10TicketStatisticsTypeGet
   * @summary Get Statistics Type
   * @request GET:/api/1.0/ticket/statistics/type
   * @secure
   */
  getStatisticsTypeApi10TicketStatisticsTypeGet = (
    query: GetStatisticsTypeApi10TicketStatisticsTypeGetParams,
    params: RequestParams = {},
  ) =>
    this.request<StatisticsCountListResponse, ErrorResponse | HTTPValidationError>({
      path: `/api/1.0/ticket/statistics/type`,
      method: "GET",
      query: query,
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags tickets
   * @name GetStatisticsTypeHistogramApi10TicketStatisticsTypeHistogramGet
   * @summary Get Statistics Type Histogram
   * @request GET:/api/1.0/ticket/statistics/type/histogram
   * @secure
   */
  getStatisticsTypeHistogramApi10TicketStatisticsTypeHistogramGet = (
    query: GetStatisticsTypeHistogramApi10TicketStatisticsTypeHistogramGetParams,
    params: RequestParams = {},
  ) =>
    this.request<StatisticsHistogramCountListResponse, ErrorResponse | HTTPValidationError>({
      path: `/api/1.0/ticket/statistics/type/histogram`,
      method: "GET",
      query: query,
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags alerts
   * @name GetAlertersApi20AlertAlerterGet
   * @summary Get Alerters
   * @request GET:/api/2.0/alert/alerter
   * @secure
   */
  getAlertersApi20AlertAlerterGet = (params: RequestParams = {}) =>
    this.request<PortalsFrontendApiAlertsModelAlerterPreviewListResponse, ErrorResponse | HTTPValidationError>({
      path: `/api/2.0/alert/alerter`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags alerts
   * @name GetAlertApi20AlertExternalIdGet
   * @summary Get Alert
   * @request GET:/api/2.0/alert/{external_id}
   * @secure
   */
  getAlertApi20AlertExternalIdGet = (externalId: string, params: RequestParams = {}) =>
    this.request<PortalsFrontendApiAlertsModelAlertDetailResponse, ErrorResponse | HTTPValidationError>({
      path: `/api/2.0/alert/${externalId}`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags alerts
   * @name EditStatusApi20AlertExternalIdPatch
   * @summary Edit Status
   * @request PATCH:/api/2.0/alert/{external_id}
   * @secure
   */
  editStatusApi20AlertExternalIdPatch = (
    externalId: string,
    data: PortalsFrontendApiAlertsModelAlertUpdateBody,
    params: RequestParams = {},
  ) =>
    this.request<PortalsFrontendApiAlertsModelAlertDetailResponse, ErrorResponse | HTTPValidationError>({
      path: `/api/2.0/alert/${externalId}`,
      method: "PATCH",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags alerts
   * @name GetAlertsApi20AlertGet
   * @summary Get Alerts
   * @request GET:/api/2.0/alert/
   * @secure
   */
  getAlertsApi20AlertGet = (query: GetAlertsApi20AlertGetParams, params: RequestParams = {}) =>
    this.request<PortalsFrontendApiAlertsModelAlertPreviewPageDetailResponse, ErrorResponse | HTTPValidationError>({
      path: `/api/2.0/alert/`,
      method: "GET",
      query: query,
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags alerts
   * @name GetAttachmentApi20AlertExternalIdAttachmentAttachmentExternalIdGet
   * @summary Get Attachment
   * @request GET:/api/2.0/alert/{external_id}/attachment/{attachment_external_id}
   * @secure
   */
  getAttachmentApi20AlertExternalIdAttachmentAttachmentExternalIdGet = (
    attachmentExternalId: string,
    externalId: string,
    params: RequestParams = {},
  ) =>
    this.request<any, ErrorResponse | HTTPValidationError>({
      path: `/api/2.0/alert/${externalId}/attachment/${attachmentExternalId}`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags alerts
   * @name GetStatisticsStatusApi20AlertStatisticsStatusGet
   * @summary Get Statistics Status
   * @request GET:/api/2.0/alert/statistics/status
   * @secure
   */
  getStatisticsStatusApi20AlertStatisticsStatusGet = (
    query: GetStatisticsStatusApi20AlertStatisticsStatusGetParams,
    params: RequestParams = {},
  ) =>
    this.request<StatisticsCountListResponse, ErrorResponse | HTTPValidationError>({
      path: `/api/2.0/alert/statistics/status`,
      method: "GET",
      query: query,
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags alerts
   * @name GetStatisticsStatusHistogramApi20AlertStatisticsStatusHistogramGet
   * @summary Get Statistics Status Histogram
   * @request GET:/api/2.0/alert/statistics/status/histogram
   * @secure
   */
  getStatisticsStatusHistogramApi20AlertStatisticsStatusHistogramGet = (
    query: GetStatisticsStatusHistogramApi20AlertStatisticsStatusHistogramGetParams,
    params: RequestParams = {},
  ) =>
    this.request<StatisticsHistogramCountListResponse, ErrorResponse | HTTPValidationError>({
      path: `/api/2.0/alert/statistics/status/histogram`,
      method: "GET",
      query: query,
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags alerts
   * @name GetStatisticsSeverityApi20AlertStatisticsSeverityGet
   * @summary Get Statistics Severity
   * @request GET:/api/2.0/alert/statistics/severity
   * @secure
   */
  getStatisticsSeverityApi20AlertStatisticsSeverityGet = (
    query: GetStatisticsSeverityApi20AlertStatisticsSeverityGetParams,
    params: RequestParams = {},
  ) =>
    this.request<StatisticsCountListResponse, ErrorResponse | HTTPValidationError>({
      path: `/api/2.0/alert/statistics/severity`,
      method: "GET",
      query: query,
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags alerts
   * @name GetStatisticsSeverityHistogramApi20AlertStatisticsSeverityHistogramGet
   * @summary Get Statistics Severity Histogram
   * @request GET:/api/2.0/alert/statistics/severity/histogram
   * @secure
   */
  getStatisticsSeverityHistogramApi20AlertStatisticsSeverityHistogramGet = (
    query: GetStatisticsSeverityHistogramApi20AlertStatisticsSeverityHistogramGetParams,
    params: RequestParams = {},
  ) =>
    this.request<StatisticsHistogramCountListResponse, ErrorResponse | HTTPValidationError>({
      path: `/api/2.0/alert/statistics/severity/histogram`,
      method: "GET",
      query: query,
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags alerts
   * @name GetStatisticsAlerterApi20AlertStatisticsAlerterGet
   * @summary Get Statistics Alerter
   * @request GET:/api/2.0/alert/statistics/alerter
   * @secure
   */
  getStatisticsAlerterApi20AlertStatisticsAlerterGet = (
    query: GetStatisticsAlerterApi20AlertStatisticsAlerterGetParams,
    params: RequestParams = {},
  ) =>
    this.request<StatisticsCountListResponse, ErrorResponse | HTTPValidationError>({
      path: `/api/2.0/alert/statistics/alerter`,
      method: "GET",
      query: query,
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags alerts
   * @name GetStatisticsAlerterHistogramApi20AlertStatisticsAlerterHistogramGet
   * @summary Get Statistics Alerter Histogram
   * @request GET:/api/2.0/alert/statistics/alerter/histogram
   * @secure
   */
  getStatisticsAlerterHistogramApi20AlertStatisticsAlerterHistogramGet = (
    query: GetStatisticsAlerterHistogramApi20AlertStatisticsAlerterHistogramGetParams,
    params: RequestParams = {},
  ) =>
    this.request<StatisticsHistogramCountListResponse, ErrorResponse | HTTPValidationError>({
      path: `/api/2.0/alert/statistics/alerter/histogram`,
      method: "GET",
      query: query,
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags alerts
   * @name GetAlertsApi10AlertGet
   * @summary Get Alerts
   * @request GET:/api/1.0/alert/
   * @secure
   */
  getAlertsApi10AlertGet = (query: GetAlertsApi10AlertGetParams, params: RequestParams = {}) =>
    this.request<
      PortalsFrontendApiAlertsDeprecatedAppAlertPreviewPageDetailResponse,
      ErrorResponse | HTTPValidationError
    >({
      path: `/api/1.0/alert/`,
      method: "GET",
      query: query,
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags alerts
   * @name GetExportCsvApi10AlertExportCsvGet
   * @summary Get Export Csv
   * @request GET:/api/1.0/alert/export/csv
   * @secure
   */
  getExportCsvApi10AlertExportCsvGet = (query: GetExportCsvApi10AlertExportCsvGetParams, params: RequestParams = {}) =>
    this.request<any, ErrorResponse | HTTPValidationError>({
      path: `/api/1.0/alert/export/csv`,
      method: "GET",
      query: query,
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags alerts
   * @name GetAlertersApi10AlertAlerterGet
   * @summary Get Alerters
   * @request GET:/api/1.0/alert/alerter
   * @secure
   */
  getAlertersApi10AlertAlerterGet = (query: GetAlertersApi10AlertAlerterGetParams, params: RequestParams = {}) =>
    this.request<PortalsFrontendApiAlertsDeprecatedAppAlerterPreviewListResponse, ErrorResponse | HTTPValidationError>({
      path: `/api/1.0/alert/alerter`,
      method: "GET",
      query: query,
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags alerts
   * @name GetAlertApi10AlertExternalIdGet
   * @summary Get Alert
   * @request GET:/api/1.0/alert/{external_id}
   * @secure
   */
  getAlertApi10AlertExternalIdGet = (externalId: string, params: RequestParams = {}) =>
    this.request<PortalsFrontendApiAlertsDeprecatedAppAlertDetailResponse, ErrorResponse | HTTPValidationError>({
      path: `/api/1.0/alert/${externalId}`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags alerts
   * @name EditStatusApi10AlertExternalIdPatch
   * @summary Edit Status
   * @request PATCH:/api/1.0/alert/{external_id}
   * @secure
   */
  editStatusApi10AlertExternalIdPatch = (
    externalId: string,
    data: PortalsFrontendApiAlertsDeprecatedAppAlertUpdateBody,
    params: RequestParams = {},
  ) =>
    this.request<PortalsFrontendApiAlertsDeprecatedAppAlertDetailResponse, ErrorResponse | HTTPValidationError>({
      path: `/api/1.0/alert/${externalId}`,
      method: "PATCH",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags alerts
   * @name GetAttachmentApi10AlertExternalIdAttachmentAttachmentExternalIdGet
   * @summary Get Attachment
   * @request GET:/api/1.0/alert/{external_id}/attachment/{attachment_external_id}
   * @secure
   */
  getAttachmentApi10AlertExternalIdAttachmentAttachmentExternalIdGet = (
    attachmentExternalId: string,
    externalId: string,
    params: RequestParams = {},
  ) =>
    this.request<any, ErrorResponse | HTTPValidationError>({
      path: `/api/1.0/alert/${externalId}/attachment/${attachmentExternalId}`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags alerts
   * @name GetStatisticsStatusApi10AlertStatisticsStatusGet
   * @summary Get Statistics Status
   * @request GET:/api/1.0/alert/statistics/status
   * @secure
   */
  getStatisticsStatusApi10AlertStatisticsStatusGet = (
    query: GetStatisticsStatusApi10AlertStatisticsStatusGetParams,
    params: RequestParams = {},
  ) =>
    this.request<StatisticsCountListResponse, ErrorResponse | HTTPValidationError>({
      path: `/api/1.0/alert/statistics/status`,
      method: "GET",
      query: query,
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags alerts
   * @name GetStatisticsStatusHistogramApi10AlertStatisticsStatusHistogramGet
   * @summary Get Statistics Status Histogram
   * @request GET:/api/1.0/alert/statistics/status/histogram
   * @secure
   */
  getStatisticsStatusHistogramApi10AlertStatisticsStatusHistogramGet = (
    query: GetStatisticsStatusHistogramApi10AlertStatisticsStatusHistogramGetParams,
    params: RequestParams = {},
  ) =>
    this.request<StatisticsHistogramCountListResponse, ErrorResponse | HTTPValidationError>({
      path: `/api/1.0/alert/statistics/status/histogram`,
      method: "GET",
      query: query,
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags alerts
   * @name GetStatisticsSeverityApi10AlertStatisticsSeverityGet
   * @summary Get Statistics Severity
   * @request GET:/api/1.0/alert/statistics/severity
   * @secure
   */
  getStatisticsSeverityApi10AlertStatisticsSeverityGet = (
    query: GetStatisticsSeverityApi10AlertStatisticsSeverityGetParams,
    params: RequestParams = {},
  ) =>
    this.request<StatisticsCountListResponse, ErrorResponse | HTTPValidationError>({
      path: `/api/1.0/alert/statistics/severity`,
      method: "GET",
      query: query,
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags alerts
   * @name GetStatisticsSeverityHistogramApi10AlertStatisticsSeverityHistogramGet
   * @summary Get Statistics Severity Histogram
   * @request GET:/api/1.0/alert/statistics/severity/histogram
   * @secure
   */
  getStatisticsSeverityHistogramApi10AlertStatisticsSeverityHistogramGet = (
    query: GetStatisticsSeverityHistogramApi10AlertStatisticsSeverityHistogramGetParams,
    params: RequestParams = {},
  ) =>
    this.request<StatisticsHistogramCountListResponse, ErrorResponse | HTTPValidationError>({
      path: `/api/1.0/alert/statistics/severity/histogram`,
      method: "GET",
      query: query,
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags alerts
   * @name GetStatisticsAlerterApi10AlertStatisticsAlerterGet
   * @summary Get Statistics Alerter
   * @request GET:/api/1.0/alert/statistics/alerter
   * @secure
   */
  getStatisticsAlerterApi10AlertStatisticsAlerterGet = (
    query: GetStatisticsAlerterApi10AlertStatisticsAlerterGetParams,
    params: RequestParams = {},
  ) =>
    this.request<StatisticsCountListResponse, ErrorResponse | HTTPValidationError>({
      path: `/api/1.0/alert/statistics/alerter`,
      method: "GET",
      query: query,
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags alerts
   * @name GetStatisticsAlerterHistogramApi10AlertStatisticsAlerterHistogramGet
   * @summary Get Statistics Alerter Histogram
   * @request GET:/api/1.0/alert/statistics/alerter/histogram
   * @secure
   */
  getStatisticsAlerterHistogramApi10AlertStatisticsAlerterHistogramGet = (
    query: GetStatisticsAlerterHistogramApi10AlertStatisticsAlerterHistogramGetParams,
    params: RequestParams = {},
  ) =>
    this.request<StatisticsHistogramCountListResponse, ErrorResponse | HTTPValidationError>({
      path: `/api/1.0/alert/statistics/alerter/histogram`,
      method: "GET",
      query: query,
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags tickets
   * @name GetTicketApi20TicketExternalIdGet
   * @summary Get Ticket
   * @request GET:/api/2.0/ticket/{external_id}
   * @secure
   */
  getTicketApi20TicketExternalIdGet = (externalId: string, params: RequestParams = {}) =>
    this.request<PortalsFrontendApiTicketsModelTicketFullDetailResponse, ErrorResponse | HTTPValidationError>({
      path: `/api/2.0/ticket/${externalId}`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags tickets
   * @name GetCommentsApi20TicketExternalIdCommentGet
   * @summary Get Comments
   * @request GET:/api/2.0/ticket/{external_id}/comment
   * @secure
   */
  getCommentsApi20TicketExternalIdCommentGet = (externalId: string, params: RequestParams = {}) =>
    this.request<PortalsFrontendApiTicketsModelTicketCommentPreviewListResponse, ErrorResponse | HTTPValidationError>({
      path: `/api/2.0/ticket/${externalId}/comment`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags tickets
   * @name CreateCommentApi20TicketExternalIdCommentPost
   * @summary Create Comment
   * @request POST:/api/2.0/ticket/{external_id}/comment
   * @secure
   */
  createCommentApi20TicketExternalIdCommentPost = (
    externalId: string,
    data: PortalsFrontendApiTicketsModelTicketCommentCreateBody,
    params: RequestParams = {},
  ) =>
    this.request<PortalsFrontendApiTicketsModelTicketCommentPreviewDetailResponse, ErrorResponse | HTTPValidationError>(
      {
        path: `/api/2.0/ticket/${externalId}/comment`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      },
    );
  /**
   * No description
   *
   * @tags tickets
   * @name GetAttachmentApi20TicketExternalIdAttachmentAttachmentExternalIdGet
   * @summary Get Attachment
   * @request GET:/api/2.0/ticket/{external_id}/attachment/{attachment_external_id}
   * @secure
   */
  getAttachmentApi20TicketExternalIdAttachmentAttachmentExternalIdGet = (
    attachmentExternalId: any,
    externalId: string,
    params: RequestParams = {},
  ) =>
    this.request<any, ErrorResponse | HTTPValidationError>({
      path: `/api/2.0/ticket/${externalId}/attachment/${attachmentExternalId}`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags tickets
   * @name GetTicketsApi20TicketGet
   * @summary Get Tickets
   * @request GET:/api/2.0/ticket/
   * @secure
   */
  getTicketsApi20TicketGet = (query: GetTicketsApi20TicketGetParams, params: RequestParams = {}) =>
    this.request<PortalsFrontendApiTicketsModelTicketPreviewPageDetailResponse, ErrorResponse | HTTPValidationError>({
      path: `/api/2.0/ticket/`,
      method: "GET",
      query: query,
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags phishing
   * @name GetBrandsApi20PhishingBrandGet
   * @summary Get Brands
   * @request GET:/api/2.0/phishing/brand/
   * @secure
   */
  getBrandsApi20PhishingBrandGet = (params: RequestParams = {}) =>
    this.request<ListResponseBrandPreview, ErrorResponse | HTTPValidationError>({
      path: `/api/2.0/phishing/brand/`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags phishing
   * @name GetAsnsApi20PhishingAsnGet
   * @summary Get Asns
   * @request GET:/api/2.0/phishing/asn/
   * @secure
   */
  getAsnsApi20PhishingAsnGet = (data: string[], params: RequestParams = {}) =>
    this.request<ListResponseASNPreview, ErrorResponse | HTTPValidationError>({
      path: `/api/2.0/phishing/asn/`,
      method: "GET",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags phishing
   * @name GetCountriesApi20PhishingCountryGet
   * @summary Get Countries
   * @request GET:/api/2.0/phishing/country/
   * @secure
   */
  getCountriesApi20PhishingCountryGet = (params: RequestParams = {}) =>
    this.request<ListResponseCountryPreview, ErrorResponse | HTTPValidationError>({
      path: `/api/2.0/phishing/country/`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags phishing
   * @name GetCustomerApi20PhishingCustomerGet
   * @summary Get Customer
   * @request GET:/api/2.0/phishing/customer/
   * @secure
   */
  getCustomerApi20PhishingCustomerGet = (params: RequestParams = {}) =>
    this.request<ListResponseCustomerFull, ErrorResponse | HTTPValidationError>({
      path: `/api/2.0/phishing/customer/`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags phishing
   * @name GetUrlsApi20PhishingUrlGet
   * @summary Get Urls
   * @request GET:/api/2.0/phishing/url/
   * @secure
   */
  getUrlsApi20PhishingUrlGet = (query: GetUrlsApi20PhishingUrlGetParams, params: RequestParams = {}) =>
    this.request<PortalsFrontendApiPhishingRouterDetailResponseURLPreviewPage, ErrorResponse | HTTPValidationError>({
      path: `/api/2.0/phishing/url/`,
      method: "GET",
      query: query,
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags phishing
   * @name PostUrlsApi20PhishingUrlPost
   * @summary Post Urls
   * @request POST:/api/2.0/phishing/url/
   * @secure
   */
  postUrlsApi20PhishingUrlPost = (
    data: PortalsFrontendApiPhishingModelsBulkUrlSubmitBody,
    params: RequestParams = {},
  ) =>
    this.request<DetailResponseBulkUrlSubmitResponse, ErrorResponse | HTTPValidationError>({
      path: `/api/2.0/phishing/url/`,
      method: "POST",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags phishing
   * @name GetUrlApi20PhishingUrlUrlHashGet
   * @summary Get Url
   * @request GET:/api/2.0/phishing/url/{url_hash}
   * @secure
   */
  getUrlApi20PhishingUrlUrlHashGet = (urlHash: string, params: RequestParams = {}) =>
    this.request<DetailResponseURLFull, ErrorResponse | HTTPValidationError>({
      path: `/api/2.0/phishing/url/${urlHash}`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags phishing
   * @name ExportUrlsApi20PhishingUrlExportCsvGet
   * @summary Export Urls
   * @request GET:/api/2.0/phishing/url/export/csv/
   * @secure
   */
  exportUrlsApi20PhishingUrlExportCsvGet = (
    query: ExportUrlsApi20PhishingUrlExportCsvGetParams,
    params: RequestParams = {},
  ) =>
    this.request<void, ErrorResponse | HTTPValidationError>({
      path: `/api/2.0/phishing/url/export/csv/`,
      method: "GET",
      query: query,
      secure: true,
      ...params,
    });
  /**
   * No description
   *
   * @tags phishing
   * @name GetUrlStatsStatusApi20PhishingUrlStatisticsStatusGet
   * @summary Get Url Stats Status
   * @request GET:/api/2.0/phishing/url/statistics/status/
   * @secure
   */
  getUrlStatsStatusApi20PhishingUrlStatisticsStatusGet = (
    query: GetUrlStatsStatusApi20PhishingUrlStatisticsStatusGetParams,
    params: RequestParams = {},
  ) =>
    this.request<StatisticsCountListResponse, ErrorResponse | HTTPValidationError>({
      path: `/api/2.0/phishing/url/statistics/status/`,
      method: "GET",
      query: query,
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags phishing
   * @name GetContentsApi20PhishingContentUrlHashGet
   * @summary Get Contents
   * @request GET:/api/2.0/phishing/content/{url_hash}
   * @secure
   */
  getContentsApi20PhishingContentUrlHashGet = (
    { urlHash, ...query }: GetContentsApi20PhishingContentUrlHashGetParams,
    params: RequestParams = {},
  ) =>
    this.request<PortalsFrontendApiPhishingRouterDetailResponseContentFullPage, ErrorResponse | HTTPValidationError>({
      path: `/api/2.0/phishing/content/${urlHash}`,
      method: "GET",
      query: query,
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags phishing
   * @name GetContentArtifactApi20PhishingContentUrlHashArtifactTypeContentIdGet
   * @summary Get Content Artifact
   * @request GET:/api/2.0/phishing/content/{url_hash}/{artifact_type}/{content_id}/
   * @secure
   */
  getContentArtifactApi20PhishingContentUrlHashArtifactTypeContentIdGet = (
    urlHash: string,
    artifactType: ArtifactType,
    contentId: number,
    params: RequestParams = {},
  ) =>
    this.request<any, ErrorResponse | HTTPValidationError>({
      path: `/api/2.0/phishing/content/${urlHash}/${artifactType}/${contentId}/`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags phishing
   * @name GetUrlStatsLegacyApi20PhishingUrlStatisticsLegacyGet
   * @summary Get Url Stats Legacy
   * @request GET:/api/2.0/phishing/url/statistics/legacy/
   * @secure
   */
  getUrlStatsLegacyApi20PhishingUrlStatisticsLegacyGet = (
    query: GetUrlStatsLegacyApi20PhishingUrlStatisticsLegacyGetParams,
    params: RequestParams = {},
  ) =>
    this.request<DetailResponseLegacyStatsResponse, ErrorResponse | HTTPValidationError>({
      path: `/api/2.0/phishing/url/statistics/legacy/`,
      method: "GET",
      query: query,
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags phishing
   * @name IndexApi10PhishingGet
   * @summary Index
   * @request GET:/api/1.0/phishing/
   * @secure
   */
  indexApi10PhishingGet = (params: RequestParams = {}) =>
    this.request<BaseResponse, ErrorResponse>({
      path: `/api/1.0/phishing/`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags phishing
   * @name GetBrandsApi10PhishingBrandGet
   * @summary Get Brands
   * @request GET:/api/1.0/phishing/brand/
   * @secure
   */
  getBrandsApi10PhishingBrandGet = (query: GetBrandsApi10PhishingBrandGetParams, params: RequestParams = {}) =>
    this.request<ListResponseBrandPreview, ErrorResponse | HTTPValidationError>({
      path: `/api/1.0/phishing/brand/`,
      method: "GET",
      query: query,
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags phishing
   * @name GetAsnsApi10PhishingAsnGet
   * @summary Get Asns
   * @request GET:/api/1.0/phishing/asn
   * @secure
   */
  getAsnsApi10PhishingAsnGet = (query: GetAsnsApi10PhishingAsnGetParams, params: RequestParams = {}) =>
    this.request<ListResponseASNPreview, ErrorResponse | HTTPValidationError>({
      path: `/api/1.0/phishing/asn`,
      method: "GET",
      query: query,
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags phishing
   * @name GetCountriesApi10PhishingCountryGet
   * @summary Get Countries
   * @request GET:/api/1.0/phishing/country
   * @secure
   */
  getCountriesApi10PhishingCountryGet = (
    query: GetCountriesApi10PhishingCountryGetParams,
    params: RequestParams = {},
  ) =>
    this.request<ListResponseCountryPreview, ErrorResponse | HTTPValidationError>({
      path: `/api/1.0/phishing/country`,
      method: "GET",
      query: query,
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags phishing
   * @name GetCustomerApi10PhishingCustomerGet
   * @summary Get Customer
   * @request GET:/api/1.0/phishing/customer
   * @secure
   */
  getCustomerApi10PhishingCustomerGet = (
    query: GetCustomerApi10PhishingCustomerGetParams,
    params: RequestParams = {},
  ) =>
    this.request<ListResponseCustomerFull, ErrorResponse | HTTPValidationError>({
      path: `/api/1.0/phishing/customer`,
      method: "GET",
      query: query,
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags phishing
   * @name GetUrlApi10PhishingUrlUrlHashGet
   * @summary Get Url
   * @request GET:/api/1.0/phishing/url/{url_hash}
   * @secure
   */
  getUrlApi10PhishingUrlUrlHashGet = (
    { urlHash, ...query }: GetUrlApi10PhishingUrlUrlHashGetParams,
    params: RequestParams = {},
  ) =>
    this.request<DetailResponseURLFull, ErrorResponse | HTTPValidationError>({
      path: `/api/1.0/phishing/url/${urlHash}`,
      method: "GET",
      query: query,
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags phishing
   * @name GetUrlsApi10PhishingUrlGet
   * @summary Get Urls
   * @request GET:/api/1.0/phishing/url
   * @secure
   */
  getUrlsApi10PhishingUrlGet = (query: GetUrlsApi10PhishingUrlGetParams, params: RequestParams = {}) =>
    this.request<
      PortalsFrontendApiPhishingDeprecatedAppDetailResponseURLPreviewPage,
      ErrorResponse | HTTPValidationError
    >({
      path: `/api/1.0/phishing/url`,
      method: "GET",
      query: query,
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags phishing
   * @name PostUrlsApi10PhishingUrlPost
   * @summary Post Urls
   * @request POST:/api/1.0/phishing/url
   * @secure
   */
  postUrlsApi10PhishingUrlPost = (
    data: PortalsFrontendApiPhishingDeprecatedAppBulkUrlSubmitBody,
    params: RequestParams = {},
  ) =>
    this.request<DetailResponseBulkUrlSubmitResponse, ErrorResponse | HTTPValidationError>({
      path: `/api/1.0/phishing/url`,
      method: "POST",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags phishing
   * @name ExportUrlsApi10PhishingUrlExportCsvGet
   * @summary Export Urls
   * @request GET:/api/1.0/phishing/url/export/csv
   * @secure
   */
  exportUrlsApi10PhishingUrlExportCsvGet = (
    query: ExportUrlsApi10PhishingUrlExportCsvGetParams,
    params: RequestParams = {},
  ) =>
    this.request<any, ErrorResponse | HTTPValidationError>({
      path: `/api/1.0/phishing/url/export/csv`,
      method: "GET",
      query: query,
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags phishing
   * @name GetUrlStatsStatusApi10PhishingUrlStatisticsStatusGet
   * @summary Get Url Stats Status
   * @request GET:/api/1.0/phishing/url/statistics/status
   * @secure
   */
  getUrlStatsStatusApi10PhishingUrlStatisticsStatusGet = (
    query: GetUrlStatsStatusApi10PhishingUrlStatisticsStatusGetParams,
    params: RequestParams = {},
  ) =>
    this.request<StatisticsCountListResponse, ErrorResponse | HTTPValidationError>({
      path: `/api/1.0/phishing/url/statistics/status`,
      method: "GET",
      query: query,
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags phishing
   * @name GetContentsApi10PhishingContentUrlHashGet
   * @summary Get Contents
   * @request GET:/api/1.0/phishing/content/{url_hash}
   * @secure
   */
  getContentsApi10PhishingContentUrlHashGet = (
    { urlHash, ...query }: GetContentsApi10PhishingContentUrlHashGetParams,
    params: RequestParams = {},
  ) =>
    this.request<
      PortalsFrontendApiPhishingDeprecatedAppDetailResponseContentFullPage,
      ErrorResponse | HTTPValidationError
    >({
      path: `/api/1.0/phishing/content/${urlHash}`,
      method: "GET",
      query: query,
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags phishing
   * @name GetContentArtifactApi10PhishingContentUrlHashArtifactTypeContentIdGet
   * @summary Get Content Artifact
   * @request GET:/api/1.0/phishing/content/{url_hash}/{artifact_type}/{content_id}/
   * @secure
   */
  getContentArtifactApi10PhishingContentUrlHashArtifactTypeContentIdGet = (
    {
      urlHash,
      artifactType,
      contentId,
      ...query
    }: GetContentArtifactApi10PhishingContentUrlHashArtifactTypeContentIdGetParams,
    params: RequestParams = {},
  ) =>
    this.request<any, ErrorResponse | HTTPValidationError>({
      path: `/api/1.0/phishing/content/${urlHash}/${artifactType}/${contentId}/`,
      method: "GET",
      query: query,
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags phishing
   * @name GetUrlStatsLegacyApi10PhishingUrlStatisticsLegacyGet
   * @summary Get Url Stats Legacy
   * @request GET:/api/1.0/phishing/url/statistics/legacy
   * @secure
   */
  getUrlStatsLegacyApi10PhishingUrlStatisticsLegacyGet = (
    query: GetUrlStatsLegacyApi10PhishingUrlStatisticsLegacyGetParams,
    params: RequestParams = {},
  ) =>
    this.request<DetailResponseLegacyStatsResponse, ErrorResponse | HTTPValidationError>({
      path: `/api/1.0/phishing/url/statistics/legacy`,
      method: "GET",
      query: query,
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags phishing-report
   * @name GenerateReportApi10PhishingReportGeneratePost
   * @summary Generate Report
   * @request POST:/api/1.0/phishing/report/generate
   * @secure
   */
  generateReportApi10PhishingReportGeneratePost = (
    query: GenerateReportApi10PhishingReportGeneratePostParams,
    params: RequestParams = {},
  ) =>
    this.request<BaseResponse, ErrorResponse | HTTPValidationError>({
      path: `/api/1.0/phishing/report/generate`,
      method: "POST",
      query: query,
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * @description List portal news
   *
   * @tags portal-news
   * @name GetPortalNewsApi10PortalNewsGet
   * @summary Get Portal News
   * @request GET:/api/1.0/portal-news/
   * @secure
   */
  getPortalNewsApi10PortalNewsGet = (query: GetPortalNewsApi10PortalNewsGetParams, params: RequestParams = {}) =>
    this.request<DetailResponsePortalnewsPreviewPage, ErrorResponse | HTTPValidationError>({
      path: `/api/1.0/portal-news/`,
      method: "GET",
      query: query,
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * @description Set the last_read_portal_news timestamp to now
   *
   * @tags portal-news
   * @name SetLastReadApi10PortalNewsLastReadPost
   * @summary Set Last Read
   * @request POST:/api/1.0/portal-news/last-read
   * @secure
   */
  setLastReadApi10PortalNewsLastReadPost = (params: RequestParams = {}) =>
    this.request<BaseResponse, ErrorResponse>({
      path: `/api/1.0/portal-news/last-read`,
      method: "POST",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags profile
   * @name GetProfileApi10ProfileGet
   * @summary Get Profile
   * @request GET:/api/1.0/profile/
   * @secure
   */
  getProfileApi10ProfileGet = (params: RequestParams = {}) =>
    this.request<ProfileDetailResponse, ErrorResponse>({
      path: `/api/1.0/profile/`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags profile
   * @name PostProfileApi10ProfilePatch
   * @summary Post Profile
   * @request PATCH:/api/1.0/profile/
   * @secure
   */
  postProfileApi10ProfilePatch = (data: UserContactInfoUpdate, params: RequestParams = {}) =>
    this.request<ProfilePreviewDetailResponse, ErrorResponse | HTTPValidationError>({
      path: `/api/1.0/profile/`,
      method: "PATCH",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags profile
   * @name GetOtpApi10ProfileOtpGet
   * @summary Get Otp
   * @request GET:/api/1.0/profile/otp
   * @secure
   */
  getOtpApi10ProfileOtpGet = (params: RequestParams = {}) =>
    this.request<UserOTPDetailResponse, ErrorResponse>({
      path: `/api/1.0/profile/otp`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags profile
   * @name PostOtpApi10ProfileOtpPost
   * @summary Post Otp
   * @request POST:/api/1.0/profile/otp
   * @secure
   */
  postOtpApi10ProfileOtpPost = (data: UserOTPVerification, params: RequestParams = {}) =>
    this.request<ProfilePreviewDetailResponse, ErrorResponse | HTTPValidationError>({
      path: `/api/1.0/profile/otp`,
      method: "POST",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags profile
   * @name DeleteOtpApi10ProfileOtpDelete
   * @summary Delete Otp
   * @request DELETE:/api/1.0/profile/otp
   * @secure
   */
  deleteOtpApi10ProfileOtpDelete = (params: RequestParams = {}) =>
    this.request<void, ErrorResponse>({
      path: `/api/1.0/profile/otp`,
      method: "DELETE",
      secure: true,
      ...params,
    });
  /**
   * No description
   *
   * @tags profile
   * @name PostTokenApi10ProfileTokenPost
   * @summary Post Token
   * @request POST:/api/1.0/profile/token
   * @secure
   */
  postTokenApi10ProfileTokenPost = (params: RequestParams = {}) =>
    this.request<ProfilePreviewDetailResponse, ErrorResponse>({
      path: `/api/1.0/profile/token`,
      method: "POST",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags profile
   * @name DeleteTokenApi10ProfileTokenDelete
   * @summary Delete Token
   * @request DELETE:/api/1.0/profile/token
   * @secure
   */
  deleteTokenApi10ProfileTokenDelete = (params: RequestParams = {}) =>
    this.request<ProfilePreviewDetailResponse, ErrorResponse>({
      path: `/api/1.0/profile/token`,
      method: "DELETE",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags profile
   * @name PostPasswordApi10ProfilePasswordPost
   * @summary Post Password
   * @request POST:/api/1.0/profile/password
   * @secure
   */
  postPasswordApi10ProfilePasswordPost = (data: ChangePasswordRequestBody, params: RequestParams = {}) =>
    this.request<ProfilePreviewDetailResponse, ErrorResponse | HTTPValidationError>({
      path: `/api/1.0/profile/password`,
      method: "POST",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags profile
   * @name GetUserDataStoragePrefrenceApi10ProfileUserDataStorageTypeNameGet
   * @summary Get User Data Storage Prefrence
   * @request GET:/api/1.0/profile/user-data-storage/{type_name}
   * @secure
   */
  getUserDataStoragePrefrenceApi10ProfileUserDataStorageTypeNameGet = (typeName: string, params: RequestParams = {}) =>
    this.request<StorageCreateResponse, ErrorResponse | HTTPValidationError>({
      path: `/api/1.0/profile/user-data-storage/${typeName}`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags profile
   * @name PutUserDataStorageApi10ProfileUserDataStorageTypeNamePut
   * @summary Put User Data Storage
   * @request PUT:/api/1.0/profile/user-data-storage/{type_name}
   * @secure
   */
  putUserDataStorageApi10ProfileUserDataStorageTypeNamePut = (
    typeName: string,
    data: StorageCreate,
    params: RequestParams = {},
  ) =>
    this.request<StorageCreateResponse, ErrorResponse | HTTPValidationError>({
      path: `/api/1.0/profile/user-data-storage/${typeName}`,
      method: "PUT",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags profile
   * @name GetUserDataStorageTypeNamesApi10ProfileUserDataStorageTypesGet
   * @summary Get User Data Storage Type Names
   * @request GET:/api/1.0/profile/user-data-storage/types
   * @secure
   */
  getUserDataStorageTypeNamesApi10ProfileUserDataStorageTypesGet = (params: RequestParams = {}) =>
    this.request<StorageTypePreviewResponse, ErrorResponse>({
      path: `/api/1.0/profile/user-data-storage/types`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags profile
   * @name GetAuditLogApi10ProfileAuditLogGet
   * @summary Get Audit Log
   * @request GET:/api/1.0/profile/audit-log/
   * @secure
   */
  getAuditLogApi10ProfileAuditLogGet = (params: RequestParams = {}) =>
    this.request<ListResponsePortalAuditLogEntry, ErrorResponse>({
      path: `/api/1.0/profile/audit-log/`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags profile
   * @name GetAlertNotificationPreferencesApi10ProfilePreferenceNotificationAlertGet
   * @summary Get Alert Notification Preferences
   * @request GET:/api/1.0/profile/preference/notification/alert
   * @secure
   */
  getAlertNotificationPreferencesApi10ProfilePreferenceNotificationAlertGet = (params: RequestParams = {}) =>
    this.request<NotificationDigestPreferenceSetListResponse, ErrorResponse>({
      path: `/api/1.0/profile/preference/notification/alert`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * @description Iterates through the List[NotificationDigestPreferenceSet] specified on the PATCH request body. For each NotificationDigestPreference, updates the specified user preference to the desired severity. If the severity specified in NotificationDigestPreference.digests is None, the specified user preference is deleted.
   *
   * @tags profile
   * @name PatchAlertNotificationPreferencesApi10ProfilePreferenceNotificationAlertPatch
   * @summary Patch Alert Notification Preferences
   * @request PATCH:/api/1.0/profile/preference/notification/alert
   * @secure
   */
  patchAlertNotificationPreferencesApi10ProfilePreferenceNotificationAlertPatch = (
    data: NotificationDigestPreferenceSet[],
    params: RequestParams = {},
  ) =>
    this.request<NotificationDigestPreferenceSetListResponse, ErrorResponse | HTTPValidationError>({
      path: `/api/1.0/profile/preference/notification/alert`,
      method: "PATCH",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags profile
   * @name GetTicketNotificationPreferencesApi10ProfilePreferenceNotificationTicketGet
   * @summary Get Ticket Notification Preferences
   * @request GET:/api/1.0/profile/preference/notification/ticket
   * @secure
   */
  getTicketNotificationPreferencesApi10ProfilePreferenceNotificationTicketGet = (params: RequestParams = {}) =>
    this.request<NotificationDigestPreferenceSetListResponse, ErrorResponse>({
      path: `/api/1.0/profile/preference/notification/ticket`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * @description Iterates through the List[NotificationDigestPreferenceSet] specified on the PATCH request body. For each NotificationDigestPreference, updates the specified user preference to the desired severity. If the severity specified in NotificationDigestPreference.digests is None, the specified user preference is deleted.
   *
   * @tags profile
   * @name PatchTicketNotificationPreferenceApi10ProfilePreferenceNotificationTicketPatch
   * @summary Patch Ticket Notification Preference
   * @request PATCH:/api/1.0/profile/preference/notification/ticket
   * @secure
   */
  patchTicketNotificationPreferenceApi10ProfilePreferenceNotificationTicketPatch = (
    data: NotificationDigestPreferenceSet[],
    params: RequestParams = {},
  ) =>
    this.request<NotificationDigestPreferenceSetListResponse, ErrorResponse | HTTPValidationError>({
      path: `/api/1.0/profile/preference/notification/ticket`,
      method: "PATCH",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags profile
   * @name GetArticleNotificationPreferencesApi10ProfilePreferenceNotificationArticleGet
   * @summary Get Article Notification Preferences
   * @request GET:/api/1.0/profile/preference/notification/article
   * @secure
   */
  getArticleNotificationPreferencesApi10ProfilePreferenceNotificationArticleGet = (params: RequestParams = {}) =>
    this.request<NotificationDigestPreferenceSetListResponse, ErrorResponse>({
      path: `/api/1.0/profile/preference/notification/article`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * @description Iterates through the List[NotificationDigestPreferenceSet] specified on the PATCH request body. For each NotificationDigestPreference, updates the specified user preference to the desired severity. If the severity specified in NotificationDigestPreference.digests is None, the specified user preference is deleted.
   *
   * @tags profile
   * @name PatchArticleNotificationPreferenceApi10ProfilePreferenceNotificationArticlePatch
   * @summary Patch Article Notification Preference
   * @request PATCH:/api/1.0/profile/preference/notification/article
   * @secure
   */
  patchArticleNotificationPreferenceApi10ProfilePreferenceNotificationArticlePatch = (
    data: NotificationDigestPreferenceSet[],
    params: RequestParams = {},
  ) =>
    this.request<NotificationDigestPreferenceSetListResponse, ErrorResponse | HTTPValidationError>({
      path: `/api/1.0/profile/preference/notification/article`,
      method: "PATCH",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags profile
   * @name GetCreditCardNotificationPreferencesApi10ProfilePreferenceNotificationCreditCardReportGet
   * @summary Get Credit Card Notification Preferences
   * @request GET:/api/1.0/profile/preference/notification/credit-card-report
   * @secure
   */
  getCreditCardNotificationPreferencesApi10ProfilePreferenceNotificationCreditCardReportGet = (
    params: RequestParams = {},
  ) =>
    this.request<NotificationDigestPreferenceSimpleSetListResponse, ErrorResponse>({
      path: `/api/1.0/profile/preference/notification/credit-card-report`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags profile
   * @name PatchCreditCardNotificationPreferenceApi10ProfilePreferenceNotificationCreditCardReportPatch
   * @summary Patch Credit Card Notification Preference
   * @request PATCH:/api/1.0/profile/preference/notification/credit-card-report
   * @secure
   */
  patchCreditCardNotificationPreferenceApi10ProfilePreferenceNotificationCreditCardReportPatch = (
    data: SimpleNotificationDigestPreference,
    params: RequestParams = {},
  ) =>
    this.request<NotificationDigestPreferenceSimpleSetListResponse, ErrorResponse | HTTPValidationError>({
      path: `/api/1.0/profile/preference/notification/credit-card-report`,
      method: "PATCH",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags profile
   * @name GetVictimNotificationPreferencesApi10ProfilePreferenceNotificationVictimReportGet
   * @summary Get Victim Notification Preferences
   * @request GET:/api/1.0/profile/preference/notification/victim-report
   * @secure
   */
  getVictimNotificationPreferencesApi10ProfilePreferenceNotificationVictimReportGet = (params: RequestParams = {}) =>
    this.request<NotificationDigestPreferenceSimpleSetListResponse, ErrorResponse>({
      path: `/api/1.0/profile/preference/notification/victim-report`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags profile
   * @name PatchVictimNotificationPreferenceApi10ProfilePreferenceNotificationVictimReportPatch
   * @summary Patch Victim Notification Preference
   * @request PATCH:/api/1.0/profile/preference/notification/victim-report
   * @secure
   */
  patchVictimNotificationPreferenceApi10ProfilePreferenceNotificationVictimReportPatch = (
    data: SimpleNotificationDigestPreference,
    params: RequestParams = {},
  ) =>
    this.request<NotificationDigestPreferenceSimpleSetListResponse, ErrorResponse | HTTPValidationError>({
      path: `/api/1.0/profile/preference/notification/victim-report`,
      method: "PATCH",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags articles
   * @name GetArticlesApi20ArticleGet
   * @summary Get Articles
   * @request GET:/api/2.0/article/
   * @secure
   */
  getArticlesApi20ArticleGet = (query: GetArticlesApi20ArticleGetParams, params: RequestParams = {}) =>
    this.request<PortalsFrontendApiArticlesAppArticlePreviewPageDetailResponse, ErrorResponse | HTTPValidationError>({
      path: `/api/2.0/article/`,
      method: "GET",
      query: query,
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags articles
   * @name GetArticleApi20ArticleExternalIdGet
   * @summary Get Article
   * @request GET:/api/2.0/article/{external_id}/
   * @secure
   */
  getArticleApi20ArticleExternalIdGet = (externalId: string, params: RequestParams = {}) =>
    this.request<PortalsFrontendApiArticlesAppArticleDetailResponse, ErrorResponse | HTTPValidationError>({
      path: `/api/2.0/article/${externalId}/`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags articles
   * @name GetAttachmentApi20ArticleExternalIdAttachmentAttachmentExternalIdGet
   * @summary Get Attachment
   * @request GET:/api/2.0/article/{external_id}/attachment/{attachment_external_id}
   * @secure
   */
  getAttachmentApi20ArticleExternalIdAttachmentAttachmentExternalIdGet = (
    attachmentExternalId: string,
    externalId: string,
    params: RequestParams = {},
  ) =>
    this.request<any, ErrorResponse | HTTPValidationError>({
      path: `/api/2.0/article/${externalId}/attachment/${attachmentExternalId}`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags articles
   * @name GetRelatedArticlesApi20ArticleExternalIdRelatedGet
   * @summary Get Related Articles
   * @request GET:/api/2.0/article/{external_id}/related
   * @secure
   */
  getRelatedArticlesApi20ArticleExternalIdRelatedGet = (
    { externalId, ...query }: GetRelatedArticlesApi20ArticleExternalIdRelatedGetParams,
    params: RequestParams = {},
  ) =>
    this.request<PortalsFrontendApiArticlesAppArticlePreviewPageDetailResponse, ErrorResponse | HTTPValidationError>({
      path: `/api/2.0/article/${externalId}/related`,
      method: "GET",
      query: query,
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags articles
   * @name GetCategoriesApi20ArticleCategoryGet
   * @summary Get Categories
   * @request GET:/api/2.0/article/category
   * @secure
   */
  getCategoriesApi20ArticleCategoryGet = (params: RequestParams = {}) =>
    this.request<PortalsFrontendApiArticlesAppArticleCategoryDetailResponse, ErrorResponse | HTTPValidationError>({
      path: `/api/2.0/article/category`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags articles
   * @name GetArticlesApi10ArticleGet
   * @summary Get Articles
   * @request GET:/api/1.0/article/
   * @deprecated
   * @secure
   */
  getArticlesApi10ArticleGet = (query: GetArticlesApi10ArticleGetParams, params: RequestParams = {}) =>
    this.request<
      PortalsFrontendApiArticlesDeprecatedAppArticlePreviewPageDetailResponse,
      ErrorResponse | HTTPValidationError
    >({
      path: `/api/1.0/article/`,
      method: "GET",
      query: query,
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags articles
   * @name GetArticleApi10ArticleExternalIdGet
   * @summary Get Article
   * @request GET:/api/1.0/article/{external_id}/
   * @deprecated
   * @secure
   */
  getArticleApi10ArticleExternalIdGet = (
    { externalId, ...query }: GetArticleApi10ArticleExternalIdGetParams,
    params: RequestParams = {},
  ) =>
    this.request<PortalsFrontendApiArticlesDeprecatedAppArticleDetailResponse, ErrorResponse | HTTPValidationError>({
      path: `/api/1.0/article/${externalId}/`,
      method: "GET",
      query: query,
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags articles
   * @name GetAttachmentApi10ArticleExternalIdAttachmentAttachmentExternalIdGet
   * @summary Get Attachment
   * @request GET:/api/1.0/article/{external_id}/attachment/{attachment_external_id}
   * @deprecated
   * @secure
   */
  getAttachmentApi10ArticleExternalIdAttachmentAttachmentExternalIdGet = (
    {
      attachmentExternalId,
      externalId,
      ...query
    }: GetAttachmentApi10ArticleExternalIdAttachmentAttachmentExternalIdGetParams,
    params: RequestParams = {},
  ) =>
    this.request<any, ErrorResponse | HTTPValidationError>({
      path: `/api/1.0/article/${externalId}/attachment/${attachmentExternalId}`,
      method: "GET",
      query: query,
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags articles
   * @name GetRelatedArticlesApi10ArticleExternalIdRelatedGet
   * @summary Get Related Articles
   * @request GET:/api/1.0/article/{external_id}/related
   * @deprecated
   * @secure
   */
  getRelatedArticlesApi10ArticleExternalIdRelatedGet = (
    { externalId, ...query }: GetRelatedArticlesApi10ArticleExternalIdRelatedGetParams,
    params: RequestParams = {},
  ) =>
    this.request<
      PortalsFrontendApiArticlesDeprecatedAppArticlePreviewPageDetailResponse,
      ErrorResponse | HTTPValidationError
    >({
      path: `/api/1.0/article/${externalId}/related`,
      method: "GET",
      query: query,
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags articles
   * @name GetCategoriesApi10ArticleCategoryGet
   * @summary Get Categories
   * @request GET:/api/1.0/article/category
   * @deprecated
   * @secure
   */
  getCategoriesApi10ArticleCategoryGet = (
    query: GetCategoriesApi10ArticleCategoryGetParams,
    params: RequestParams = {},
  ) =>
    this.request<
      PortalsFrontendApiArticlesDeprecatedAppArticleCategoryDetailResponse,
      ErrorResponse | HTTPValidationError
    >({
      path: `/api/1.0/article/category`,
      method: "GET",
      query: query,
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags investigations
   * @name GetEntityTypesApi20InvestigationEntityTypeGet
   * @summary Get Entity Types
   * @request GET:/api/2.0/investigation/entity-type
   * @secure
   */
  getEntityTypesApi20InvestigationEntityTypeGet = (
    query: GetEntityTypesApi20InvestigationEntityTypeGetParams,
    params: RequestParams = {},
  ) =>
    this.request<PortalsFrontendApiInvestigationsRouterListResponseEntityTypePayload, ErrorResponse>({
      path: `/api/2.0/investigation/entity-type`,
      method: "GET",
      query: query,
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags investigations
   * @name GetEntityApi20InvestigationEntityGet
   * @summary Get Entity
   * @request GET:/api/2.0/investigation/entity
   * @secure
   */
  getEntityApi20InvestigationEntityGet = (
    query: GetEntityApi20InvestigationEntityGetParams,
    params: RequestParams = {},
  ) =>
    this.request<PortalsFrontendApiInvestigationsModelEntityListResponse, ErrorResponse>({
      path: `/api/2.0/investigation/entity`,
      method: "GET",
      query: query,
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * @description Generates a csv of an IFD lookup. Does the same modelling as `get_entity` and then afterwards does some non-pretty logic to flatten the result to be presentable in a csv.
   *
   * @tags investigations
   * @name GetExportCsvApi20InvestigationExportCsvGet
   * @summary Get Export Csv
   * @request GET:/api/2.0/investigation/export/csv
   * @secure
   */
  getExportCsvApi20InvestigationExportCsvGet = (
    query: GetExportCsvApi20InvestigationExportCsvGetParams,
    params: RequestParams = {},
  ) =>
    this.request<any, ErrorResponse>({
      path: `/api/2.0/investigation/export/csv`,
      method: "GET",
      query: query,
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags investigations
   * @name GetEntityTypesApi10InvestigationEntityTypeGet
   * @summary Get Entity Types
   * @request GET:/api/1.0/investigation/entity-type
   * @deprecated
   * @secure
   */
  getEntityTypesApi10InvestigationEntityTypeGet = (
    query: GetEntityTypesApi10InvestigationEntityTypeGetParams,
    params: RequestParams = {},
  ) =>
    this.request<PortalsFrontendApiInvestigationsDeprecatedAppListResponseEntityTypePayload, ErrorResponse>({
      path: `/api/1.0/investigation/entity-type`,
      method: "GET",
      query: query,
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags investigations
   * @name GetEntityApi10InvestigationEntityGet
   * @summary Get Entity
   * @request GET:/api/1.0/investigation/entity
   * @deprecated
   * @secure
   */
  getEntityApi10InvestigationEntityGet = (
    query: GetEntityApi10InvestigationEntityGetParams,
    params: RequestParams = {},
  ) =>
    this.request<PortalsFrontendApiInvestigationsDeprecatedAppEntityListResponse, ErrorResponse>({
      path: `/api/1.0/investigation/entity`,
      method: "GET",
      query: query,
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * @description Generates a csv of an IFD lookup. Does the same modelling as `get_entity` and then afterwards does some non-pretty logic to flatten the result to be presentable in a csv.
   *
   * @tags investigations
   * @name GetExportCsvApi10InvestigationExportCsvGet
   * @summary Get Export Csv
   * @request GET:/api/1.0/investigation/export/csv
   * @deprecated
   * @secure
   */
  getExportCsvApi10InvestigationExportCsvGet = (
    query: GetExportCsvApi10InvestigationExportCsvGetParams,
    params: RequestParams = {},
  ) =>
    this.request<any, ErrorResponse>({
      path: `/api/1.0/investigation/export/csv`,
      method: "GET",
      query: query,
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags compromised-data
   * @name GetCreditCardReportApi20CompromisedDataCreditCardReportExternalIdGet
   * @summary Get Credit Card Report
   * @request GET:/api/2.0/compromised-data/credit-card-report/{external_id}
   * @secure
   */
  getCreditCardReportApi20CompromisedDataCreditCardReportExternalIdGet = (
    externalId: string,
    params: RequestParams = {},
  ) =>
    this.request<PortalsFrontendApiStraydataAppDetailResponseCreditCard, ErrorResponse | HTTPValidationError>({
      path: `/api/2.0/compromised-data/credit-card-report/${externalId}`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags compromised-data
   * @name EditCreditCardReportApi20CompromisedDataCreditCardReportExternalIdPatch
   * @summary Edit Credit Card Report
   * @request PATCH:/api/2.0/compromised-data/credit-card-report/{external_id}
   * @secure
   */
  editCreditCardReportApi20CompromisedDataCreditCardReportExternalIdPatch = (
    externalId: string,
    data: PortalsFrontendApiStraydataAppReportUpdateBody,
    params: RequestParams = {},
  ) =>
    this.request<PortalsFrontendApiStraydataAppDetailResponseCreditCard, ErrorResponse | HTTPValidationError>({
      path: `/api/2.0/compromised-data/credit-card-report/${externalId}`,
      method: "PATCH",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags compromised-data
   * @name GetCreditCardReportsApi20CompromisedDataCreditCardReportGet
   * @summary Get Credit Card Reports
   * @request GET:/api/2.0/compromised-data/credit-card-report/
   * @secure
   */
  getCreditCardReportsApi20CompromisedDataCreditCardReportGet = (
    query: GetCreditCardReportsApi20CompromisedDataCreditCardReportGetParams,
    params: RequestParams = {},
  ) =>
    this.request<PortalsFrontendApiStraydataAppCreditCardPageResponse, ErrorResponse | HTTPValidationError>({
      path: `/api/2.0/compromised-data/credit-card-report/`,
      method: "GET",
      query: query,
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags compromised-data
   * @name GetCreditCardStatsStateApi20CompromisedDataCreditCardReportStatisticsStatusGet
   * @summary Get Credit Card Stats State
   * @request GET:/api/2.0/compromised-data/credit-card-report/statistics/status
   * @secure
   */
  getCreditCardStatsStateApi20CompromisedDataCreditCardReportStatisticsStatusGet = (
    query: GetCreditCardStatsStateApi20CompromisedDataCreditCardReportStatisticsStatusGetParams,
    params: RequestParams = {},
  ) =>
    this.request<StatisticsCountListResponse, ErrorResponse | HTTPValidationError>({
      path: `/api/2.0/compromised-data/credit-card-report/statistics/status`,
      method: "GET",
      query: query,
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags compromised-data
   * @name GetCreditCardHistogramApi20CompromisedDataCreditCardReportStatisticsMatcherHistogramGet
   * @summary Get Credit Card Histogram
   * @request GET:/api/2.0/compromised-data/credit-card-report/statistics/matcher/histogram
   * @secure
   */
  getCreditCardHistogramApi20CompromisedDataCreditCardReportStatisticsMatcherHistogramGet = (
    query: GetCreditCardHistogramApi20CompromisedDataCreditCardReportStatisticsMatcherHistogramGetParams,
    params: RequestParams = {},
  ) =>
    this.request<StatisticsHistogramCountListResponse, ErrorResponse | HTTPValidationError>({
      path: `/api/2.0/compromised-data/credit-card-report/statistics/matcher/histogram`,
      method: "GET",
      query: query,
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags compromised-data
   * @name GetCreditCardSourceStatsApi20CompromisedDataCreditCardReportStatisticsSourceGet
   * @summary Get Credit Card Source Stats
   * @request GET:/api/2.0/compromised-data/credit-card-report/statistics/source
   * @secure
   */
  getCreditCardSourceStatsApi20CompromisedDataCreditCardReportStatisticsSourceGet = (
    query: GetCreditCardSourceStatsApi20CompromisedDataCreditCardReportStatisticsSourceGetParams,
    params: RequestParams = {},
  ) =>
    this.request<StatisticsCountListResponse, ErrorResponse | HTTPValidationError>({
      path: `/api/2.0/compromised-data/credit-card-report/statistics/source`,
      method: "GET",
      query: query,
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags compromised-data
   * @name GetCreditCardExportCsvApi20CompromisedDataCreditCardReportExportCsvGet
   * @summary Get Credit Card Export Csv
   * @request GET:/api/2.0/compromised-data/credit-card-report/export/csv
   * @secure
   */
  getCreditCardExportCsvApi20CompromisedDataCreditCardReportExportCsvGet = (
    query: GetCreditCardExportCsvApi20CompromisedDataCreditCardReportExportCsvGetParams,
    params: RequestParams = {},
  ) =>
    this.request<any, ErrorResponse | HTTPValidationError>({
      path: `/api/2.0/compromised-data/credit-card-report/export/csv`,
      method: "GET",
      query: query,
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags compromised-data
   * @name BulkEditCreditCardReportsApi20CompromisedDataCreditCardReportBulkPatch
   * @summary Bulk Edit Credit Card Reports
   * @request PATCH:/api/2.0/compromised-data/credit-card-report/bulk
   * @secure
   */
  bulkEditCreditCardReportsApi20CompromisedDataCreditCardReportBulkPatch = (
    data: PortalsFrontendApiStraydataAppBulkReportUpdateBody,
    params: RequestParams = {},
  ) =>
    this.request<
      PortalsFrontendApiStraydataAppDetailResponseBulkReportUpdateResponse,
      ErrorResponse | HTTPValidationError
    >({
      path: `/api/2.0/compromised-data/credit-card-report/bulk`,
      method: "PATCH",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags compromised-data
   * @name GetVictimReportApi20CompromisedDataCredentialReportExternalIdGet
   * @summary Get Victim Report
   * @request GET:/api/2.0/compromised-data/credential-report/{external_id}
   * @secure
   */
  getVictimReportApi20CompromisedDataCredentialReportExternalIdGet = (externalId: string, params: RequestParams = {}) =>
    this.request<PortalsFrontendApiStraydataAppDetailResponseVictim, ErrorResponse | HTTPValidationError>({
      path: `/api/2.0/compromised-data/credential-report/${externalId}`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags compromised-data
   * @name EditVictimReportApi20CompromisedDataCredentialReportExternalIdPatch
   * @summary Edit Victim Report
   * @request PATCH:/api/2.0/compromised-data/credential-report/{external_id}
   * @secure
   */
  editVictimReportApi20CompromisedDataCredentialReportExternalIdPatch = (
    externalId: string,
    data: PortalsFrontendApiStraydataAppReportUpdateBody,
    params: RequestParams = {},
  ) =>
    this.request<PortalsFrontendApiStraydataAppDetailResponseVictim, ErrorResponse | HTTPValidationError>({
      path: `/api/2.0/compromised-data/credential-report/${externalId}`,
      method: "PATCH",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags compromised-data
   * @name GetVictimReportsApi20CompromisedDataCredentialReportGet
   * @summary Get Victim Reports
   * @request GET:/api/2.0/compromised-data/credential-report/
   * @secure
   */
  getVictimReportsApi20CompromisedDataCredentialReportGet = (
    query: GetVictimReportsApi20CompromisedDataCredentialReportGetParams,
    params: RequestParams = {},
  ) =>
    this.request<PortalsFrontendApiStraydataAppVictimPageResponse, ErrorResponse | HTTPValidationError>({
      path: `/api/2.0/compromised-data/credential-report/`,
      method: "GET",
      query: query,
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags compromised-data
   * @name GetVictimStatsStateApi20CompromisedDataCredentialReportStatisticsStatusGet
   * @summary Get Victim Stats State
   * @request GET:/api/2.0/compromised-data/credential-report/statistics/status
   * @secure
   */
  getVictimStatsStateApi20CompromisedDataCredentialReportStatisticsStatusGet = (
    query: GetVictimStatsStateApi20CompromisedDataCredentialReportStatisticsStatusGetParams,
    params: RequestParams = {},
  ) =>
    this.request<StatisticsCountListResponse, ErrorResponse | HTTPValidationError>({
      path: `/api/2.0/compromised-data/credential-report/statistics/status`,
      method: "GET",
      query: query,
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags compromised-data
   * @name GetVictimHistogramApi20CompromisedDataCredentialReportStatisticsMatcherHistogramGet
   * @summary Get Victim Histogram
   * @request GET:/api/2.0/compromised-data/credential-report/statistics/matcher/histogram
   * @secure
   */
  getVictimHistogramApi20CompromisedDataCredentialReportStatisticsMatcherHistogramGet = (
    query: GetVictimHistogramApi20CompromisedDataCredentialReportStatisticsMatcherHistogramGetParams,
    params: RequestParams = {},
  ) =>
    this.request<StatisticsHistogramCountListResponse, ErrorResponse | HTTPValidationError>({
      path: `/api/2.0/compromised-data/credential-report/statistics/matcher/histogram`,
      method: "GET",
      query: query,
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags compromised-data
   * @name GetVictimSourceStatsApi20CompromisedDataCredentialReportStatisticsSourceGet
   * @summary Get Victim Source Stats
   * @request GET:/api/2.0/compromised-data/credential-report/statistics/source
   * @secure
   */
  getVictimSourceStatsApi20CompromisedDataCredentialReportStatisticsSourceGet = (
    query: GetVictimSourceStatsApi20CompromisedDataCredentialReportStatisticsSourceGetParams,
    params: RequestParams = {},
  ) =>
    this.request<StatisticsCountListResponse, ErrorResponse | HTTPValidationError>({
      path: `/api/2.0/compromised-data/credential-report/statistics/source`,
      method: "GET",
      query: query,
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags compromised-data
   * @name GetVictimExportCsvApi20CompromisedDataCredentialReportExportCsvGet
   * @summary Get Victim Export Csv
   * @request GET:/api/2.0/compromised-data/credential-report/export/csv
   * @secure
   */
  getVictimExportCsvApi20CompromisedDataCredentialReportExportCsvGet = (
    query: GetVictimExportCsvApi20CompromisedDataCredentialReportExportCsvGetParams,
    params: RequestParams = {},
  ) =>
    this.request<any, ErrorResponse | HTTPValidationError>({
      path: `/api/2.0/compromised-data/credential-report/export/csv`,
      method: "GET",
      query: query,
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags compromised-data
   * @name BulkEditVictimReportsApi20CompromisedDataCredentialReportBulkPatch
   * @summary Bulk Edit Victim Reports
   * @request PATCH:/api/2.0/compromised-data/credential-report/bulk
   * @secure
   */
  bulkEditVictimReportsApi20CompromisedDataCredentialReportBulkPatch = (
    data: PortalsFrontendApiStraydataAppBulkReportUpdateBody,
    params: RequestParams = {},
  ) =>
    this.request<
      PortalsFrontendApiStraydataAppDetailResponseBulkReportUpdateResponse,
      ErrorResponse | HTTPValidationError
    >({
      path: `/api/2.0/compromised-data/credential-report/bulk`,
      method: "PATCH",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags compromised-data
   * @name BulkEditCreditCardReportsApi10CompromisedDataCreditCardReportBulkPatch
   * @summary Bulk Edit Credit Card Reports
   * @request PATCH:/api/1.0/compromised-data/credit-card-report/bulk
   * @deprecated
   * @secure
   */
  bulkEditCreditCardReportsApi10CompromisedDataCreditCardReportBulkPatch = (
    data: PortalsFrontendApiStraydataDepredatedAppBulkReportUpdateBody,
    params: RequestParams = {},
  ) =>
    this.request<
      PortalsFrontendApiStraydataDepredatedAppDetailResponseBulkReportUpdateResponse,
      ErrorResponse | HTTPValidationError
    >({
      path: `/api/1.0/compromised-data/credit-card-report/bulk`,
      method: "PATCH",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags compromised-data
   * @name GetCreditCardReportApi10CompromisedDataCreditCardReportExternalIdGet
   * @summary Get Credit Card Report
   * @request GET:/api/1.0/compromised-data/credit-card-report/{external_id}
   * @deprecated
   * @secure
   */
  getCreditCardReportApi10CompromisedDataCreditCardReportExternalIdGet = (
    externalId: string,
    params: RequestParams = {},
  ) =>
    this.request<PortalsFrontendApiStraydataDepredatedAppDetailResponseCreditCard, ErrorResponse | HTTPValidationError>(
      {
        path: `/api/1.0/compromised-data/credit-card-report/${externalId}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      },
    );
  /**
   * No description
   *
   * @tags compromised-data
   * @name EditCreditCardReportApi10CompromisedDataCreditCardReportExternalIdPatch
   * @summary Edit Credit Card Report
   * @request PATCH:/api/1.0/compromised-data/credit-card-report/{external_id}
   * @deprecated
   * @secure
   */
  editCreditCardReportApi10CompromisedDataCreditCardReportExternalIdPatch = (
    externalId: string,
    data: PortalsFrontendApiStraydataDepredatedAppReportUpdateBody,
    params: RequestParams = {},
  ) =>
    this.request<PortalsFrontendApiStraydataDepredatedAppDetailResponseCreditCard, ErrorResponse | HTTPValidationError>(
      {
        path: `/api/1.0/compromised-data/credit-card-report/${externalId}`,
        method: "PATCH",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      },
    );
  /**
   * No description
   *
   * @tags compromised-data
   * @name GetCreditCardReportsApi10CompromisedDataCreditCardReportGet
   * @summary Get Credit Card Reports
   * @request GET:/api/1.0/compromised-data/credit-card-report/
   * @deprecated
   * @secure
   */
  getCreditCardReportsApi10CompromisedDataCreditCardReportGet = (
    query: GetCreditCardReportsApi10CompromisedDataCreditCardReportGetParams,
    params: RequestParams = {},
  ) =>
    this.request<PortalsFrontendApiStraydataDepredatedAppCreditCardPageResponse, ErrorResponse | HTTPValidationError>({
      path: `/api/1.0/compromised-data/credit-card-report/`,
      method: "GET",
      query: query,
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags compromised-data
   * @name GetCreditCardStatsStateApi10CompromisedDataCreditCardReportStatisticsStatusGet
   * @summary Get Credit Card Stats State
   * @request GET:/api/1.0/compromised-data/credit-card-report/statistics/status
   * @deprecated
   * @secure
   */
  getCreditCardStatsStateApi10CompromisedDataCreditCardReportStatisticsStatusGet = (
    query: GetCreditCardStatsStateApi10CompromisedDataCreditCardReportStatisticsStatusGetParams,
    params: RequestParams = {},
  ) =>
    this.request<StatisticsCountListResponse, ErrorResponse | HTTPValidationError>({
      path: `/api/1.0/compromised-data/credit-card-report/statistics/status`,
      method: "GET",
      query: query,
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags compromised-data
   * @name GetCreditCardHistogramApi10CompromisedDataCreditCardReportStatisticsMatcherHistogramGet
   * @summary Get Credit Card Histogram
   * @request GET:/api/1.0/compromised-data/credit-card-report/statistics/matcher/histogram
   * @deprecated
   * @secure
   */
  getCreditCardHistogramApi10CompromisedDataCreditCardReportStatisticsMatcherHistogramGet = (
    query: GetCreditCardHistogramApi10CompromisedDataCreditCardReportStatisticsMatcherHistogramGetParams,
    params: RequestParams = {},
  ) =>
    this.request<StatisticsHistogramCountListResponse, ErrorResponse | HTTPValidationError>({
      path: `/api/1.0/compromised-data/credit-card-report/statistics/matcher/histogram`,
      method: "GET",
      query: query,
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags compromised-data
   * @name GetCreditCardSourceStatsApi10CompromisedDataCreditCardReportStatisticsSourceGet
   * @summary Get Credit Card Source Stats
   * @request GET:/api/1.0/compromised-data/credit-card-report/statistics/source
   * @deprecated
   * @secure
   */
  getCreditCardSourceStatsApi10CompromisedDataCreditCardReportStatisticsSourceGet = (
    query: GetCreditCardSourceStatsApi10CompromisedDataCreditCardReportStatisticsSourceGetParams,
    params: RequestParams = {},
  ) =>
    this.request<StatisticsCountListResponse, ErrorResponse | HTTPValidationError>({
      path: `/api/1.0/compromised-data/credit-card-report/statistics/source`,
      method: "GET",
      query: query,
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags compromised-data
   * @name GetCreditCardExportCsvApi10CompromisedDataCreditCardReportExportCsvGet
   * @summary Get Credit Card Export Csv
   * @request GET:/api/1.0/compromised-data/credit-card-report/export/csv
   * @deprecated
   * @secure
   */
  getCreditCardExportCsvApi10CompromisedDataCreditCardReportExportCsvGet = (
    query: GetCreditCardExportCsvApi10CompromisedDataCreditCardReportExportCsvGetParams,
    params: RequestParams = {},
  ) =>
    this.request<any, ErrorResponse | HTTPValidationError>({
      path: `/api/1.0/compromised-data/credit-card-report/export/csv`,
      method: "GET",
      query: query,
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags compromised-data
   * @name BulkEditVictimReportsApi10CompromisedDataCredentialReportBulkPatch
   * @summary Bulk Edit Victim Reports
   * @request PATCH:/api/1.0/compromised-data/credential-report/bulk
   * @deprecated
   * @secure
   */
  bulkEditVictimReportsApi10CompromisedDataCredentialReportBulkPatch = (
    data: PortalsFrontendApiStraydataDepredatedAppBulkReportUpdateBody,
    params: RequestParams = {},
  ) =>
    this.request<
      PortalsFrontendApiStraydataDepredatedAppDetailResponseBulkReportUpdateResponse,
      ErrorResponse | HTTPValidationError
    >({
      path: `/api/1.0/compromised-data/credential-report/bulk`,
      method: "PATCH",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags compromised-data
   * @name GetVictimReportApi10CompromisedDataCredentialReportExternalIdGet
   * @summary Get Victim Report
   * @request GET:/api/1.0/compromised-data/credential-report/{external_id}
   * @deprecated
   * @secure
   */
  getVictimReportApi10CompromisedDataCredentialReportExternalIdGet = (externalId: string, params: RequestParams = {}) =>
    this.request<PortalsFrontendApiStraydataDepredatedAppDetailResponseVictim, ErrorResponse | HTTPValidationError>({
      path: `/api/1.0/compromised-data/credential-report/${externalId}`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags compromised-data
   * @name EditVictimReportApi10CompromisedDataCredentialReportExternalIdPatch
   * @summary Edit Victim Report
   * @request PATCH:/api/1.0/compromised-data/credential-report/{external_id}
   * @deprecated
   * @secure
   */
  editVictimReportApi10CompromisedDataCredentialReportExternalIdPatch = (
    externalId: string,
    data: PortalsFrontendApiStraydataDepredatedAppReportUpdateBody,
    params: RequestParams = {},
  ) =>
    this.request<PortalsFrontendApiStraydataDepredatedAppDetailResponseVictim, ErrorResponse | HTTPValidationError>({
      path: `/api/1.0/compromised-data/credential-report/${externalId}`,
      method: "PATCH",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags compromised-data
   * @name GetVictimReportsApi10CompromisedDataCredentialReportGet
   * @summary Get Victim Reports
   * @request GET:/api/1.0/compromised-data/credential-report/
   * @deprecated
   * @secure
   */
  getVictimReportsApi10CompromisedDataCredentialReportGet = (
    query: GetVictimReportsApi10CompromisedDataCredentialReportGetParams,
    params: RequestParams = {},
  ) =>
    this.request<PortalsFrontendApiStraydataDepredatedAppVictimPageResponse, ErrorResponse | HTTPValidationError>({
      path: `/api/1.0/compromised-data/credential-report/`,
      method: "GET",
      query: query,
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags compromised-data
   * @name GetVictimStatsStateApi10CompromisedDataCredentialReportStatisticsStatusGet
   * @summary Get Victim Stats State
   * @request GET:/api/1.0/compromised-data/credential-report/statistics/status
   * @deprecated
   * @secure
   */
  getVictimStatsStateApi10CompromisedDataCredentialReportStatisticsStatusGet = (
    query: GetVictimStatsStateApi10CompromisedDataCredentialReportStatisticsStatusGetParams,
    params: RequestParams = {},
  ) =>
    this.request<StatisticsCountListResponse, ErrorResponse | HTTPValidationError>({
      path: `/api/1.0/compromised-data/credential-report/statistics/status`,
      method: "GET",
      query: query,
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags compromised-data
   * @name GetVictimHistogramApi10CompromisedDataCredentialReportStatisticsMatcherHistogramGet
   * @summary Get Victim Histogram
   * @request GET:/api/1.0/compromised-data/credential-report/statistics/matcher/histogram
   * @deprecated
   * @secure
   */
  getVictimHistogramApi10CompromisedDataCredentialReportStatisticsMatcherHistogramGet = (
    query: GetVictimHistogramApi10CompromisedDataCredentialReportStatisticsMatcherHistogramGetParams,
    params: RequestParams = {},
  ) =>
    this.request<StatisticsHistogramCountListResponse, ErrorResponse | HTTPValidationError>({
      path: `/api/1.0/compromised-data/credential-report/statistics/matcher/histogram`,
      method: "GET",
      query: query,
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags compromised-data
   * @name GetVictimSourceStatsApi10CompromisedDataCredentialReportStatisticsSourceGet
   * @summary Get Victim Source Stats
   * @request GET:/api/1.0/compromised-data/credential-report/statistics/source
   * @deprecated
   * @secure
   */
  getVictimSourceStatsApi10CompromisedDataCredentialReportStatisticsSourceGet = (
    query: GetVictimSourceStatsApi10CompromisedDataCredentialReportStatisticsSourceGetParams,
    params: RequestParams = {},
  ) =>
    this.request<StatisticsCountListResponse, ErrorResponse | HTTPValidationError>({
      path: `/api/1.0/compromised-data/credential-report/statistics/source`,
      method: "GET",
      query: query,
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags compromised-data
   * @name GetVictimExportCsvApi10CompromisedDataCredentialReportExportCsvGet
   * @summary Get Victim Export Csv
   * @request GET:/api/1.0/compromised-data/credential-report/export/csv
   * @deprecated
   * @secure
   */
  getVictimExportCsvApi10CompromisedDataCredentialReportExportCsvGet = (
    query: GetVictimExportCsvApi10CompromisedDataCredentialReportExportCsvGetParams,
    params: RequestParams = {},
  ) =>
    this.request<any, ErrorResponse | HTTPValidationError>({
      path: `/api/1.0/compromised-data/credential-report/export/csv`,
      method: "GET",
      query: query,
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags forensics
   * @name GetDownloadApi10RemoteForensicsDownloadGet
   * @summary Get Download
   * @request GET:/api/1.0/remote-forensics/download
   * @deprecated
   * @secure
   */
  getDownloadApi10RemoteForensicsDownloadGet = (params: RequestParams = {}) =>
    this.request<PortalsFrontendApiForensicsDeprecatedAppDownloadInfoListResponse, ErrorResponse>({
      path: `/api/1.0/remote-forensics/download`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags forensics
   * @name GetAnalysesApi10RemoteForensicsGet
   * @summary Get Analyses
   * @request GET:/api/1.0/remote-forensics/
   * @deprecated
   * @secure
   */
  getAnalysesApi10RemoteForensicsGet = (query: GetAnalysesApi10RemoteForensicsGetParams, params: RequestParams = {}) =>
    this.request<
      PortalsFrontendApiForensicsDeprecatedAppAnalysisPreviewPageDetailResponse,
      ErrorResponse | HTTPValidationError
    >({
      path: `/api/1.0/remote-forensics/`,
      method: "GET",
      query: query,
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags forensics
   * @name GetAnalysisOverviewApi10RemoteForensicsAnalysisExternalIdGet
   * @summary Get Analysis Overview
   * @request GET:/api/1.0/remote-forensics/{analysis_external_id}
   * @deprecated
   * @secure
   */
  getAnalysisOverviewApi10RemoteForensicsAnalysisExternalIdGet = (
    analysisExternalId: string,
    params: RequestParams = {},
  ) =>
    this.request<PortalsFrontendApiForensicsDeprecatedAppAnalysisDetailResponse, ErrorResponse | HTTPValidationError>({
      path: `/api/1.0/remote-forensics/${analysisExternalId}`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags forensics
   * @name PatchAnalysisApi10RemoteForensicsAnalysisExternalIdPatch
   * @summary Patch Analysis
   * @request PATCH:/api/1.0/remote-forensics/{analysis_external_id}
   * @deprecated
   * @secure
   */
  patchAnalysisApi10RemoteForensicsAnalysisExternalIdPatch = (
    analysisExternalId: string,
    data: PortalsFrontendApiForensicsDeprecatedAppAnalysisPatchBody,
    params: RequestParams = {},
  ) =>
    this.request<PortalsFrontendApiForensicsDeprecatedAppAnalysisDetailResponse, ErrorResponse | HTTPValidationError>({
      path: `/api/1.0/remote-forensics/${analysisExternalId}`,
      method: "PATCH",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags forensics
   * @name GetAnalysisCountersApi10RemoteForensicsAnalysisExternalIdSummaryGet
   * @summary Get Analysis Counters
   * @request GET:/api/1.0/remote-forensics/{analysis_external_id}/summary
   * @deprecated
   * @secure
   */
  getAnalysisCountersApi10RemoteForensicsAnalysisExternalIdSummaryGet = (
    analysisExternalId: string,
    params: RequestParams = {},
  ) =>
    this.request<
      PortalsFrontendApiForensicsDeprecatedAppResultSummaryDetailResponse,
      ErrorResponse | HTTPValidationError
    >({
      path: `/api/1.0/remote-forensics/${analysisExternalId}/summary`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags forensics
   * @name GetAnalysisDetectionToolsApi10RemoteForensicsAnalysisExternalIdDetectionToolsGet
   * @summary Get Analysis Detection Tools
   * @request GET:/api/1.0/remote-forensics/{analysis_external_id}/detection-tools
   * @deprecated
   * @secure
   */
  getAnalysisDetectionToolsApi10RemoteForensicsAnalysisExternalIdDetectionToolsGet = (
    analysisExternalId: string,
    params: RequestParams = {},
  ) =>
    this.request<
      PortalsFrontendApiForensicsDeprecatedAppDetectionToolListResponse,
      ErrorResponse | HTTPValidationError
    >({
      path: `/api/1.0/remote-forensics/${analysisExternalId}/detection-tools`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags forensics
   * @name GetAnalysisProcessesApi10RemoteForensicsAnalysisExternalIdProcessesGet
   * @summary Get Analysis Processes
   * @request GET:/api/1.0/remote-forensics/{analysis_external_id}/processes
   * @deprecated
   * @secure
   */
  getAnalysisProcessesApi10RemoteForensicsAnalysisExternalIdProcessesGet = (
    analysisExternalId: string,
    params: RequestParams = {},
  ) =>
    this.request<PortalsFrontendApiForensicsDeprecatedAppProcessNodeListResponse, ErrorResponse | HTTPValidationError>({
      path: `/api/1.0/remote-forensics/${analysisExternalId}/processes`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags forensics
   * @name GetAnalysisMemoryAnalysisApi10RemoteForensicsAnalysisExternalIdMemoryAnalysisGet
   * @summary Get Analysis Memory Analysis
   * @request GET:/api/1.0/remote-forensics/{analysis_external_id}/memory-analysis
   * @deprecated
   * @secure
   */
  getAnalysisMemoryAnalysisApi10RemoteForensicsAnalysisExternalIdMemoryAnalysisGet = (
    analysisExternalId: string,
    params: RequestParams = {},
  ) =>
    this.request<
      PortalsFrontendApiForensicsDeprecatedAppMemoryAnalysisEntryListResponse,
      ErrorResponse | HTTPValidationError
    >({
      path: `/api/1.0/remote-forensics/${analysisExternalId}/memory-analysis`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags forensics
   * @name GetAnalysisScheduledTasksApi10RemoteForensicsAnalysisExternalIdScheduledTasksGet
   * @summary Get Analysis Scheduled Tasks
   * @request GET:/api/1.0/remote-forensics/{analysis_external_id}/scheduled-tasks
   * @deprecated
   * @secure
   */
  getAnalysisScheduledTasksApi10RemoteForensicsAnalysisExternalIdScheduledTasksGet = (
    analysisExternalId: string,
    params: RequestParams = {},
  ) =>
    this.request<
      PortalsFrontendApiForensicsDeprecatedAppScheduledTasksListResponse,
      ErrorResponse | HTTPValidationError
    >({
      path: `/api/1.0/remote-forensics/${analysisExternalId}/scheduled-tasks`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags forensics
   * @name GetAnalysisSuspiciousExtensionsApi10RemoteForensicsAnalysisExternalIdSuspiciousExtensionsGet
   * @summary Get Analysis Suspicious Extensions
   * @request GET:/api/1.0/remote-forensics/{analysis_external_id}/suspicious-extensions
   * @deprecated
   * @secure
   */
  getAnalysisSuspiciousExtensionsApi10RemoteForensicsAnalysisExternalIdSuspiciousExtensionsGet = (
    analysisExternalId: string,
    params: RequestParams = {},
  ) =>
    this.request<
      PortalsFrontendApiForensicsDeprecatedAppSuspiciousExtensionsListResponse,
      ErrorResponse | HTTPValidationError
    >({
      path: `/api/1.0/remote-forensics/${analysisExternalId}/suspicious-extensions`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags forensics
   * @name GetAnalysisSuspiciousHashesApi10RemoteForensicsAnalysisExternalIdSuspiciousHashesGet
   * @summary Get Analysis Suspicious Hashes
   * @request GET:/api/1.0/remote-forensics/{analysis_external_id}/suspicious-hashes
   * @deprecated
   * @secure
   */
  getAnalysisSuspiciousHashesApi10RemoteForensicsAnalysisExternalIdSuspiciousHashesGet = (
    analysisExternalId: string,
    params: RequestParams = {},
  ) =>
    this.request<
      PortalsFrontendApiForensicsDeprecatedAppSuspiciousHashListResponse,
      ErrorResponse | HTTPValidationError
    >({
      path: `/api/1.0/remote-forensics/${analysisExternalId}/suspicious-hashes`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags forensics
   * @name GetAnalysisSuspiciousFilesApi10RemoteForensicsAnalysisExternalIdSuspiciousFilesGet
   * @summary Get Analysis Suspicious Files
   * @request GET:/api/1.0/remote-forensics/{analysis_external_id}/suspicious-files
   * @deprecated
   * @secure
   */
  getAnalysisSuspiciousFilesApi10RemoteForensicsAnalysisExternalIdSuspiciousFilesGet = (
    analysisExternalId: string,
    params: RequestParams = {},
  ) =>
    this.request<
      PortalsFrontendApiForensicsDeprecatedAppSuspiciousFilesListResponse,
      ErrorResponse | HTTPValidationError
    >({
      path: `/api/1.0/remote-forensics/${analysisExternalId}/suspicious-files`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags forensics
   * @name GetFilesystemRemoteAdminToolsApi10RemoteForensicsAnalysisExternalIdFilesystemRemoteAdminToolsGet
   * @summary Get Filesystem Remote Admin Tools
   * @request GET:/api/1.0/remote-forensics/{analysis_external_id}/filesystem-remote-admin-tools
   * @deprecated
   * @secure
   */
  getFilesystemRemoteAdminToolsApi10RemoteForensicsAnalysisExternalIdFilesystemRemoteAdminToolsGet = (
    analysisExternalId: string,
    params: RequestParams = {},
  ) =>
    this.request<
      PortalsFrontendApiForensicsDeprecatedAppFilesystemRemoteAdminToolListResponse,
      ErrorResponse | HTTPValidationError
    >({
      path: `/api/1.0/remote-forensics/${analysisExternalId}/filesystem-remote-admin-tools`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags forensics
   * @name GetAnalysisAutorunKeysApi10RemoteForensicsAnalysisExternalIdAutorunKeysGet
   * @summary Get Analysis Autorun Keys
   * @request GET:/api/1.0/remote-forensics/{analysis_external_id}/autorun-keys
   * @deprecated
   * @secure
   */
  getAnalysisAutorunKeysApi10RemoteForensicsAnalysisExternalIdAutorunKeysGet = (
    analysisExternalId: string,
    params: RequestParams = {},
  ) =>
    this.request<
      PortalsFrontendApiForensicsDeprecatedAppConfidenceRegistryKeyListResponse,
      ErrorResponse | HTTPValidationError
    >({
      path: `/api/1.0/remote-forensics/${analysisExternalId}/autorun-keys`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags forensics
   * @name GetAnalysisAmcacheApi10RemoteForensicsAnalysisExternalIdAmcacheGet
   * @summary Get Analysis Amcache
   * @request GET:/api/1.0/remote-forensics/{analysis_external_id}/amcache
   * @deprecated
   * @secure
   */
  getAnalysisAmcacheApi10RemoteForensicsAnalysisExternalIdAmcacheGet = (
    analysisExternalId: string,
    params: RequestParams = {},
  ) =>
    this.request<PortalsFrontendApiForensicsDeprecatedAppAmcacheEntryListResponse, ErrorResponse | HTTPValidationError>(
      {
        path: `/api/1.0/remote-forensics/${analysisExternalId}/amcache`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      },
    );
  /**
   * No description
   *
   * @tags forensics
   * @name GetAnalysisRemoteAdministrationToolsApi10RemoteForensicsAnalysisExternalIdRegistryRemoteAdminToolsGet
   * @summary Get Analysis Remote Administration Tools
   * @request GET:/api/1.0/remote-forensics/{analysis_external_id}/registry-remote-admin-tools
   * @deprecated
   * @secure
   */
  getAnalysisRemoteAdministrationToolsApi10RemoteForensicsAnalysisExternalIdRegistryRemoteAdminToolsGet = (
    analysisExternalId: string,
    params: RequestParams = {},
  ) =>
    this.request<
      PortalsFrontendApiForensicsDeprecatedAppRegistryRemoteAdminToolListResponse,
      ErrorResponse | HTTPValidationError
    >({
      path: `/api/1.0/remote-forensics/${analysisExternalId}/registry-remote-admin-tools`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags forensics
   * @name GetMalwareDroppersApi10RemoteForensicsAnalysisExternalIdMalwareDroppersGet
   * @summary Get Malware Droppers
   * @request GET:/api/1.0/remote-forensics/{analysis_external_id}/malware-droppers
   * @deprecated
   * @secure
   */
  getMalwareDroppersApi10RemoteForensicsAnalysisExternalIdMalwareDroppersGet = (
    analysisExternalId: string,
    params: RequestParams = {},
  ) =>
    this.request<
      PortalsFrontendApiForensicsDeprecatedAppMalwareDropperListResponse,
      ErrorResponse | HTTPValidationError
    >({
      path: `/api/1.0/remote-forensics/${analysisExternalId}/malware-droppers`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags forensics
   * @name GetAppCompatCacheApi10RemoteForensicsAnalysisExternalIdAppCompatCacheGet
   * @summary Get App Compat Cache
   * @request GET:/api/1.0/remote-forensics/{analysis_external_id}/app-compat-cache
   * @deprecated
   * @secure
   */
  getAppCompatCacheApi10RemoteForensicsAnalysisExternalIdAppCompatCacheGet = (
    analysisExternalId: string,
    params: RequestParams = {},
  ) =>
    this.request<
      PortalsFrontendApiForensicsDeprecatedAppConfidenceRegistryKeyListResponse,
      ErrorResponse | HTTPValidationError
    >({
      path: `/api/1.0/remote-forensics/${analysisExternalId}/app-compat-cache`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags forensics
   * @name GetRegistrySuspiciousKeysApi10RemoteForensicsAnalysisExternalIdRegistrySuspiciousKeysGet
   * @summary Get Registry Suspicious Keys
   * @request GET:/api/1.0/remote-forensics/{analysis_external_id}/registry-suspicious-keys
   * @deprecated
   * @secure
   */
  getRegistrySuspiciousKeysApi10RemoteForensicsAnalysisExternalIdRegistrySuspiciousKeysGet = (
    analysisExternalId: string,
    params: RequestParams = {},
  ) =>
    this.request<
      PortalsFrontendApiForensicsDeprecatedAppRegistryDetectionListResponse,
      ErrorResponse | HTTPValidationError
    >({
      path: `/api/1.0/remote-forensics/${analysisExternalId}/registry-suspicious-keys`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags forensics
   * @name GetAnalysisDnsQueriesApi10RemoteForensicsAnalysisExternalIdDnsQueriesGet
   * @summary Get Analysis Dns Queries
   * @request GET:/api/1.0/remote-forensics/{analysis_external_id}/dns-queries
   * @deprecated
   * @secure
   */
  getAnalysisDnsQueriesApi10RemoteForensicsAnalysisExternalIdDnsQueriesGet = (
    analysisExternalId: string,
    params: RequestParams = {},
  ) =>
    this.request<PortalsFrontendApiForensicsDeprecatedAppDNSQueryListResponse, ErrorResponse | HTTPValidationError>({
      path: `/api/1.0/remote-forensics/${analysisExternalId}/dns-queries`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags forensics
   * @name GetAnalysisNetConnectionsApi10RemoteForensicsAnalysisExternalIdNetConnectionsGet
   * @summary Get Analysis Net Connections
   * @request GET:/api/1.0/remote-forensics/{analysis_external_id}/net-connections
   * @deprecated
   * @secure
   */
  getAnalysisNetConnectionsApi10RemoteForensicsAnalysisExternalIdNetConnectionsGet = (
    analysisExternalId: string,
    params: RequestParams = {},
  ) =>
    this.request<
      PortalsFrontendApiForensicsDeprecatedAppNetConnectionListResponse,
      ErrorResponse | HTTPValidationError
    >({
      path: `/api/1.0/remote-forensics/${analysisExternalId}/net-connections`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags forensics
   * @name GetAnalysisOpenPortsApi10RemoteForensicsAnalysisExternalIdOpenPortsGet
   * @summary Get Analysis Open Ports
   * @request GET:/api/1.0/remote-forensics/{analysis_external_id}/open-ports
   * @deprecated
   * @secure
   */
  getAnalysisOpenPortsApi10RemoteForensicsAnalysisExternalIdOpenPortsGet = (
    analysisExternalId: string,
    params: RequestParams = {},
  ) =>
    this.request<PortalsFrontendApiForensicsDeprecatedAppNetOpenPortsListResponse, ErrorResponse | HTTPValidationError>(
      {
        path: `/api/1.0/remote-forensics/${analysisExternalId}/open-ports`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      },
    );
  /**
   * No description
   *
   * @tags forensics
   * @name GetAnalysisBrowserSuspiciousVisitsApi10RemoteForensicsAnalysisExternalIdBrowserSuspiciousVisitsGet
   * @summary Get Analysis Browser Suspicious Visits
   * @request GET:/api/1.0/remote-forensics/{analysis_external_id}/browser-suspicious-visits
   * @deprecated
   * @secure
   */
  getAnalysisBrowserSuspiciousVisitsApi10RemoteForensicsAnalysisExternalIdBrowserSuspiciousVisitsGet = (
    analysisExternalId: string,
    params: RequestParams = {},
  ) =>
    this.request<BrowserSuspiciousVisisListResponse, ErrorResponse | HTTPValidationError>({
      path: `/api/1.0/remote-forensics/${analysisExternalId}/browser-suspicious-visits`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags forensics
   * @name GetAnalysisApplicationsApi10RemoteForensicsAnalysisExternalIdApplicationsGet
   * @summary Get Analysis Applications
   * @request GET:/api/1.0/remote-forensics/{analysis_external_id}/applications
   * @deprecated
   * @secure
   */
  getAnalysisApplicationsApi10RemoteForensicsAnalysisExternalIdApplicationsGet = (
    analysisExternalId: string,
    params: RequestParams = {},
  ) =>
    this.request<PortalsFrontendApiForensicsDeprecatedAppApplicationListResponse, ErrorResponse | HTTPValidationError>({
      path: `/api/1.0/remote-forensics/${analysisExternalId}/applications`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags forensics
   * @name GetAnalysisExternalstorageApi10RemoteForensicsAnalysisExternalIdExternalstorageGet
   * @summary Get Analysis Externalstorage
   * @request GET:/api/1.0/remote-forensics/{analysis_external_id}/externalstorage
   * @deprecated
   * @secure
   */
  getAnalysisExternalstorageApi10RemoteForensicsAnalysisExternalIdExternalstorageGet = (
    analysisExternalId: string,
    params: RequestParams = {},
  ) =>
    this.request<
      PortalsFrontendApiForensicsDeprecatedAppExternalStorageListResponse,
      ErrorResponse | HTTPValidationError
    >({
      path: `/api/1.0/remote-forensics/${analysisExternalId}/externalstorage`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags forensics
   * @name GetStatisticsStatusApi10RemoteForensicsStatisticsStatusGet
   * @summary Get Statistics Status
   * @request GET:/api/1.0/remote-forensics/statistics/status
   * @deprecated
   * @secure
   */
  getStatisticsStatusApi10RemoteForensicsStatisticsStatusGet = (
    query: GetStatisticsStatusApi10RemoteForensicsStatisticsStatusGetParams,
    params: RequestParams = {},
  ) =>
    this.request<StatisticsCountListResponse, ErrorResponse | HTTPValidationError>({
      path: `/api/1.0/remote-forensics/statistics/status`,
      method: "GET",
      query: query,
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags forensics
   * @name GetStatisticsStatusHistogramApi10RemoteForensicsStatisticsStatusHistogramGet
   * @summary Get Statistics Status Histogram
   * @request GET:/api/1.0/remote-forensics/statistics/status/histogram
   * @deprecated
   * @secure
   */
  getStatisticsStatusHistogramApi10RemoteForensicsStatisticsStatusHistogramGet = (
    query: GetStatisticsStatusHistogramApi10RemoteForensicsStatisticsStatusHistogramGetParams,
    params: RequestParams = {},
  ) =>
    this.request<StatisticsHistogramCountListResponse, ErrorResponse | HTTPValidationError>({
      path: `/api/1.0/remote-forensics/statistics/status/histogram`,
      method: "GET",
      query: query,
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags forensics
   * @name GetStatisticsPlatformApi10RemoteForensicsStatisticsPlatformGet
   * @summary Get Statistics Platform
   * @request GET:/api/1.0/remote-forensics/statistics/platform
   * @deprecated
   * @secure
   */
  getStatisticsPlatformApi10RemoteForensicsStatisticsPlatformGet = (
    query: GetStatisticsPlatformApi10RemoteForensicsStatisticsPlatformGetParams,
    params: RequestParams = {},
  ) =>
    this.request<StatisticsCountListResponse, ErrorResponse | HTTPValidationError>({
      path: `/api/1.0/remote-forensics/statistics/platform`,
      method: "GET",
      query: query,
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags forensics
   * @name GetStatisticsPlatformHistogramApi10RemoteForensicsStatisticsPlatformHistogramGet
   * @summary Get Statistics Platform Histogram
   * @request GET:/api/1.0/remote-forensics/statistics/platform/histogram
   * @deprecated
   * @secure
   */
  getStatisticsPlatformHistogramApi10RemoteForensicsStatisticsPlatformHistogramGet = (
    query: GetStatisticsPlatformHistogramApi10RemoteForensicsStatisticsPlatformHistogramGetParams,
    params: RequestParams = {},
  ) =>
    this.request<StatisticsHistogramCountListResponse, ErrorResponse | HTTPValidationError>({
      path: `/api/1.0/remote-forensics/statistics/platform/histogram`,
      method: "GET",
      query: query,
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags forensics
   * @name GetStatisticsInfectionVectorHistogramApi10RemoteForensicsStatisticsInfectionTypeGet
   * @summary Get Statistics Infection Vector Histogram
   * @request GET:/api/1.0/remote-forensics/statistics/infection-type
   * @deprecated
   * @secure
   */
  getStatisticsInfectionVectorHistogramApi10RemoteForensicsStatisticsInfectionTypeGet = (
    query: GetStatisticsInfectionVectorHistogramApi10RemoteForensicsStatisticsInfectionTypeGetParams,
    params: RequestParams = {},
  ) =>
    this.request<StatisticsCountListResponse, ErrorResponse | HTTPValidationError>({
      path: `/api/1.0/remote-forensics/statistics/infection-type`,
      method: "GET",
      query: query,
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags forensics
   * @name GetStatisticsInfectionTypeHistogramApi10RemoteForensicsStatisticsInfectionTypeHistogramGet
   * @summary Get Statistics Infection Type Histogram
   * @request GET:/api/1.0/remote-forensics/statistics/infection-type/histogram
   * @deprecated
   * @secure
   */
  getStatisticsInfectionTypeHistogramApi10RemoteForensicsStatisticsInfectionTypeHistogramGet = (
    query: GetStatisticsInfectionTypeHistogramApi10RemoteForensicsStatisticsInfectionTypeHistogramGetParams,
    params: RequestParams = {},
  ) =>
    this.request<StatisticsHistogramCountListResponse, ErrorResponse | HTTPValidationError>({
      path: `/api/1.0/remote-forensics/statistics/infection-type/histogram`,
      method: "GET",
      query: query,
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags forensics
   * @name GetDownloadApi20RemoteForensicsDownloadGet
   * @summary Get Download
   * @request GET:/api/2.0/remote-forensics/download
   * @secure
   */
  getDownloadApi20RemoteForensicsDownloadGet = (params: RequestParams = {}) =>
    this.request<PortalsFrontendApiForensicsModelDownloadInfoListResponse, ErrorResponse | HTTPValidationError>({
      path: `/api/2.0/remote-forensics/download`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags forensics
   * @name GetAnalysesApi20RemoteForensicsGet
   * @summary Get Analyses
   * @request GET:/api/2.0/remote-forensics/
   * @secure
   */
  getAnalysesApi20RemoteForensicsGet = (query: GetAnalysesApi20RemoteForensicsGetParams, params: RequestParams = {}) =>
    this.request<
      PortalsFrontendApiForensicsModelAnalysisPreviewPageDetailResponse,
      ErrorResponse | HTTPValidationError
    >({
      path: `/api/2.0/remote-forensics/`,
      method: "GET",
      query: query,
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags forensics
   * @name GetAnalysisOverviewApi20RemoteForensicsAnalysisExternalIdGet
   * @summary Get Analysis Overview
   * @request GET:/api/2.0/remote-forensics/{analysis_external_id}
   * @secure
   */
  getAnalysisOverviewApi20RemoteForensicsAnalysisExternalIdGet = (
    analysisExternalId: string,
    params: RequestParams = {},
  ) =>
    this.request<PortalsFrontendApiForensicsModelAnalysisDetailResponse, ErrorResponse | HTTPValidationError>({
      path: `/api/2.0/remote-forensics/${analysisExternalId}`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags forensics
   * @name PatchAnalysisApi20RemoteForensicsAnalysisExternalIdPatch
   * @summary Patch Analysis
   * @request PATCH:/api/2.0/remote-forensics/{analysis_external_id}
   * @secure
   */
  patchAnalysisApi20RemoteForensicsAnalysisExternalIdPatch = (
    analysisExternalId: string,
    data: PortalsFrontendApiForensicsModelAnalysisPatchBody,
    params: RequestParams = {},
  ) =>
    this.request<PortalsFrontendApiForensicsModelAnalysisDetailResponse, ErrorResponse | HTTPValidationError>({
      path: `/api/2.0/remote-forensics/${analysisExternalId}`,
      method: "PATCH",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags forensics
   * @name GetAnalysisCountersApi20RemoteForensicsAnalysisExternalIdSummaryGet
   * @summary Get Analysis Counters
   * @request GET:/api/2.0/remote-forensics/{analysis_external_id}/summary
   * @secure
   */
  getAnalysisCountersApi20RemoteForensicsAnalysisExternalIdSummaryGet = (
    analysisExternalId: string,
    params: RequestParams = {},
  ) =>
    this.request<PortalsFrontendApiForensicsModelResultSummaryDetailResponse, ErrorResponse | HTTPValidationError>({
      path: `/api/2.0/remote-forensics/${analysisExternalId}/summary`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags forensics
   * @name GetAnalysisDetectionToolsApi20RemoteForensicsAnalysisExternalIdDetectionToolsGet
   * @summary Get Analysis Detection Tools
   * @request GET:/api/2.0/remote-forensics/{analysis_external_id}/detection-tools
   * @secure
   */
  getAnalysisDetectionToolsApi20RemoteForensicsAnalysisExternalIdDetectionToolsGet = (
    analysisExternalId: string,
    params: RequestParams = {},
  ) =>
    this.request<PortalsFrontendApiForensicsModelDetectionToolListResponse, ErrorResponse | HTTPValidationError>({
      path: `/api/2.0/remote-forensics/${analysisExternalId}/detection-tools`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags forensics
   * @name GetAnalysisProcessesApi20RemoteForensicsAnalysisExternalIdProcessesGet
   * @summary Get Analysis Processes
   * @request GET:/api/2.0/remote-forensics/{analysis_external_id}/processes
   * @secure
   */
  getAnalysisProcessesApi20RemoteForensicsAnalysisExternalIdProcessesGet = (
    analysisExternalId: string,
    params: RequestParams = {},
  ) =>
    this.request<PortalsFrontendApiForensicsModelProcessNodeListResponse, ErrorResponse | HTTPValidationError>({
      path: `/api/2.0/remote-forensics/${analysisExternalId}/processes`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags forensics
   * @name GetAnalysisMemoryAnalysisApi20RemoteForensicsAnalysisExternalIdMemoryAnalysisGet
   * @summary Get Analysis Memory Analysis
   * @request GET:/api/2.0/remote-forensics/{analysis_external_id}/memory-analysis
   * @secure
   */
  getAnalysisMemoryAnalysisApi20RemoteForensicsAnalysisExternalIdMemoryAnalysisGet = (
    analysisExternalId: string,
    params: RequestParams = {},
  ) =>
    this.request<PortalsFrontendApiForensicsModelMemoryAnalysisEntryListResponse, ErrorResponse | HTTPValidationError>({
      path: `/api/2.0/remote-forensics/${analysisExternalId}/memory-analysis`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags forensics
   * @name GetAnalysisScheduledTasksApi20RemoteForensicsAnalysisExternalIdScheduledTasksGet
   * @summary Get Analysis Scheduled Tasks
   * @request GET:/api/2.0/remote-forensics/{analysis_external_id}/scheduled-tasks
   * @secure
   */
  getAnalysisScheduledTasksApi20RemoteForensicsAnalysisExternalIdScheduledTasksGet = (
    analysisExternalId: string,
    params: RequestParams = {},
  ) =>
    this.request<PortalsFrontendApiForensicsModelScheduledTasksListResponse, ErrorResponse | HTTPValidationError>({
      path: `/api/2.0/remote-forensics/${analysisExternalId}/scheduled-tasks`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags forensics
   * @name GetAnalysisSuspiciousExtensionsApi20RemoteForensicsAnalysisExternalIdSuspiciousExtensionsGet
   * @summary Get Analysis Suspicious Extensions
   * @request GET:/api/2.0/remote-forensics/{analysis_external_id}/suspicious-extensions
   * @secure
   */
  getAnalysisSuspiciousExtensionsApi20RemoteForensicsAnalysisExternalIdSuspiciousExtensionsGet = (
    analysisExternalId: string,
    params: RequestParams = {},
  ) =>
    this.request<PortalsFrontendApiForensicsModelSuspiciousExtensionsListResponse, ErrorResponse | HTTPValidationError>(
      {
        path: `/api/2.0/remote-forensics/${analysisExternalId}/suspicious-extensions`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      },
    );
  /**
   * No description
   *
   * @tags forensics
   * @name GetAnalysisSuspiciousHashesApi20RemoteForensicsAnalysisExternalIdSuspiciousHashesGet
   * @summary Get Analysis Suspicious Hashes
   * @request GET:/api/2.0/remote-forensics/{analysis_external_id}/suspicious-hashes
   * @secure
   */
  getAnalysisSuspiciousHashesApi20RemoteForensicsAnalysisExternalIdSuspiciousHashesGet = (
    analysisExternalId: string,
    params: RequestParams = {},
  ) =>
    this.request<PortalsFrontendApiForensicsModelSuspiciousHashListResponse, ErrorResponse | HTTPValidationError>({
      path: `/api/2.0/remote-forensics/${analysisExternalId}/suspicious-hashes`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags forensics
   * @name GetAnalysisNetConnectionsApi20RemoteForensicsAnalysisExternalIdNetConnectionsGet
   * @summary Get Analysis Net Connections
   * @request GET:/api/2.0/remote-forensics/{analysis_external_id}/net-connections
   * @secure
   */
  getAnalysisNetConnectionsApi20RemoteForensicsAnalysisExternalIdNetConnectionsGet = (
    analysisExternalId: string,
    params: RequestParams = {},
  ) =>
    this.request<PortalsFrontendApiForensicsModelNetConnectionListResponse, ErrorResponse | HTTPValidationError>({
      path: `/api/2.0/remote-forensics/${analysisExternalId}/net-connections`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags forensics
   * @name GetAnalysisOpenPortsApi20RemoteForensicsAnalysisExternalIdOpenPortsGet
   * @summary Get Analysis Open Ports
   * @request GET:/api/2.0/remote-forensics/{analysis_external_id}/open-ports
   * @secure
   */
  getAnalysisOpenPortsApi20RemoteForensicsAnalysisExternalIdOpenPortsGet = (
    analysisExternalId: string,
    params: RequestParams = {},
  ) =>
    this.request<PortalsFrontendApiForensicsModelNetOpenPortsListResponse, ErrorResponse | HTTPValidationError>({
      path: `/api/2.0/remote-forensics/${analysisExternalId}/open-ports`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags forensics
   * @name GetAnalysisBrowserSuspiciousVisitsApi20RemoteForensicsAnalysisExternalIdBrowserSuspiciousVisitsGet
   * @summary Get Analysis Browser Suspicious Visits
   * @request GET:/api/2.0/remote-forensics/{analysis_external_id}/browser-suspicious-visits
   * @secure
   */
  getAnalysisBrowserSuspiciousVisitsApi20RemoteForensicsAnalysisExternalIdBrowserSuspiciousVisitsGet = (
    analysisExternalId: string,
    params: RequestParams = {},
  ) =>
    this.request<BrowserSuspiciousVisitListResponse, ErrorResponse | HTTPValidationError>({
      path: `/api/2.0/remote-forensics/${analysisExternalId}/browser-suspicious-visits`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags forensics
   * @name GetAnalysisApplicationsApi20RemoteForensicsAnalysisExternalIdApplicationsGet
   * @summary Get Analysis Applications
   * @request GET:/api/2.0/remote-forensics/{analysis_external_id}/applications
   * @secure
   */
  getAnalysisApplicationsApi20RemoteForensicsAnalysisExternalIdApplicationsGet = (
    analysisExternalId: string,
    params: RequestParams = {},
  ) =>
    this.request<PortalsFrontendApiForensicsModelApplicationListResponse, ErrorResponse | HTTPValidationError>({
      path: `/api/2.0/remote-forensics/${analysisExternalId}/applications`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags forensics
   * @name GetAnalysisSuspiciousFilesApi20RemoteForensicsAnalysisExternalIdSuspiciousFilesGet
   * @summary Get Analysis Suspicious Files
   * @request GET:/api/2.0/remote-forensics/{analysis_external_id}/suspicious-files
   * @secure
   */
  getAnalysisSuspiciousFilesApi20RemoteForensicsAnalysisExternalIdSuspiciousFilesGet = (
    analysisExternalId: string,
    params: RequestParams = {},
  ) =>
    this.request<PortalsFrontendApiForensicsModelSuspiciousFilesListResponse, ErrorResponse | HTTPValidationError>({
      path: `/api/2.0/remote-forensics/${analysisExternalId}/suspicious-files`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags forensics
   * @name GetFilesystemRemoteAdminToolsApi20RemoteForensicsAnalysisExternalIdFilesystemRemoteAdminToolsGet
   * @summary Get Filesystem Remote Admin Tools
   * @request GET:/api/2.0/remote-forensics/{analysis_external_id}/filesystem-remote-admin-tools
   * @secure
   */
  getFilesystemRemoteAdminToolsApi20RemoteForensicsAnalysisExternalIdFilesystemRemoteAdminToolsGet = (
    analysisExternalId: string,
    params: RequestParams = {},
  ) =>
    this.request<
      PortalsFrontendApiForensicsModelFilesystemRemoteAdminToolListResponse,
      ErrorResponse | HTTPValidationError
    >({
      path: `/api/2.0/remote-forensics/${analysisExternalId}/filesystem-remote-admin-tools`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags forensics
   * @name GetAnalysisAutorunKeysApi20RemoteForensicsAnalysisExternalIdAutorunKeysGet
   * @summary Get Analysis Autorun Keys
   * @request GET:/api/2.0/remote-forensics/{analysis_external_id}/autorun-keys
   * @secure
   */
  getAnalysisAutorunKeysApi20RemoteForensicsAnalysisExternalIdAutorunKeysGet = (
    analysisExternalId: string,
    params: RequestParams = {},
  ) =>
    this.request<
      PortalsFrontendApiForensicsModelConfidenceRegistryKeyListResponse,
      ErrorResponse | HTTPValidationError
    >({
      path: `/api/2.0/remote-forensics/${analysisExternalId}/autorun-keys`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags forensics
   * @name GetAnalysisAmcacheApi20RemoteForensicsAnalysisExternalIdAmcacheGet
   * @summary Get Analysis Amcache
   * @request GET:/api/2.0/remote-forensics/{analysis_external_id}/amcache
   * @secure
   */
  getAnalysisAmcacheApi20RemoteForensicsAnalysisExternalIdAmcacheGet = (
    analysisExternalId: string,
    params: RequestParams = {},
  ) =>
    this.request<PortalsFrontendApiForensicsModelAmcacheEntryListResponse, ErrorResponse | HTTPValidationError>({
      path: `/api/2.0/remote-forensics/${analysisExternalId}/amcache`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags forensics
   * @name GetAnalysisRemoteAdministrationToolsApi20RemoteForensicsAnalysisExternalIdRegistryRemoteAdminToolsGet
   * @summary Get Analysis Remote Administration Tools
   * @request GET:/api/2.0/remote-forensics/{analysis_external_id}/registry-remote-admin-tools
   * @secure
   */
  getAnalysisRemoteAdministrationToolsApi20RemoteForensicsAnalysisExternalIdRegistryRemoteAdminToolsGet = (
    analysisExternalId: string,
    params: RequestParams = {},
  ) =>
    this.request<
      PortalsFrontendApiForensicsModelRegistryRemoteAdminToolListResponse,
      ErrorResponse | HTTPValidationError
    >({
      path: `/api/2.0/remote-forensics/${analysisExternalId}/registry-remote-admin-tools`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags forensics
   * @name GetMalwareDroppersApi20RemoteForensicsAnalysisExternalIdMalwareDroppersGet
   * @summary Get Malware Droppers
   * @request GET:/api/2.0/remote-forensics/{analysis_external_id}/malware-droppers
   * @secure
   */
  getMalwareDroppersApi20RemoteForensicsAnalysisExternalIdMalwareDroppersGet = (
    analysisExternalId: string,
    params: RequestParams = {},
  ) =>
    this.request<PortalsFrontendApiForensicsModelMalwareDropperListResponse, ErrorResponse | HTTPValidationError>({
      path: `/api/2.0/remote-forensics/${analysisExternalId}/malware-droppers`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags forensics
   * @name GetAppCompatCacheApi20RemoteForensicsAnalysisExternalIdAppCompatCacheGet
   * @summary Get App Compat Cache
   * @request GET:/api/2.0/remote-forensics/{analysis_external_id}/app-compat-cache
   * @secure
   */
  getAppCompatCacheApi20RemoteForensicsAnalysisExternalIdAppCompatCacheGet = (
    analysisExternalId: string,
    params: RequestParams = {},
  ) =>
    this.request<
      PortalsFrontendApiForensicsModelConfidenceRegistryKeyListResponse,
      ErrorResponse | HTTPValidationError
    >({
      path: `/api/2.0/remote-forensics/${analysisExternalId}/app-compat-cache`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags forensics
   * @name GetRegistrySuspiciousKeysApi20RemoteForensicsAnalysisExternalIdRegistrySuspiciousKeysGet
   * @summary Get Registry Suspicious Keys
   * @request GET:/api/2.0/remote-forensics/{analysis_external_id}/registry-suspicious-keys
   * @secure
   */
  getRegistrySuspiciousKeysApi20RemoteForensicsAnalysisExternalIdRegistrySuspiciousKeysGet = (
    analysisExternalId: string,
    params: RequestParams = {},
  ) =>
    this.request<PortalsFrontendApiForensicsModelRegistryDetectionListResponse, ErrorResponse | HTTPValidationError>({
      path: `/api/2.0/remote-forensics/${analysisExternalId}/registry-suspicious-keys`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags forensics
   * @name GetAnalysisDnsQueriesApi20RemoteForensicsAnalysisExternalIdDnsQueriesGet
   * @summary Get Analysis Dns Queries
   * @request GET:/api/2.0/remote-forensics/{analysis_external_id}/dns-queries
   * @secure
   */
  getAnalysisDnsQueriesApi20RemoteForensicsAnalysisExternalIdDnsQueriesGet = (
    analysisExternalId: string,
    params: RequestParams = {},
  ) =>
    this.request<PortalsFrontendApiForensicsModelDNSQueryListResponse, ErrorResponse | HTTPValidationError>({
      path: `/api/2.0/remote-forensics/${analysisExternalId}/dns-queries`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags forensics
   * @name GetAnalysisExternalstorageApi20RemoteForensicsAnalysisExternalIdExternalstorageGet
   * @summary Get Analysis Externalstorage
   * @request GET:/api/2.0/remote-forensics/{analysis_external_id}/externalstorage
   * @secure
   */
  getAnalysisExternalstorageApi20RemoteForensicsAnalysisExternalIdExternalstorageGet = (
    analysisExternalId: string,
    params: RequestParams = {},
  ) =>
    this.request<PortalsFrontendApiForensicsModelExternalStorageListResponse, ErrorResponse | HTTPValidationError>({
      path: `/api/2.0/remote-forensics/${analysisExternalId}/externalstorage`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags forensics
   * @name GetStatisticsStatusApi20RemoteForensicsStatisticsStatusGet
   * @summary Get Statistics Status
   * @request GET:/api/2.0/remote-forensics/statistics/status
   * @secure
   */
  getStatisticsStatusApi20RemoteForensicsStatisticsStatusGet = (
    query: GetStatisticsStatusApi20RemoteForensicsStatisticsStatusGetParams,
    params: RequestParams = {},
  ) =>
    this.request<StatisticsCountListResponse, ErrorResponse | HTTPValidationError>({
      path: `/api/2.0/remote-forensics/statistics/status`,
      method: "GET",
      query: query,
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags forensics
   * @name GetStatisticsStatusHistogramApi20RemoteForensicsStatisticsStatusHistogramGet
   * @summary Get Statistics Status Histogram
   * @request GET:/api/2.0/remote-forensics/statistics/status/histogram
   * @secure
   */
  getStatisticsStatusHistogramApi20RemoteForensicsStatisticsStatusHistogramGet = (
    query: GetStatisticsStatusHistogramApi20RemoteForensicsStatisticsStatusHistogramGetParams,
    params: RequestParams = {},
  ) =>
    this.request<StatisticsHistogramCountListResponse, ErrorResponse | HTTPValidationError>({
      path: `/api/2.0/remote-forensics/statistics/status/histogram`,
      method: "GET",
      query: query,
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags forensics
   * @name GetStatisticsPlatformApi20RemoteForensicsStatisticsPlatformGet
   * @summary Get Statistics Platform
   * @request GET:/api/2.0/remote-forensics/statistics/platform
   * @secure
   */
  getStatisticsPlatformApi20RemoteForensicsStatisticsPlatformGet = (
    query: GetStatisticsPlatformApi20RemoteForensicsStatisticsPlatformGetParams,
    params: RequestParams = {},
  ) =>
    this.request<StatisticsCountListResponse, ErrorResponse | HTTPValidationError>({
      path: `/api/2.0/remote-forensics/statistics/platform`,
      method: "GET",
      query: query,
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags forensics
   * @name GetStatisticsPlatformHistogramApi20RemoteForensicsStatisticsPlatformHistogramGet
   * @summary Get Statistics Platform Histogram
   * @request GET:/api/2.0/remote-forensics/statistics/platform/histogram
   * @secure
   */
  getStatisticsPlatformHistogramApi20RemoteForensicsStatisticsPlatformHistogramGet = (
    query: GetStatisticsPlatformHistogramApi20RemoteForensicsStatisticsPlatformHistogramGetParams,
    params: RequestParams = {},
  ) =>
    this.request<StatisticsHistogramCountListResponse, ErrorResponse | HTTPValidationError>({
      path: `/api/2.0/remote-forensics/statistics/platform/histogram`,
      method: "GET",
      query: query,
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags forensics
   * @name GetStatisticsInfectionVectorHistogramApi20RemoteForensicsStatisticsInfectionTypeGet
   * @summary Get Statistics Infection Vector Histogram
   * @request GET:/api/2.0/remote-forensics/statistics/infection-type
   * @secure
   */
  getStatisticsInfectionVectorHistogramApi20RemoteForensicsStatisticsInfectionTypeGet = (
    query: GetStatisticsInfectionVectorHistogramApi20RemoteForensicsStatisticsInfectionTypeGetParams,
    params: RequestParams = {},
  ) =>
    this.request<StatisticsCountListResponse, ErrorResponse | HTTPValidationError>({
      path: `/api/2.0/remote-forensics/statistics/infection-type`,
      method: "GET",
      query: query,
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags forensics
   * @name GetStatisticsInfectionTypeHistogramApi20RemoteForensicsStatisticsInfectionTypeHistogramGet
   * @summary Get Statistics Infection Type Histogram
   * @request GET:/api/2.0/remote-forensics/statistics/infection-type/histogram
   * @secure
   */
  getStatisticsInfectionTypeHistogramApi20RemoteForensicsStatisticsInfectionTypeHistogramGet = (
    query: GetStatisticsInfectionTypeHistogramApi20RemoteForensicsStatisticsInfectionTypeHistogramGetParams,
    params: RequestParams = {},
  ) =>
    this.request<StatisticsHistogramCountListResponse, ErrorResponse | HTTPValidationError>({
      path: `/api/2.0/remote-forensics/statistics/infection-type/histogram`,
      method: "GET",
      query: query,
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags onboarding
   * @name GetMdfeCustomerSettingsApi10OnboardingMdfeCustomerExternalIdGet
   * @summary Get Mdfe Customer Settings
   * @request GET:/api/1.0/onboarding/mdfe-customer/{external_id}
   * @secure
   */
  getMdfeCustomerSettingsApi10OnboardingMdfeCustomerExternalIdGet = (externalId: string, params: RequestParams = {}) =>
    this.request<DetailResponse, ErrorResponse | HTTPValidationError>({
      path: `/api/1.0/onboarding/mdfe-customer/${externalId}`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags onboarding
   * @name CreateMdfeCustomerSettingsApi10OnboardingMdfeCustomerExternalIdPost
   * @summary Create Mdfe Customer Settings
   * @request POST:/api/1.0/onboarding/mdfe-customer/{external_id}
   * @secure
   */
  createMdfeCustomerSettingsApi10OnboardingMdfeCustomerExternalIdPost = (
    externalId: string,
    data: MDFESetting,
    params: RequestParams = {},
  ) =>
    this.request<DetailResponse, ErrorResponse | HTTPValidationError>({
      path: `/api/1.0/onboarding/mdfe-customer/${externalId}`,
      method: "POST",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags onboarding
   * @name GetAzureLighthouseTemplateUrlApi10OnboardingSentinelCustomerAzureLighthouseGet
   * @summary Get Azure Lighthouse Template Url
   * @request GET:/api/1.0/onboarding/sentinel-customer/azure-lighthouse
   * @secure
   */
  getAzureLighthouseTemplateUrlApi10OnboardingSentinelCustomerAzureLighthouseGet = (params: RequestParams = {}) =>
    this.request<DetailResponse, ErrorResponse>({
      path: `/api/1.0/onboarding/sentinel-customer/azure-lighthouse`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags reporting
   * @name GetReportsApi20ReportGet
   * @summary Get Reports
   * @request GET:/api/2.0/report/
   * @secure
   */
  getReportsApi20ReportGet = (query: GetReportsApi20ReportGetParams, params: RequestParams = {}) =>
    this.request<PortalsFrontendApiReportingModelReportPreviewPageDetailResponse, ErrorResponse | HTTPValidationError>({
      path: `/api/2.0/report/`,
      method: "GET",
      query: query,
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags reporting
   * @name GenerateReportApi20ReportPost
   * @summary Generate Report
   * @request POST:/api/2.0/report/
   * @secure
   */
  generateReportApi20ReportPost = (data: PortalsFrontendApiReportingModelReportRequest, params: RequestParams = {}) =>
    this.request<PortalsFrontendApiReportingRouterDetailResponseReportPreview, ErrorResponse | HTTPValidationError>({
      path: `/api/2.0/report/`,
      method: "POST",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * @description Lists user-visible report types and filters out types requiring subscriptions that the current users business units don't have.
   *
   * @tags reporting
   * @name GetTypesApi20ReportTypeGet
   * @summary Get Types
   * @request GET:/api/2.0/report/type
   * @secure
   */
  getTypesApi20ReportTypeGet = (params: RequestParams = {}) =>
    this.request<PortalsFrontendApiReportingRouterListResponseReportType, ErrorResponse | HTTPValidationError>({
      path: `/api/2.0/report/type`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * @description List Types and Templates for the business unit.
   *
   * @tags reporting
   * @name GetRequestOptionApi20ReportRequestOptionGet
   * @summary Get Request Option
   * @request GET:/api/2.0/report/request-option
   * @secure
   */
  getRequestOptionApi20ReportRequestOptionGet = (params: RequestParams = {}) =>
    this.request<PortalsFrontendApiReportingRouterListResponseRequestOption, ErrorResponse | HTTPValidationError>({
      path: `/api/2.0/report/request-option`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * @description Download latest report rendering
   *
   * @tags reporting
   * @name DownloadLatestReportApi20ReportReportExternalIdDownloadGet
   * @summary Download Latest Report
   * @request GET:/api/2.0/report/{report_external_id}/download
   * @secure
   */
  downloadLatestReportApi20ReportReportExternalIdDownloadGet = (reportExternalId: string, params: RequestParams = {}) =>
    this.request<any, ErrorResponse | HTTPValidationError>({
      path: `/api/2.0/report/${reportExternalId}/download`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags reporting
   * @name DeleteReportApi20ReportReportExternalIdDelete
   * @summary Delete Report
   * @request DELETE:/api/2.0/report/{report_external_id}
   * @secure
   */
  deleteReportApi20ReportReportExternalIdDelete = (reportExternalId: string, params: RequestParams = {}) =>
    this.request<PortalsFrontendApiReportingRouterDetailResponseReportPreview, ErrorResponse | HTTPValidationError>({
      path: `/api/2.0/report/${reportExternalId}`,
      method: "DELETE",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags reporting
   * @name GetReportsApi10ReportGet
   * @summary Get Reports
   * @request GET:/api/1.0/report/
   * @deprecated
   * @secure
   */
  getReportsApi10ReportGet = (query: GetReportsApi10ReportGetParams, params: RequestParams = {}) =>
    this.request<
      PortalsFrontendApiReportingDeprecatedModelReportPreviewPageDetailResponse,
      ErrorResponse | HTTPValidationError
    >({
      path: `/api/1.0/report/`,
      method: "GET",
      query: query,
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags reporting
   * @name GenerateReportApi10ReportPost
   * @summary Generate Report
   * @request POST:/api/1.0/report/
   * @deprecated
   * @secure
   */
  generateReportApi10ReportPost = (
    query: GenerateReportApi10ReportPostParams,
    data: PortalsFrontendApiReportingDeprecatedModelReportRequest,
    params: RequestParams = {},
  ) =>
    this.request<
      PortalsFrontendApiReportingDeprecatedRouterDetailResponseReportPreview,
      ErrorResponse | HTTPValidationError
    >({
      path: `/api/1.0/report/`,
      method: "POST",
      query: query,
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * @description Lists user-visible report types and filters out types requiring subscriptions that the current users business units don't have.
   *
   * @tags reporting
   * @name GetTypesApi10ReportTypeGet
   * @summary Get Types
   * @request GET:/api/1.0/report/type
   * @deprecated
   * @secure
   */
  getTypesApi10ReportTypeGet = (query: GetTypesApi10ReportTypeGetParams, params: RequestParams = {}) =>
    this.request<
      PortalsFrontendApiReportingDeprecatedRouterListResponseReportType,
      ErrorResponse | HTTPValidationError
    >({
      path: `/api/1.0/report/type`,
      method: "GET",
      query: query,
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * @description List Types if users have Business Unit that have product. The payload includes Templates and Business Units for the type.
   *
   * @tags reporting
   * @name GetRequestOptionApi10ReportRequestOptionGet
   * @summary Get Request Option
   * @request GET:/api/1.0/report/request-option
   * @deprecated
   * @secure
   */
  getRequestOptionApi10ReportRequestOptionGet = (
    query: GetRequestOptionApi10ReportRequestOptionGetParams,
    params: RequestParams = {},
  ) =>
    this.request<
      PortalsFrontendApiReportingDeprecatedRouterListResponseRequestOption,
      ErrorResponse | HTTPValidationError
    >({
      path: `/api/1.0/report/request-option`,
      method: "GET",
      query: query,
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * @description Download latest report rendering
   *
   * @tags reporting
   * @name DownloadLatestReportApi10ReportReportExternalIdDownloadGet
   * @summary Download Latest Report
   * @request GET:/api/1.0/report/{report_external_id}/download
   * @deprecated
   * @secure
   */
  downloadLatestReportApi10ReportReportExternalIdDownloadGet = (
    { reportExternalId, ...query }: DownloadLatestReportApi10ReportReportExternalIdDownloadGetParams,
    params: RequestParams = {},
  ) =>
    this.request<any, ErrorResponse | HTTPValidationError>({
      path: `/api/1.0/report/${reportExternalId}/download`,
      method: "GET",
      query: query,
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags organization
   * @name UpdateUserApi10OrganizationUserExternalIdPut
   * @summary Update User
   * @request PUT:/api/1.0/organization/user/{external_id}
   * @secure
   */
  updateUserApi10OrganizationUserExternalIdPut = (
    { externalId, ...query }: UpdateUserApi10OrganizationUserExternalIdPutParams,
    data: UpdateUserBody,
    params: RequestParams = {},
  ) =>
    this.request<any, ErrorResponse | HTTPValidationError>({
      path: `/api/1.0/organization/user/${externalId}`,
      method: "PUT",
      query: query,
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags organization
   * @name DeleteUserApi10OrganizationUserExternalIdDelete
   * @summary Delete User
   * @request DELETE:/api/1.0/organization/user/{external_id}
   * @secure
   */
  deleteUserApi10OrganizationUserExternalIdDelete = (
    { externalId, ...query }: DeleteUserApi10OrganizationUserExternalIdDeleteParams,
    params: RequestParams = {},
  ) =>
    this.request<void, ErrorResponse | HTTPValidationError>({
      path: `/api/1.0/organization/user/${externalId}`,
      method: "DELETE",
      query: query,
      secure: true,
      ...params,
    });
  /**
   * No description
   *
   * @tags organization
   * @name UndeleteUserApi10OrganizationUserExternalIdUndeletePatch
   * @summary Undelete User
   * @request PATCH:/api/1.0/organization/user/{external_id}/undelete
   * @secure
   */
  undeleteUserApi10OrganizationUserExternalIdUndeletePatch = (
    { externalId, ...query }: UndeleteUserApi10OrganizationUserExternalIdUndeletePatchParams,
    params: RequestParams = {},
  ) =>
    this.request<any, ErrorResponse | HTTPValidationError>({
      path: `/api/1.0/organization/user/${externalId}/undelete`,
      method: "PATCH",
      query: query,
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * @description Get all user groups of organization.
   *
   * @tags organization
   * @name GetUserGroupsApi10OrganizationUserGroupGet
   * @summary Get User Groups
   * @request GET:/api/1.0/organization/user-group/
   * @secure
   */
  getUserGroupsApi10OrganizationUserGroupGet = (
    query: GetUserGroupsApi10OrganizationUserGroupGetParams,
    params: RequestParams = {},
  ) =>
    this.request<UserGroupDetailListResponse, ErrorResponse | HTTPValidationError>({
      path: `/api/1.0/organization/user-group/`,
      method: "GET",
      query: query,
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * @description Create user group. The starts_at and expires_at for the roles of the user group depend on the roles of the standard_user_group of the organization.
   *
   * @tags organization
   * @name CreateUserGroupApi10OrganizationUserGroupPost
   * @summary Create User Group
   * @request POST:/api/1.0/organization/user-group/
   * @secure
   */
  createUserGroupApi10OrganizationUserGroupPost = (
    query: CreateUserGroupApi10OrganizationUserGroupPostParams,
    data: UserGroupUpdate,
    params: RequestParams = {},
  ) =>
    this.request<UserGroupDetailResponse, ErrorResponse | HTTPValidationError>({
      path: `/api/1.0/organization/user-group/`,
      method: "POST",
      query: query,
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * @description Update user group. The starts_at and expires_at for the roles of the user group depend on the roles of the standard_user_group of the organization.
   *
   * @tags organization
   * @name UpdateUserGroupApi10OrganizationUserGroupExternalIdPut
   * @summary Update User Group
   * @request PUT:/api/1.0/organization/user-group/{external_id}
   * @secure
   */
  updateUserGroupApi10OrganizationUserGroupExternalIdPut = (
    { externalId, ...query }: UpdateUserGroupApi10OrganizationUserGroupExternalIdPutParams,
    data: UserGroupUpdate,
    params: RequestParams = {},
  ) =>
    this.request<UserGroupDetailResponse, ErrorResponse | HTTPValidationError>({
      path: `/api/1.0/organization/user-group/${externalId}`,
      method: "PUT",
      query: query,
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * @description Delete user group.
   *
   * @tags organization
   * @name DeleteUserGroupApi10OrganizationUserGroupExternalIdDelete
   * @summary Delete User Group
   * @request DELETE:/api/1.0/organization/user-group/{external_id}
   * @secure
   */
  deleteUserGroupApi10OrganizationUserGroupExternalIdDelete = (externalId: string, params: RequestParams = {}) =>
    this.request<void, ErrorResponse | HTTPValidationError>({
      path: `/api/1.0/organization/user-group/${externalId}`,
      method: "DELETE",
      secure: true,
      ...params,
    });
  /**
   * @description Add users to user group.
   *
   * @tags organization
   * @name AddUsersToUserGroupsApi10OrganizationUserGroupExternalIdUserPut
   * @summary Add Users To User Groups
   * @request PUT:/api/1.0/organization/user-group/{external_id}/user
   * @secure
   */
  addUsersToUserGroupsApi10OrganizationUserGroupExternalIdUserPut = (
    { externalId, ...query }: AddUsersToUserGroupsApi10OrganizationUserGroupExternalIdUserPutParams,
    data: UserGroupChangeUsers,
    params: RequestParams = {},
  ) =>
    this.request<UserGroupDetailResponse, ErrorResponse | HTTPValidationError>({
      path: `/api/1.0/organization/user-group/${externalId}/user`,
      method: "PUT",
      query: query,
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * @description Remove users in user group.
   *
   * @tags organization
   * @name RemoveUsersInUserGroupApi10OrganizationUserGroupExternalIdUserDelete
   * @summary Remove Users In User Group
   * @request DELETE:/api/1.0/organization/user-group/{external_id}/user
   * @secure
   */
  removeUsersInUserGroupApi10OrganizationUserGroupExternalIdUserDelete = (
    externalId: string,
    data: UserGroupChangeUsers,
    params: RequestParams = {},
  ) =>
    this.request<void, ErrorResponse | HTTPValidationError>({
      path: `/api/1.0/organization/user-group/${externalId}/user`,
      method: "DELETE",
      body: data,
      secure: true,
      type: ContentType.Json,
      ...params,
    });
  /**
   * @description Get user roles of the organization.
   *
   * @tags organization
   * @name GetUserRolesApi10OrganizationUserRolesGet
   * @summary Get User Roles
   * @request GET:/api/1.0/organization/user-roles/
   * @secure
   */
  getUserRolesApi10OrganizationUserRolesGet = (
    query: GetUserRolesApi10OrganizationUserRolesGetParams,
    params: RequestParams = {},
  ) =>
    this.request<RoleDetailListResponse, ErrorResponse | HTTPValidationError>({
      path: `/api/1.0/organization/user-roles/`,
      method: "GET",
      query: query,
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * @description Get client roles of the organization.
   *
   * @tags organization
   * @name GetClientRolesApi10OrganizationClientRolesGet
   * @summary Get Client Roles
   * @request GET:/api/1.0/organization/client-roles/
   * @secure
   */
  getClientRolesApi10OrganizationClientRolesGet = (
    query: GetClientRolesApi10OrganizationClientRolesGetParams,
    params: RequestParams = {},
  ) =>
    this.request<RoleDetailListResponse, ErrorResponse | HTTPValidationError>({
      path: `/api/1.0/organization/client-roles/`,
      method: "GET",
      query: query,
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * @description Get escalation contacts from organization. If organization_id is not provided, BU will be from the current user primary BU.
   *
   * @tags organization
   * @name GetEscalationContactsApi10OrganizationEscalationContactGet
   * @summary Get Escalation Contacts
   * @request GET:/api/1.0/organization/escalation-contact/
   * @secure
   */
  getEscalationContactsApi10OrganizationEscalationContactGet = (
    query: GetEscalationContactsApi10OrganizationEscalationContactGetParams,
    params: RequestParams = {},
  ) =>
    this.request<EscalationContactListResponse, ErrorResponse | HTTPValidationError>({
      path: `/api/1.0/organization/escalation-contact/`,
      method: "GET",
      query: query,
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * @description Add escalation contacts to organization. If organization_id is not provided, BU will be from the current user primary BU.
   *
   * @tags organization
   * @name UpdateEscalationContactsApi10OrganizationEscalationContactPost
   * @summary Update Escalation Contacts
   * @request POST:/api/1.0/organization/escalation-contact/
   * @secure
   */
  updateEscalationContactsApi10OrganizationEscalationContactPost = (
    query: UpdateEscalationContactsApi10OrganizationEscalationContactPostParams,
    data: EscalationContactUpdateBody[],
    params: RequestParams = {},
  ) =>
    this.request<EscalationContactListResponse, ErrorResponse | HTTPValidationError>({
      path: `/api/1.0/organization/escalation-contact/`,
      method: "POST",
      query: query,
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * @description Get approved ip ranges for a given organization. If organization_id is not provided, BU will be from the current user primary BU.
   *
   * @tags organization
   * @name GetIpRangesApi10OrganizationIpRangeGet
   * @summary Get Ip Ranges
   * @request GET:/api/1.0/organization/ip-range/
   * @secure
   */
  getIpRangesApi10OrganizationIpRangeGet = (
    query: GetIpRangesApi10OrganizationIpRangeGetParams,
    params: RequestParams = {},
  ) =>
    this.request<IPRangeListResponse, ErrorResponse | HTTPValidationError>({
      path: `/api/1.0/organization/ip-range/`,
      method: "GET",
      query: query,
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * @description Add ip-range to organization. If organization_id is not provided, BU will be from the current user primary BU.
   *
   * @tags organization
   * @name AddIpRangeApi10OrganizationIpRangePost
   * @summary Add Ip Range
   * @request POST:/api/1.0/organization/ip-range/
   * @secure
   */
  addIpRangeApi10OrganizationIpRangePost = (data: IPRangeCreateBody, params: RequestParams = {}) =>
    this.request<IPRangePreviewResponse, ErrorResponse | HTTPValidationError>({
      path: `/api/1.0/organization/ip-range/`,
      method: "POST",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * @description Updates ip-range identified by given external id. If organization_id is not provided, BU will be from the current user primary BU.
   *
   * @tags organization
   * @name UpdateIpRangeApi10OrganizationIpRangeExternalIdPut
   * @summary Update Ip Range
   * @request PUT:/api/1.0/organization/ip-range/{external_id}
   * @secure
   */
  updateIpRangeApi10OrganizationIpRangeExternalIdPut = (
    externalId: string,
    data: IPRangeUpdateBody,
    params: RequestParams = {},
  ) =>
    this.request<IPRangePreviewResponse, ErrorResponse | HTTPValidationError>({
      path: `/api/1.0/organization/ip-range/${externalId}`,
      method: "PUT",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * @description delete ip-range from organization. If organization_id is not provided, BU will be from the current user primary BU.
   *
   * @tags organization
   * @name DeleteRangeApi10OrganizationIpRangeExternalIdDelete
   * @summary Delete Range
   * @request DELETE:/api/1.0/organization/ip-range/{external_id}
   * @secure
   */
  deleteRangeApi10OrganizationIpRangeExternalIdDelete = (
    { externalId, ...query }: DeleteRangeApi10OrganizationIpRangeExternalIdDeleteParams,
    params: RequestParams = {},
  ) =>
    this.request<IPRangePreviewResponse, ErrorResponse | HTTPValidationError>({
      path: `/api/1.0/organization/ip-range/${externalId}`,
      method: "DELETE",
      query: query,
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags organization
   * @name GetClientsApi10OrganizationClientGet
   * @summary Get Clients
   * @request GET:/api/1.0/organization/client/
   * @secure
   */
  getClientsApi10OrganizationClientGet = (
    query: GetClientsApi10OrganizationClientGetParams,
    params: RequestParams = {},
  ) =>
    this.request<ClientListResponse, ErrorResponse | HTTPValidationError>({
      path: `/api/1.0/organization/client/`,
      method: "GET",
      query: query,
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags organization
   * @name CreateClientApi10OrganizationClientPost
   * @summary Create Client
   * @request POST:/api/1.0/organization/client/
   * @secure
   */
  createClientApi10OrganizationClientPost = (
    query: CreateClientApi10OrganizationClientPostParams,
    data: UpdateClientBody,
    params: RequestParams = {},
  ) =>
    this.request<any, ErrorResponse | HTTPValidationError>({
      path: `/api/1.0/organization/client/`,
      method: "POST",
      query: query,
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags organization
   * @name UpdateClientApi10OrganizationClientExternalIdPut
   * @summary Update Client
   * @request PUT:/api/1.0/organization/client/{external_id}
   * @secure
   */
  updateClientApi10OrganizationClientExternalIdPut = (
    { externalId, ...query }: UpdateClientApi10OrganizationClientExternalIdPutParams,
    data: UpdateClientBody,
    params: RequestParams = {},
  ) =>
    this.request<any, ErrorResponse | HTTPValidationError>({
      path: `/api/1.0/organization/client/${externalId}`,
      method: "PUT",
      query: query,
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags organization
   * @name DeleteClientApi10OrganizationClientExternalIdDelete
   * @summary Delete Client
   * @request DELETE:/api/1.0/organization/client/{external_id}
   * @secure
   */
  deleteClientApi10OrganizationClientExternalIdDelete = (
    { externalId, ...query }: DeleteClientApi10OrganizationClientExternalIdDeleteParams,
    params: RequestParams = {},
  ) =>
    this.request<void, ErrorResponse | HTTPValidationError>({
      path: `/api/1.0/organization/client/${externalId}`,
      method: "DELETE",
      query: query,
      secure: true,
      ...params,
    });
  /**
   * No description
   *
   * @tags organization
   * @name GetUsersApi10OrganizationUserGet
   * @summary Get Users
   * @request GET:/api/1.0/organization/user/
   * @secure
   */
  getUsersApi10OrganizationUserGet = (params: RequestParams = {}) =>
    this.request<UserListResponse, ErrorResponse | HTTPValidationError>({
      path: `/api/1.0/organization/user/`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags organization
   * @name CreateUserApi10OrganizationUserPost
   * @summary Create User
   * @request POST:/api/1.0/organization/user/
   * @secure
   */
  createUserApi10OrganizationUserPost = (data: UpdateUserBody, params: RequestParams = {}) =>
    this.request<any, ErrorResponse | HTTPValidationError>({
      path: `/api/1.0/organization/user/`,
      method: "POST",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * @description Endpoint for retrieving retainer information of user's business unit. If a retainer does not exist yet, return a mostly empty retainer preview just including the retainer type.
   *
   * @tags retainers
   * @name GetRetainerApi20RetainerGet
   * @summary Get Retainer
   * @request GET:/api/2.0/retainer/
   * @secure
   */
  getRetainerApi20RetainerGet = (params: RequestParams = {}) =>
    this.request<DetailResponseRetainerPreview, ErrorResponse | HTTPValidationError>({
      path: `/api/2.0/retainer/`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags retainers
   * @name CreateOrUpdateRetainerApi20RetainerPost
   * @summary Create Or Update Retainer
   * @request POST:/api/2.0/retainer/
   * @secure
   */
  createOrUpdateRetainerApi20RetainerPost = (
    data: PortalsFrontendApiRetainersModelsRetainerCreateOrUpdateBody,
    params: RequestParams = {},
  ) =>
    this.request<DetailResponseRetainerPreview, ErrorResponse | HTTPValidationError>({
      path: `/api/2.0/retainer/`,
      method: "POST",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags retainers
   * @name DownloadRetainerAttachmentApi20RetainerAttachmentExternalIdGet
   * @summary Download Retainer Attachment
   * @request GET:/api/2.0/retainer/attachment/{external_id}/
   * @secure
   */
  downloadRetainerAttachmentApi20RetainerAttachmentExternalIdGet = (externalId: string, params: RequestParams = {}) =>
    this.request<any, ErrorResponse | HTTPValidationError>({
      path: `/api/2.0/retainer/attachment/${externalId}/`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * @description Endpoint for retrieving retainer information of user's business unit. If a retainer does not exist yet, return a mostly empty retainer preview just including the retainer type.
   *
   * @tags retainers
   * @name GetRetainerApi10RetainerGet
   * @summary Get Retainer
   * @request GET:/api/1.0/retainer/
   * @deprecated
   * @secure
   */
  getRetainerApi10RetainerGet = (params: RequestParams = {}) =>
    this.request<RetainerPreviewDetailResponse, any>({
      path: `/api/1.0/retainer/`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags retainers
   * @name CreateOrUpdateRetainerForUserApi10RetainerPost
   * @summary Create Or Update Retainer For User
   * @request POST:/api/1.0/retainer/
   * @deprecated
   * @secure
   */
  createOrUpdateRetainerForUserApi10RetainerPost = (
    data: PortalsFrontendApiRetainersDeprecatedAppRetainerCreateOrUpdateBody,
    params: RequestParams = {},
  ) =>
    this.request<RetainerPreviewDetailResponse, HTTPValidationError>({
      path: `/api/1.0/retainer/`,
      method: "POST",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * @description Endpoint for uploading files to a user's retainer. If the retainer does not exist, it will be automatically created without contact information.
   *
   * @tags retainers
   * @name UploadRetainerAttachmentApi10RetainerAttachmentPost
   * @summary Upload Retainer Attachment
   * @request POST:/api/1.0/retainer/attachment/
   * @deprecated
   * @secure
   */
  uploadRetainerAttachmentApi10RetainerAttachmentPost = (
    data: BodyUploadRetainerAttachmentApi10RetainerAttachmentPost,
    params: RequestParams = {},
  ) =>
    this.request<RetainerAttachmentsPreviewDetailResponse, HTTPValidationError>({
      path: `/api/1.0/retainer/attachment/`,
      method: "POST",
      body: data,
      secure: true,
      type: ContentType.FormData,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags retainers
   * @name DownloadRetainerAttachmentApi10RetainerAttachmentExternalIdGet
   * @summary Download Retainer Attachment
   * @request GET:/api/1.0/retainer/attachment/{external_id}/
   * @deprecated
   * @secure
   */
  downloadRetainerAttachmentApi10RetainerAttachmentExternalIdGet = (externalId: string, params: RequestParams = {}) =>
    this.request<any, HTTPValidationError>({
      path: `/api/1.0/retainer/attachment/${externalId}/`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags retainers
   * @name DeleteRetainerAttachmentApi10RetainerAttachmentExternalIdDelete
   * @summary Delete Retainer Attachment
   * @request DELETE:/api/1.0/retainer/attachment/{external_id}/
   * @deprecated
   * @secure
   */
  deleteRetainerAttachmentApi10RetainerAttachmentExternalIdDelete = (externalId: string, params: RequestParams = {}) =>
    this.request<void, HTTPValidationError>({
      path: `/api/1.0/retainer/attachment/${externalId}/`,
      method: "DELETE",
      secure: true,
      ...params,
    });
  /**
   * @description Gets a page of auditlog events.
   *
   * @tags auditlog
   * @name GetEventsApi10AuditlogGet
   * @summary Get Events
   * @request GET:/api/1.0/auditlog/
   * @secure
   */
  getEventsApi10AuditlogGet = (query: GetEventsApi10AuditlogGetParams, params: RequestParams = {}) =>
    this.request<AuditlogEventPageDetailResponse, ErrorResponse | HTTPValidationError>({
      path: `/api/1.0/auditlog/`,
      method: "GET",
      query: query,
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * @description This endpoint also sets a session cookie containing the CSRF token.
   *
   * @name CsrfTokenRouteApi10CsrfTokenGet
   * @summary Csrf Token Route
   * @request GET:/api/1.0/csrf-token
   */
  csrfTokenRouteApi10CsrfTokenGet = (params: RequestParams = {}) =>
    this.request<DetailResponseCsrfToken, any>({
      path: `/api/1.0/csrf-token`,
      method: "GET",
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags reset
   * @name IndexApi10ResetGet
   * @summary Index
   * @request GET:/api/1.0/reset/
   */
  indexApi10ResetGet = (params: RequestParams = {}) =>
    this.request<BaseResponse, HTTPValidationError>({
      path: `/api/1.0/reset/`,
      method: "GET",
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags reset
   * @name CreatePasswordResetApi10ResetPasswordResetEmailPost
   * @summary Create Password Reset
   * @request POST:/api/1.0/reset/password-reset-email
   */
  createPasswordResetApi10ResetPasswordResetEmailPost = (data: EmailPasswordResetInput, params: RequestParams = {}) =>
    this.request<BaseResponse, HTTPValidationError>({
      path: `/api/1.0/reset/password-reset-email`,
      method: "POST",
      body: data,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags reset
   * @name SetPasswordApi10ResetPasswordPost
   * @summary Set Password
   * @request POST:/api/1.0/reset/password
   */
  setPasswordApi10ResetPasswordPost = (data: SetPasswordRequestBody, params: RequestParams = {}) =>
    this.request<BaseResponse, HTTPValidationError>({
      path: `/api/1.0/reset/password`,
      method: "POST",
      body: data,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * @description Retrieve details of an ER Case using a submission code. The ER Case is automatically submitted the first time a user requests its details.
   *
   * @tags emergency-response-cases
   * @name GetEmergencyResponseCaseInfoApi10EmergencyResponseCaseErCaseExternalIdGet
   * @summary Get Emergency Response Case Info
   * @request GET:/api/1.0/emergency-response-case/{er_case_external_id}
   */
  getEmergencyResponseCaseInfoApi10EmergencyResponseCaseErCaseExternalIdGet = (
    { erCaseExternalId, ...query }: GetEmergencyResponseCaseInfoApi10EmergencyResponseCaseErCaseExternalIdGetParams,
    params: RequestParams = {},
  ) =>
    this.request<ERCaseSubmissionPreviewDetailResponse, HTTPValidationError>({
      path: `/api/1.0/emergency-response-case/${erCaseExternalId}`,
      method: "GET",
      query: query,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags emergency-response-cases
   * @name RetrieveAllErCaseAttachmentsWithSubmissionCodeApi10EmergencyResponseCaseErCaseExternalIdAttachmentGet
   * @summary Retrieve All Er Case Attachments With Submission Code
   * @request GET:/api/1.0/emergency-response-case/{er_case_external_id}/attachment
   */
  retrieveAllErCaseAttachmentsWithSubmissionCodeApi10EmergencyResponseCaseErCaseExternalIdAttachmentGet = (
    {
      erCaseExternalId,
      ...query
    }: RetrieveAllErCaseAttachmentsWithSubmissionCodeApi10EmergencyResponseCaseErCaseExternalIdAttachmentGetParams,
    params: RequestParams = {},
  ) =>
    this.request<EmergencyResponseCaseAttachmentsPreviewDetailResponse, HTTPValidationError>({
      path: `/api/1.0/emergency-response-case/${erCaseExternalId}/attachment`,
      method: "GET",
      query: query,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags emergency-response-cases
   * @name UploadErCaseAttachmentWithSubmissionCodeApi10EmergencyResponseCaseErCaseExternalIdAttachmentPost
   * @summary Upload Er Case Attachment With Submission Code
   * @request POST:/api/1.0/emergency-response-case/{er_case_external_id}/attachment
   */
  uploadErCaseAttachmentWithSubmissionCodeApi10EmergencyResponseCaseErCaseExternalIdAttachmentPost = (
    {
      erCaseExternalId,
      ...query
    }: UploadErCaseAttachmentWithSubmissionCodeApi10EmergencyResponseCaseErCaseExternalIdAttachmentPostParams,
    data: BodyUploadErCaseAttachmentWithSubmissionCodeApi10EmergencyResponseCaseErCaseExternalIdAttachmentPost,
    params: RequestParams = {},
  ) =>
    this.request<EmergencyResponseCaseAttachmentsPreviewDetailResponse, HTTPValidationError>({
      path: `/api/1.0/emergency-response-case/${erCaseExternalId}/attachment`,
      method: "POST",
      query: query,
      body: data,
      type: ContentType.FormData,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags emergency-response-cases
   * @name DownloadErCaseAttachmentWithSubmissionCodeApi10EmergencyResponseCaseErCaseExternalIdAttachmentErCaseAttachmentExternalIdGet
   * @summary Download Er Case Attachment With Submission Code
   * @request GET:/api/1.0/emergency-response-case/{er_case_external_id}/attachment/{er_case_attachment_external_id}
   */
  downloadErCaseAttachmentWithSubmissionCodeApi10EmergencyResponseCaseErCaseExternalIdAttachmentErCaseAttachmentExternalIdGet =
    (
      {
        erCaseExternalId,
        erCaseAttachmentExternalId,
        ...query
      }: DownloadErCaseAttachmentWithSubmissionCodeApi10EmergencyResponseCaseErCaseExternalIdAttachmentErCaseAttachmentExternalIdGetParams,
      params: RequestParams = {},
    ) =>
      this.request<any, HTTPValidationError>({
        path: `/api/1.0/emergency-response-case/${erCaseExternalId}/attachment/${erCaseAttachmentExternalId}`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      });
  /**
   * No description
   *
   * @tags emergency-response-cases
   * @name DeleteErCaseAttachmentWithSubmissionCodeApi10EmergencyResponseCaseErCaseExternalIdAttachmentErCaseAttachmentExternalIdDelete
   * @summary Delete Er Case Attachment With Submission Code
   * @request DELETE:/api/1.0/emergency-response-case/{er_case_external_id}/attachment/{er_case_attachment_external_id}
   */
  deleteErCaseAttachmentWithSubmissionCodeApi10EmergencyResponseCaseErCaseExternalIdAttachmentErCaseAttachmentExternalIdDelete =
    (
      {
        erCaseExternalId,
        erCaseAttachmentExternalId,
        ...query
      }: DeleteErCaseAttachmentWithSubmissionCodeApi10EmergencyResponseCaseErCaseExternalIdAttachmentErCaseAttachmentExternalIdDeleteParams,
      params: RequestParams = {},
    ) =>
      this.request<void, HTTPValidationError>({
        path: `/api/1.0/emergency-response-case/${erCaseExternalId}/attachment/${erCaseAttachmentExternalId}`,
        method: "DELETE",
        query: query,
        ...params,
      });
  /**
   * No description
   *
   * @tags emergency-response-cases
   * @name SetErCaseQuestionnaireDataApi10EmergencyResponseCaseErCaseExternalIdQuestionnairePatch
   * @summary Set Er Case Questionnaire Data
   * @request PATCH:/api/1.0/emergency-response-case/{er_case_external_id}/questionnaire
   */
  setErCaseQuestionnaireDataApi10EmergencyResponseCaseErCaseExternalIdQuestionnairePatch = (
    {
      erCaseExternalId,
      ...query
    }: SetErCaseQuestionnaireDataApi10EmergencyResponseCaseErCaseExternalIdQuestionnairePatchParams,
    data: ERCaseQuestionnaireUpdateBody,
    params: RequestParams = {},
  ) =>
    this.request<ERCaseQuestionnairePreviewDetailResponse, HTTPValidationError>({
      path: `/api/1.0/emergency-response-case/${erCaseExternalId}/questionnaire`,
      method: "PATCH",
      query: query,
      body: data,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * @description Callback URL for twilio to acknowledge an ER Case.
   *
   * @tags emergency-response-cases
   * @name AcknowledgeEmergencyResponseCaseApi10EmergencyResponseCaseErCaseExternalIdCallbackTigerTeamPost
   * @summary Acknowledge Emergency Response Case
   * @request POST:/api/1.0/emergency-response-case/{er_case_external_id}/callback/tiger-team
   * @secure
   */
  acknowledgeEmergencyResponseCaseApi10EmergencyResponseCaseErCaseExternalIdCallbackTigerTeamPost = (
    erCaseExternalId: string,
    data: BodyAcknowledgeEmergencyResponseCaseApi10EmergencyResponseCaseErCaseExternalIdCallbackTigerTeamPost,
    params: RequestParams = {},
  ) =>
    this.request<any, HTTPValidationError>({
      path: `/api/1.0/emergency-response-case/${erCaseExternalId}/callback/tiger-team`,
      method: "POST",
      body: data,
      secure: true,
      type: ContentType.UrlEncoded,
      format: "json",
      ...params,
    });
  /**
   * @description Retrieve a list of business units accessible to the authenticated admin user. Sort business units by their name and filter by feature flags if needed.
   *
   * @tags admin-business-unit
   * @name GetBusinessUnitsApi10AdminBusinessUnitGet
   * @summary Get Business Units
   * @request GET:/api/1.0/admin/business-unit/
   * @secure
   */
  getBusinessUnitsApi10AdminBusinessUnitGet = (
    query: GetBusinessUnitsApi10AdminBusinessUnitGetParams,
    params: RequestParams = {},
  ) =>
    this.request<BusinessUnitPreviewPageDetailResponse, HTTPValidationError>({
      path: `/api/1.0/admin/business-unit/`,
      method: "GET",
      query: query,
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * @description Retrieve a list of business units accessible to the authenticated admin user, along with information about their associated retainers and retainer attachments. Sort business units by their name and filter by name if needed.
   *
   * @tags admin-retainers
   * @name GetBusinessUnitsWithRetainersByAdminApi10AdminRetainerGet
   * @summary Get Business Units With Retainers By Admin
   * @request GET:/api/1.0/admin/retainer/
   * @secure
   */
  getBusinessUnitsWithRetainersByAdminApi10AdminRetainerGet = (
    query: GetBusinessUnitsWithRetainersByAdminApi10AdminRetainerGetParams,
    params: RequestParams = {},
  ) =>
    this.request<BusinessUnitWithRetainerPreviewPageDetailResponse, HTTPValidationError>({
      path: `/api/1.0/admin/retainer/`,
      method: "GET",
      query: query,
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags admin-retainers
   * @name CreateRetainerByAdminApi10AdminRetainerPost
   * @summary Create Retainer By Admin
   * @request POST:/api/1.0/admin/retainer/
   * @secure
   */
  createRetainerByAdminApi10AdminRetainerPost = (data: RetainerCreateBody, params: RequestParams = {}) =>
    this.request<RetainerPreviewDetailResponse, HTTPValidationError>({
      path: `/api/1.0/admin/retainer/`,
      method: "POST",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags admin-retainers
   * @name GetRetainerTypesApi10AdminRetainerTypeGet
   * @summary Get Retainer Types
   * @request GET:/api/1.0/admin/retainer/type
   * @secure
   */
  getRetainerTypesApi10AdminRetainerTypeGet = (params: RequestParams = {}) =>
    this.request<RetainerTypePreviewListResponse, any>({
      path: `/api/1.0/admin/retainer/type`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * @description Get an ER Retainer its external id if it is accessible to the user, along with information about its business unit and attachments.
   *
   * @tags admin-retainers
   * @name GetRetainerByExternalIdByAdminApi10AdminRetainerRetainerExternalIdGet
   * @summary Get Retainer By External Id By Admin
   * @request GET:/api/1.0/admin/retainer/{retainer_external_id}
   * @secure
   */
  getRetainerByExternalIdByAdminApi10AdminRetainerRetainerExternalIdGet = (
    retainerExternalId: string,
    params: RequestParams = {},
  ) =>
    this.request<RetainerWithBusinessUnitPreviewDetailResponse, HTTPValidationError>({
      path: `/api/1.0/admin/retainer/${retainerExternalId}`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags admin-retainers
   * @name UpdateRetainerByAdminApi10AdminRetainerRetainerExternalIdPut
   * @summary Update Retainer By Admin
   * @request PUT:/api/1.0/admin/retainer/{retainer_external_id}
   * @secure
   */
  updateRetainerByAdminApi10AdminRetainerRetainerExternalIdPut = (
    retainerExternalId: string,
    data: PortalsFrontendApiRetainersDeprecatedAppRetainerCreateOrUpdateBody,
    params: RequestParams = {},
  ) =>
    this.request<RetainerPreviewDetailResponse, HTTPValidationError>({
      path: `/api/1.0/admin/retainer/${retainerExternalId}`,
      method: "PUT",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags admin-retainers
   * @name DownloadRetainerAttachmentByAdminApi10AdminRetainerRetainerExternalIdAttachmentAttachmentExternalIdGet
   * @summary Download Retainer Attachment By Admin
   * @request GET:/api/1.0/admin/retainer/{retainer_external_id}/attachment/{attachment_external_id}
   * @secure
   */
  downloadRetainerAttachmentByAdminApi10AdminRetainerRetainerExternalIdAttachmentAttachmentExternalIdGet = (
    retainerExternalId: string,
    attachmentExternalId: string,
    params: RequestParams = {},
  ) =>
    this.request<any, HTTPValidationError>({
      path: `/api/1.0/admin/retainer/${retainerExternalId}/attachment/${attachmentExternalId}`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags admin-retainers
   * @name DeleteRetainerAttachmentByAdminApi10AdminRetainerRetainerExternalIdAttachmentAttachmentExternalIdDelete
   * @summary Delete Retainer Attachment By Admin
   * @request DELETE:/api/1.0/admin/retainer/{retainer_external_id}/attachment/{attachment_external_id}
   * @secure
   */
  deleteRetainerAttachmentByAdminApi10AdminRetainerRetainerExternalIdAttachmentAttachmentExternalIdDelete = (
    retainerExternalId: string,
    attachmentExternalId: string,
    params: RequestParams = {},
  ) =>
    this.request<void, HTTPValidationError>({
      path: `/api/1.0/admin/retainer/${retainerExternalId}/attachment/${attachmentExternalId}`,
      method: "DELETE",
      secure: true,
      ...params,
    });
  /**
   * No description
   *
   * @tags admin-retainers
   * @name UploadRetainerAttachmentByAdminApi10AdminRetainerRetainerExternalIdAttachmentPost
   * @summary Upload Retainer Attachment By Admin
   * @request POST:/api/1.0/admin/retainer/{retainer_external_id}/attachment/
   * @secure
   */
  uploadRetainerAttachmentByAdminApi10AdminRetainerRetainerExternalIdAttachmentPost = (
    retainerExternalId: string,
    data: BodyUploadRetainerAttachmentByAdminApi10AdminRetainerRetainerExternalIdAttachmentPost,
    params: RequestParams = {},
  ) =>
    this.request<RetainerAttachmentsPreviewDetailResponse, HTTPValidationError>({
      path: `/api/1.0/admin/retainer/${retainerExternalId}/attachment/`,
      method: "POST",
      body: data,
      secure: true,
      type: ContentType.FormData,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags admin-emergency-response-cases
   * @name GetEmergencyResponseCasesApi10AdminEmergencyResponseCaseGet
   * @summary Get Emergency Response Cases
   * @request GET:/api/1.0/admin/emergency-response-case/
   * @secure
   */
  getEmergencyResponseCasesApi10AdminEmergencyResponseCaseGet = (
    query: GetEmergencyResponseCasesApi10AdminEmergencyResponseCaseGetParams,
    params: RequestParams = {},
  ) =>
    this.request<EmergencyResponseCasePreviewPageDetailResponse, HTTPValidationError>({
      path: `/api/1.0/admin/emergency-response-case/`,
      method: "GET",
      query: query,
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags admin-emergency-response-cases
   * @name CreateNewEmergencyResponseCaseApi10AdminEmergencyResponseCasePost
   * @summary Create New Emergency Response Case
   * @request POST:/api/1.0/admin/emergency-response-case/
   * @secure
   */
  createNewEmergencyResponseCaseApi10AdminEmergencyResponseCasePost = (
    data: EmergencyResponseCaseCreateBody,
    params: RequestParams = {},
  ) =>
    this.request<EmergencyResponseCasePreviewDetailResponse, HTTPValidationError>({
      path: `/api/1.0/admin/emergency-response-case/`,
      method: "POST",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags admin-emergency-response-cases
   * @name GetEmergencyResponseCasesByExternalIdApi10AdminEmergencyResponseCaseErCaseExternalIdGet
   * @summary Get Emergency Response Cases By External Id
   * @request GET:/api/1.0/admin/emergency-response-case/{er_case_external_id}
   * @secure
   */
  getEmergencyResponseCasesByExternalIdApi10AdminEmergencyResponseCaseErCaseExternalIdGet = (
    erCaseExternalId: string,
    params: RequestParams = {},
  ) =>
    this.request<EmergencyResponseCasePreviewDetailResponse, HTTPValidationError>({
      path: `/api/1.0/admin/emergency-response-case/${erCaseExternalId}`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags admin-emergency-response-cases
   * @name UpdateExistingEmergencyResponseCaseApi10AdminEmergencyResponseCaseErCaseExternalIdPatch
   * @summary Update Existing Emergency Response Case
   * @request PATCH:/api/1.0/admin/emergency-response-case/{er_case_external_id}
   * @secure
   */
  updateExistingEmergencyResponseCaseApi10AdminEmergencyResponseCaseErCaseExternalIdPatch = (
    erCaseExternalId: string,
    data: EmergencyResponseCaseUpdateBody,
    params: RequestParams = {},
  ) =>
    this.request<EmergencyResponseCasePreviewDetailResponse, HTTPValidationError>({
      path: `/api/1.0/admin/emergency-response-case/${erCaseExternalId}`,
      method: "PATCH",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags admin-emergency-response-cases
   * @name ResendNewCaseMessageApi10AdminEmergencyResponseCaseErCaseExternalIdMessageMessageTypePost
   * @summary Resend New Case Message
   * @request POST:/api/1.0/admin/emergency-response-case/{er_case_external_id}/message/{message_type}
   * @secure
   */
  resendNewCaseMessageApi10AdminEmergencyResponseCaseErCaseExternalIdMessageMessageTypePost = (
    erCaseExternalId: string,
    messageType: ResendMessageType,
    params: RequestParams = {},
  ) =>
    this.request<any, HTTPValidationError>({
      path: `/api/1.0/admin/emergency-response-case/${erCaseExternalId}/message/${messageType}`,
      method: "POST",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags admin-emergency-response-cases
   * @name UploadErCaseAttachmentApi10AdminEmergencyResponseCaseErCaseExternalIdAttachmentPost
   * @summary Upload Er Case Attachment
   * @request POST:/api/1.0/admin/emergency-response-case/{er_case_external_id}/attachment
   * @secure
   */
  uploadErCaseAttachmentApi10AdminEmergencyResponseCaseErCaseExternalIdAttachmentPost = (
    erCaseExternalId: string,
    data: BodyUploadErCaseAttachmentApi10AdminEmergencyResponseCaseErCaseExternalIdAttachmentPost,
    params: RequestParams = {},
  ) =>
    this.request<EmergencyResponseCaseAttachmentsPreviewDetailResponse, HTTPValidationError>({
      path: `/api/1.0/admin/emergency-response-case/${erCaseExternalId}/attachment`,
      method: "POST",
      body: data,
      secure: true,
      type: ContentType.FormData,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags admin-emergency-response-cases
   * @name DownloadErCaseAttachmentApi10AdminEmergencyResponseCaseErCaseExternalIdAttachmentAttachmentExternalIdGet
   * @summary Download Er Case Attachment
   * @request GET:/api/1.0/admin/emergency-response-case/{er_case_external_id}/attachment/{attachment_external_id}
   * @secure
   */
  downloadErCaseAttachmentApi10AdminEmergencyResponseCaseErCaseExternalIdAttachmentAttachmentExternalIdGet = (
    erCaseExternalId: string,
    attachmentExternalId: string,
    params: RequestParams = {},
  ) =>
    this.request<any, HTTPValidationError>({
      path: `/api/1.0/admin/emergency-response-case/${erCaseExternalId}/attachment/${attachmentExternalId}`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags admin-emergency-response-cases
   * @name DeleteErCaseAttachmentApi10AdminEmergencyResponseCaseErCaseExternalIdAttachmentAttachmentExternalIdDelete
   * @summary Delete Er Case Attachment
   * @request DELETE:/api/1.0/admin/emergency-response-case/{er_case_external_id}/attachment/{attachment_external_id}
   * @secure
   */
  deleteErCaseAttachmentApi10AdminEmergencyResponseCaseErCaseExternalIdAttachmentAttachmentExternalIdDelete = (
    erCaseExternalId: string,
    attachmentExternalId: string,
    params: RequestParams = {},
  ) =>
    this.request<void, HTTPValidationError>({
      path: `/api/1.0/admin/emergency-response-case/${erCaseExternalId}/attachment/${attachmentExternalId}`,
      method: "DELETE",
      secure: true,
      ...params,
    });
  /**
   * @description Retrieve a list of active admin users accessible to the authenticated admin user.
   *
   * @tags admin-user
   * @name GetAdminUsersApi10AdminUserAdminGet
   * @summary Get Admin Users
   * @request GET:/api/1.0/admin/user/admin
   * @secure
   */
  getAdminUsersApi10AdminUserAdminGet = (params: RequestParams = {}) =>
    this.request<AdminUserPreviewListResponse, any>({
      path: `/api/1.0/admin/user/admin`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
}
