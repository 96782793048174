import { searchKeys } from "@csis.com/tip/src/models/pageSearch/constants";
import { predefinedResultsPerPage } from "@csis.com/tip/src/models/pagination/constants";

export const victimsInfoKeys = {
  ID: "id",
  DISPLAY_ID: "display_id",
  URL: "url",
  RELEASED: "released",
  SUBMITTED: "submitted",
  RESOLVED: "resolved",
  STAMP: "stamp",
  STATUS: "status",
  SOURCE: "source",
  VARIANT: "variant",
  MATCHER: "identifier",
  TICKET: "ticket",
  USERNAME: "username",
  EMAIL: "email",
  RELEASED_BEFORE: "released_before",
  RELEASED_AFTER: "released_after",
  SUBMITTED_BEFORE: "submitted_before",
  SUBMITTED_AFTER: "submitted_after",
  // keys used in all search pages:
  ...searchKeys,
} as const;

export const filterKeys = [
  victimsInfoKeys.STATUS,
  victimsInfoKeys.SOURCE,
  victimsInfoKeys.VARIANT,
] as const;

export const DEFAULT_RESULTS_PER_PAGE = predefinedResultsPerPage.TEN;
