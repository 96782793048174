import { axiosCsisApi } from "@csis.com/tip/src/App";
import { STRINGIFY_QUERY_PARAMS } from "@csis.com/tip/src/api/utils";
import { Status } from "../../models/Status";
import { QueryParams } from "../types";
import { mapCcStartAndEndDateToCorrectType } from "./utils";

export async function fetchCreditCardsApi(
  queryParams: Partial<QueryParams>,
  organizationId: string
) {
  const params = mapCcStartAndEndDateToCorrectType(queryParams);

  return axiosCsisApi.getCreditCardReportsApi10CompromisedDataCreditCardReportGet(
    {
      ...params,
      organization_id: organizationId,
    },
    STRINGIFY_QUERY_PARAMS
  );
}

export async function exportCCInfoCsvApi(
  queryParams: Partial<QueryParams>,
  organizationId: string
) {
  const params = mapCcStartAndEndDateToCorrectType(queryParams);

  return axiosCsisApi.getCreditCardExportCsvApi10CompromisedDataCreditCardReportExportCsvGet(
    {
      ...params,
      organization_id: organizationId,
    },
    {
      format: "blob",
      ...STRINGIFY_QUERY_PARAMS,
    }
  );
}

export async function patchBulkStatusApi({
  ids,
  status,
}: {
  ids: string[];
  status: Status;
}) {
  return axiosCsisApi.bulkEditCreditCardReportsApi10CompromisedDataCreditCardReportBulkPatch(
    { external_ids: ids, status: status }
  );
}
