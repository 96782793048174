import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { AxiosResponse } from "axios";
import { all, call, put, select, takeLatest } from "redux-saga/effects";
import { axiosCsisApi } from "@csis.com/tip/src/App";
import { DetailResponsePortalnewsPreviewPage } from "@csis.com/tip/src/api/openapi/data-contracts";
import {
  STRINGIFY_QUERY_PARAMS,
  handleRequestError,
} from "@csis.com/tip/src/api/utils";
import { getNews } from "./selector";
import { NewsItem, QueryParams } from "./types";

interface StateSlice {
  news: NewsItem[] | null;
  isPending: boolean;
  fetchError: string | null;
  updateError: string | null;
  // this is to not reopen the portal news dialog on navigation if there are still unread news
  hasNewsBeenDisplayed: boolean;
}

const initialState: StateSlice = {
  news: null,
  isPending: false,
  fetchError: null,
  updateError: null,
  hasNewsBeenDisplayed: false,
};

const newsSlice = createSlice({
  name: "news",
  initialState,
  reducers: {
    fetchNews(_state, _action: PayloadAction<Partial<QueryParams>>) {
      //empty handled by saga
    },
    setPending(state) {
      state.isPending = true;
      state.fetchError = null;
    },
    setFetchError(state, action: PayloadAction<string>) {
      state.isPending = false;
      state.fetchError = action.payload;
    },
    setUpdateError(state, action: PayloadAction<string>) {
      state.isPending = false;
      state.updateError = action.payload;
    },
    resetUpdateError(state) {
      state.updateError = null;
    },
    resetState(state) {
      state.news = null;
      state.isPending = false;
      state.fetchError = null;
      state.updateError = null;
    },
    fetchSuccess(state, action: PayloadAction<NewsItem[]>) {
      state.news = action.payload;
      state.isPending = false;
      state.fetchError = null;
      state.updateError = null;
    },
    setNews(state, action: PayloadAction<NewsItem[]>) {
      state.news = action.payload;
    },
    updateNewsReadStatusAll(_state) {
      //empty handled by saga
    },
    setHasNewsBeenDisplayed(state, action: PayloadAction<boolean>) {
      state.hasNewsBeenDisplayed = action.payload;
    },
  },
});

export default newsSlice.reducer;

export const {
  fetchNews,
  setPending,
  setFetchError,
  setUpdateError,
  resetUpdateError,
  resetState,
  fetchSuccess,
  updateNewsReadStatusAll,
  setNews,
  setHasNewsBeenDisplayed,
} = newsSlice.actions;

// Async stuff - sagas

function* fetchNewsSaga(action: PayloadAction<Partial<QueryParams>>) {
  yield put(resetState());
  yield put(setPending());
  try {
    const response: AxiosResponse<DetailResponsePortalnewsPreviewPage> =
      yield call(
        axiosCsisApi.getPortalNewsApi10PortalNewsGet,
        action.payload,
        STRINGIFY_QUERY_PARAMS
      );
    yield put(fetchSuccess(response.data.payload.page as NewsItem[]));
  } catch (e) {
    const errorMessage = handleRequestError(e);
    yield put(setFetchError(errorMessage));
  }
}

function* updateNewsReadStatusAllSaga() {
  const newsItems: NewsItem[] | null = yield select(getNews);

  yield put(resetUpdateError());

  if (newsItems) {
    const newsItemsUpdatedReadStatus = newsItems.map((item) => {
      return {
        ...item,
        unread: false,
      };
    });

    yield put(setNews(newsItemsUpdatedReadStatus));

    try {
      yield call(axiosCsisApi.setLastReadApi10PortalNewsLastReadPost);
      yield put(fetchSuccess(newsItemsUpdatedReadStatus));
    } catch (e) {
      const errorMessage = handleRequestError(e);
      yield put(setNews(newsItems));
      yield put(setUpdateError(errorMessage));
    }
  }
}

function* actionWatcher() {
  yield takeLatest(fetchNews.toString(), fetchNewsSaga);
  yield takeLatest(
    updateNewsReadStatusAll.toString(),
    updateNewsReadStatusAllSaga
  );
}

export function* newsSagas() {
  yield all([actionWatcher()]);
}
