import classNames from "classnames";
import React from "react";
import { SIZE } from "../../sizes";

export interface GridInterface {
  children: React.ReactNode;
  justify?: "center" | "space-between";
  gutterSize?: SIZE;
}

const Grid: React.FC<GridInterface> = ({
  children,
  justify,
  gutterSize = "normal",
}) => {
  const classes = classNames(
    "grid",
    `grid--size-${gutterSize}`,
    justify ? `grid--justify-${justify}` : "",
    `grid--gutter-${gutterSize}`
  );

  return <div className={classes}>{children}</div>;
};

export default Grid;
