import { axiosCsisApi } from "@csis.com/tip/src/App";
import { mapUrlsStartAndEndDateToCorrectType } from "@csis.com/tip/src/pages/Products/Phishing/UrlsSearch/api/utils";
import { QueryParams } from "../../types";

export async function fetchLecacyStatsApi(
  queryParams: Partial<QueryParams>,
  organizationId: string
) {
  const params = mapUrlsStartAndEndDateToCorrectType(queryParams);

  return axiosCsisApi.getUrlStatsLegacyApi10PhishingUrlStatisticsLegacyGet({
    ...params,
    organization_id: organizationId,
  });
}
