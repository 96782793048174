import { PayloadAction } from "@reduxjs/toolkit";
import { AxiosResponse } from "axios";
import { all, call, put, select, takeLatest } from "redux-saga/effects";
import {
  StatisticsCountListResponse,
  StatisticsHistogramCountListResponse,
} from "@csis.com/tip/src/api/openapi/data-contracts";
import { createAsyncArrayFetchSlice } from "@csis.com/tip/src/sliceHelpers/createAsyncArrayFetchSlice";
import { getSelectedOrgId } from "../../../Profile/Security/selectors";
import {
  QueryParams,
  SeveritiesHistogramEntry,
  SeveritiesTotalEntry,
} from "../types";
import {
  fetchSeveritiesStatisticsApi,
  fetchSeveritiesStatisticsHistogramApi,
} from "./api/api";

const alertsStatisticsSeveritiesSlice = createAsyncArrayFetchSlice<
  SeveritiesTotalEntry,
  QueryParams
>("alertsStatisticsSeverity");

export const alertsStatisticsSeverityReducer =
  alertsStatisticsSeveritiesSlice.reducer;

export const {
  fetchData: fetchSeveritiesDistribution,
  setIsPending: setIsSeveritiesDistributionPending,
  setFetchError: setSeveritiesDistributionFetchError,
  setFetchSuccess: setFetchSeveritiesDistributionSuccess,
} = alertsStatisticsSeveritiesSlice.actions;

const alertsStatisticsOverTimeSlice = createAsyncArrayFetchSlice<
  SeveritiesHistogramEntry,
  QueryParams
>("alertsStatisticsSeverityOverTime");

export const alertsStatisticsSeverityOverTimeReducer =
  alertsStatisticsOverTimeSlice.reducer;

export const {
  fetchData: fetchSeveritiesDistributionOverTime,
  setIsPending: setIsSeveritiesDistributionOverTimePending,
  setFetchError: setSeveritiesDistributionOverTimeFetchError,
  setFetchSuccess: setFetchSeveritiesDistributionOverTimeSuccess,
} = alertsStatisticsOverTimeSlice.actions;

// Async stuff - sagas

function* fetchSeveritiesDistributionSaga(
  action: PayloadAction<Partial<QueryParams>>
) {
  yield put(setIsSeveritiesDistributionPending());

  try {
    const selectedOrgId: string = yield select(getSelectedOrgId);

    const response: AxiosResponse<StatisticsCountListResponse> = yield call(
      fetchSeveritiesStatisticsApi,
      action.payload,
      selectedOrgId
    );

    yield put(setFetchSeveritiesDistributionSuccess(response.data.payload));
  } catch (e) {
    yield put(setSeveritiesDistributionFetchError());
  }
}

function* fetchSeveritiesDistributionOverTimeSaga(
  action: PayloadAction<Partial<QueryParams>>
) {
  yield put(setIsSeveritiesDistributionOverTimePending());

  try {
    const selectedOrgId: string = yield select(getSelectedOrgId);

    const response: AxiosResponse<StatisticsHistogramCountListResponse> =
      yield call(
        fetchSeveritiesStatisticsHistogramApi,
        action.payload,
        selectedOrgId
      );

    yield put(
      setFetchSeveritiesDistributionOverTimeSuccess(response.data.payload)
    );
  } catch (e) {
    yield put(setSeveritiesDistributionOverTimeFetchError());
  }
}

function* actionWatcher() {
  yield takeLatest(
    fetchSeveritiesDistribution.toString(),
    fetchSeveritiesDistributionSaga
  );
  yield takeLatest(
    fetchSeveritiesDistributionOverTime.toString(),
    fetchSeveritiesDistributionOverTimeSaga
  );
}

export function* alertsStatisticsSeveritySagas() {
  yield all([actionWatcher()]);
}
