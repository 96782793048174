import { useCallback, useEffect, useRef, useState } from "react";
import ReactDOM from "react-dom";
import {
  Button,
  Dialog,
  Grid,
  GridItem,
  Icon,
  Stack,
} from "@csis.com/components";

const DialogWrapper = ({
  title,
  details,
  isOpen,
  onConfirm,
  onCancel,
  mode,
}: {
  title: string;
  details: string;
  isOpen: boolean;
  onConfirm: () => void;
  onCancel: () => void;
  mode: DialogMode;
}) => {
  if (!isOpen) {
    return null;
  }

  return (
    <Dialog
      color={mode === "delete" ? "red" : undefined}
      size="small"
      name="confirm-dialog"
      onClose={onCancel}
      isModal
      dataTestId="confirm-dialog"
    >
      <Stack gutterSize="large" isVertical align="stretch">
        <Stack align="center">
          <Icon
            kind={mode === "delete" ? "warning" : "check"}
            color={mode === "delete" ? "red" : "csis"}
            size="large"
          />
          <div className={"f_semibold f_big"}>{title}</div>
        </Stack>
        <div>{details}</div>
        <Grid gutterSize="big">
          <GridItem span={6}>
            <Button
              text={"Cancel"}
              name="confirm-dlg-cancel-btn"
              color="grey"
              size="small"
              onButtonClick={onCancel}
              isFullWidth
            />
          </GridItem>
          <GridItem span={6}>
            <Button
              text={"Confirm"}
              onButtonClick={onConfirm}
              isFullWidth
              size="small"
              color={mode === "delete" ? "red" : undefined}
              name="confirm-dlg-ok-btn"
              dataTestId="confirm-dlg-ok-btn"
            />
          </GridItem>
        </Grid>
      </Stack>
    </Dialog>
  );
};

type DialogMode = "delete" | "neutral";

export function useConfirm() {
  const [open, setOpen] = useState(false);

  const [mode, setMode] = useState<DialogMode>("neutral");
  const [title, setTitle] = useState<string>("");
  const [details, setDetails] = useState<string>("");

  const onConfirmRef = useRef<null | (() => void)>(null);

  const confirm = (
    {
      title,
      details,
      mode,
    }: {
      title: string;
      details?: string;
      mode: DialogMode;
    },
    onConfirmAction: () => void
  ) => {
    setOpen(true);
    setTitle(title);
    setDetails(details || "");
    setMode(mode);
    onConfirmRef.current = onConfirmAction;
  };

  const handleConfirm = useCallback(() => {
    setOpen(false);
    onConfirmRef.current && onConfirmRef.current();
  }, []);

  const handleCancel = useCallback(() => {
    setOpen(false);
  }, []);

  useEffect(() => {
    const dialog = document.createElement("div");
    document.body.appendChild(dialog);

    ReactDOM.render(
      <DialogWrapper
        title={title}
        details={details}
        mode={mode}
        isOpen={open}
        onConfirm={handleConfirm}
        onCancel={handleCancel}
      />,
      dialog
    );

    return () => {
      ReactDOM.unmountComponentAtNode(dialog);
      document.body.removeChild(dialog);
    };
  }, [open, handleConfirm, handleCancel, title, details, mode]);

  return confirm;
}
