import { PayloadAction } from "@reduxjs/toolkit";
import { AxiosResponse } from "axios";
import { all, call, put, select, takeLatest } from "redux-saga/effects";
import {
  UpdateUserBody,
  UserDetail,
  UserListResponse,
} from "@csis.com/tip/src/api/openapi/data-contracts";
import { handleRequestError } from "@csis.com/tip/src/api/utils";
import { createAsyncArrayFetchSlice } from "@csis.com/tip/src/sliceHelpers/createAsyncArrayFetchSlice";
import { createAsyncDataUpdateSlice } from "@csis.com/tip/src/sliceHelpers/createAsyncDataUpdateSlice";
import { getSelectedOrgId } from "../../../Security/selectors";
import {
  deleteUserApi,
  fetchUsersApi,
  postUserApi,
  unDeleteUserApi,
  updateUserApi,
} from "./api/api";

const usersSlice = createAsyncArrayFetchSlice<UserDetail, undefined>("users");

export const usersReducer = usersSlice.reducer;

export const {
  fetchData: fetchUsers,
  setIsPending: setIsUsersFetchPending,
  setFetchError: setUsersFetchError,
  setFetchSuccess: setFetchUsersSuccess,
} = usersSlice.actions;

export type UserCreatePayload = {
  data: UpdateUserBody;
  action: "create";
};
export type UserUpdatePayload = {
  id: string;
  data: UpdateUserBody;
  action: "update";
};
export type UserDeletePayload = { id: string; action: "delete" };
export type UserUndeletePayload = { id: string; action: "undelete" };

export type UserModifyPayload =
  | UserCreatePayload
  | UserUpdatePayload
  | UserDeletePayload
  | UserUndeletePayload;

const usersUpdateSlice =
  createAsyncDataUpdateSlice<UserModifyPayload>("usersUpdate");

export const usersUpdateReducer = usersUpdateSlice.reducer;

export const {
  updateData: modifyUser,
  setIsUpdatePending,
  setIsUpdateError,
  setIsUpdateSuccess,
  resetState,
} = usersUpdateSlice.actions;

// Async stuff - sagas

function* fetchUsersSaga() {
  yield put(setIsUsersFetchPending());

  try {
    const selectedOrgId: string | undefined = yield select(getSelectedOrgId);
    if (!selectedOrgId) {
      yield put(setUsersFetchError());
      return;
    }

    const response: AxiosResponse<UserListResponse> = yield call(fetchUsersApi);
    yield put(setFetchUsersSuccess(response.data.payload));
  } catch (e) {
    yield put(setUsersFetchError());
  }
}

function* modifyUserSaga(action: PayloadAction<UserModifyPayload>) {
  yield put(setIsUpdatePending());

  const selectedOrgId: string | undefined = yield select(getSelectedOrgId);
  if (!selectedOrgId) {
    yield put(setIsUpdateError());
    return;
  }

  try {
    if (action.payload.action === "create") {
      yield call(postUserApi, action.payload.data);
    } else if (action.payload.action === "delete") {
      yield call(deleteUserApi, action.payload.id, selectedOrgId);
    } else if (action.payload.action === "undelete") {
      yield call(unDeleteUserApi, action.payload.id, selectedOrgId);
    } else if (action.payload.action === "update") {
      yield call(
        updateUserApi,
        action.payload.id,
        action.payload.data,
        selectedOrgId
      );
    } else {
      throw new Error("Invalid action");
    }
    yield put(setIsUpdateSuccess());
  } catch (e) {
    const errorMessage = handleRequestError(e);
    yield put(setIsUpdateError(errorMessage));
  }
}

function* actionWatcher() {
  yield takeLatest(fetchUsers.toString(), fetchUsersSaga);
  yield takeLatest(modifyUser.toString(), modifyUserSaga);
}

export function* usersSagas() {
  yield all([actionWatcher()]);
}
