import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { AxiosResponse } from "axios";
import { all, call, put, select, takeLatest } from "redux-saga/effects";
import { PortalsFrontendApiArticlesDeprecatedAppArticleCategoryDetailResponse as ArticleCategoryDetailResponse } from "@csis.com/tip/src/api/openapi/data-contracts";
import { getSelectedOrgId } from "../../../Profile/Security/selectors";
import { CategoryEntry } from "./Category";
import { fetchArticleCategoriesApi } from "./api/api";

interface StateSlice {
  categories: CategoryEntry[];
}
const initialState: StateSlice = {
  categories: [],
};

const categoriesSlice = createSlice({
  name: "categories",
  initialState: initialState,
  reducers: {
    fetchCategories() {
      //empty handled by saga
    },
    fetchSuccess(state, action: PayloadAction<CategoryEntry[]>) {
      state.categories = action.payload;
    },
  },
});

export default categoriesSlice.reducer;

export const { fetchCategories, fetchSuccess } = categoriesSlice.actions;

// Async stuff - sagas

function* fetchCategoriesSaga() {
  try {
    const selectedOrgId: string = yield select(getSelectedOrgId);
    const response: AxiosResponse<ArticleCategoryDetailResponse> = yield call(
      fetchArticleCategoriesApi,
      selectedOrgId
    );

    yield put(fetchSuccess(response.data.payload));
  } catch (e) {}
}

function* actionWatcher() {
  yield takeLatest(fetchCategories.toString(), fetchCategoriesSaga);
}

export function* insightsCategoriesSagas() {
  yield all([actionWatcher()]);
}
