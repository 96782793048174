import axios from "axios";
import { axiosCsisApi } from "@csis.com/tip/src/App";
import { TicketCreateBody } from "../../../../api/openapi/data-contracts";

export async function postTicketApi(newTicket: TicketCreateBody) {
  return axiosCsisApi.createTicketApi10TicketPost(newTicket);
}

export async function postTicketAttachmentApi(formData: FormData) {
  //
  return await axios({
    method: "post",
    url: "/1.0/ticket/attachment",
    data: formData,
    headers: { "Content-Type": "multipart/form-data" },
  });
}
