import { PayloadAction, createSlice, current } from "@reduxjs/toolkit";
import { AxiosResponse } from "axios";
import {
  all,
  call,
  put,
  select,
  takeEvery,
  takeLatest,
} from "redux-saga/effects";
import {
  PortalsFrontendApiPhishingDeprecatedAppDetailResponseContentFullPage as DetailResponseContentFullPage,
  DetailResponseURLFull,
} from "@csis.com/tip/src/api/openapi/data-contracts";
import { handleRequestError } from "@csis.com/tip/src/api/utils";
import { isString } from "@csis.com/tip/src/models/helpers";
import { getSelectedOrgId } from "../../../Profile/Security/selectors";
import {
  fetchCaptureApi,
  fetchContentApi,
  fetchHtmlApi,
  fetchUrlApi,
} from "./api/api";
import { ContentCapture, ContentQueryParams, Url, UrlContent } from "./types";

interface StateSlice {
  url: Url | null;
  isPending: boolean;
  fetchError: string | null;

  content: UrlContent[] | null;
  isContentPending: boolean;
  fetchContentError: string | null;
  hasNextContentPage: boolean;

  mainCapture: string | null;
  isMainCapturePending: boolean;
  mainCaptureError: string | null;

  contentCapture: ContentCapture | null;
}
const initialState: StateSlice = {
  url: null,
  isPending: false,
  fetchError: null,

  content: null,
  isContentPending: false,
  fetchContentError: null,
  hasNextContentPage: false,

  mainCapture: null,
  isMainCapturePending: false,
  mainCaptureError: null,

  contentCapture: null,
};

const urlSlice = createSlice({
  name: "url",
  initialState: initialState,
  reducers: {
    fetchUrlById(_state, _action: PayloadAction<{ id: string }>) {
      //empty handled by saga
    },
    setPending(state) {
      state.isPending = true;
      state.fetchError = null;
    },
    setFetchError(state, action: PayloadAction<string>) {
      state.isPending = false;
      state.fetchError = action.payload;
    },
    fetchSuccess(state, action: PayloadAction<Url>) {
      state.isPending = false;
      state.url = action.payload;
      state.fetchError = null;
    },

    fetchContentById(
      _state,
      _action: PayloadAction<{ id: string; queryParams: ContentQueryParams }>
    ) {
      //empty handled by saga
    },
    setContentPending(state) {
      state.isContentPending = true;
      state.fetchContentError = null;
    },
    setContentFetchError(state, action: PayloadAction<string>) {
      state.isContentPending = false;
      state.fetchContentError = action.payload;
    },
    fetchContentSuccess(state, action: PayloadAction<UrlContent[]>) {
      state.isContentPending = false;
      state.content = action.payload;
      state.fetchContentError = null;
    },
    setHasContentNextPage(state, action: PayloadAction<boolean>) {
      state.hasNextContentPage = action.payload;
    },

    fetchMainCapture(
      _state,
      _action: PayloadAction<{ captureSource: string }>
    ) {
      //empty handled by saga
    },
    setMainCapturePending(state) {
      state.mainCapture = null;
      state.isMainCapturePending = true;
      state.mainCaptureError = null;
    },
    setMainCaptureError(state, action: PayloadAction<string>) {
      state.mainCapture = null;
      state.isMainCapturePending = false;
      state.mainCaptureError = action.payload;
    },
    setMainCapture(state, action: PayloadAction<string | null>) {
      state.mainCapture = action.payload;
      state.isMainCapturePending = false;
      state.mainCaptureError = null;
    },

    fetchContentCapture(
      _state,
      _action: PayloadAction<{
        captureSource: string;
        contentId: string;
        type: "html" | "image";
      }>
    ) {
      //empty handled by saga
    },
    setContentCapturePending(
      state,
      action: PayloadAction<{
        contentId: string;
        type: "image" | "html";
      }>
    ) {
      const contentId = action.payload.contentId;
      const currentState = current(state);
      const prevContentInfo = currentState.contentCapture?.[contentId];

      const newContentInfo =
        action.payload.type === "image"
          ? {
              capturePending: true,
              captureError: null,
            }
          : {
              htmlPending: true,
              htmlError: null,
            };

      const newEntryForContentInfo = { ...prevContentInfo, ...newContentInfo };

      const newState = {
        ...state.contentCapture,
        [contentId]: newEntryForContentInfo,
      };

      state.contentCapture = newState;
    },
    setContentCaptureError(
      state,
      action: PayloadAction<{
        contentId: string;
        error: string | null;
        type: "image" | "html";
      }>
    ) {
      const contentId = action.payload.contentId;
      const currentState = current(state);
      const prevContentInfo = currentState.contentCapture?.[contentId];

      const newContentInfo =
        action.payload.type === "image"
          ? {
              capturePending: false,
              captureError: action.payload.error,
            }
          : {
              htmlPending: false,
              htmlError: action.payload.error,
            };

      const newEntryForContentInfo = { ...prevContentInfo, ...newContentInfo };

      const newState = {
        ...state.contentCapture,
        [contentId]: newEntryForContentInfo,
      };

      state.contentCapture = newState;
    },
    setContentCapture(
      state,
      action: PayloadAction<{
        contentId: string;
        capture: string | null;
        type: "image" | "html";
      }>
    ) {
      const contentId = action.payload.contentId;
      const currentState = current(state);
      const prevContentInfo = currentState.contentCapture?.[contentId];

      const newContentInfo =
        action.payload.type === "image"
          ? {
              capture: action.payload.capture,
              capturePending: false,
              captureError: null,
            }
          : {
              html: action.payload.capture,
              htmlPending: false,
              htmlError: null,
            };

      const newEntryForContentInfo = { ...prevContentInfo, ...newContentInfo };

      const newState = {
        ...state.contentCapture,
        [contentId]: newEntryForContentInfo,
      };

      state.contentCapture = newState;
    },
  },
});

export default urlSlice.reducer;

export const {
  fetchUrlById,
  setPending,
  setFetchError,
  fetchSuccess,

  fetchContentById,
  setContentPending,
  setContentFetchError,
  fetchContentSuccess,
  setHasContentNextPage,

  fetchMainCapture,
  setMainCapture,
  setMainCapturePending,
  setMainCaptureError,

  fetchContentCapture,
  setContentCapturePending,
  setContentCaptureError,
  setContentCapture,
} = urlSlice.actions;

// Async stuff - sagas

function* fetchUrlByIdSaga(action: PayloadAction<{ id: string }>) {
  yield put(setPending());
  try {
    const selectedOrgId: string = yield select(getSelectedOrgId);

    const response: AxiosResponse<DetailResponseURLFull> = yield call(
      fetchUrlApi,
      action.payload.id,
      selectedOrgId
    );

    yield put(fetchSuccess(response.data.payload));
  } catch (e) {
    const errorMessage = handleRequestError(e);
    yield put(setFetchError(errorMessage));
  }
}

function* fetchContentByIdSaga(
  action: PayloadAction<{ id: string; queryParams: ContentQueryParams }>
) {
  yield put(setContentPending());
  try {
    const selectedOrgId: string = yield select(getSelectedOrgId);

    const response: AxiosResponse<DetailResponseContentFullPage> = yield call(
      fetchContentApi,
      action.payload.id,
      action.payload.queryParams,
      selectedOrgId
    );

    yield put(fetchContentSuccess(response.data.payload.page));
    yield put(setHasContentNextPage(response.data.payload.has_next));
  } catch (e) {
    const errorMessage = handleRequestError(e);
    yield put(setContentFetchError(errorMessage));
  }
}

function* fetchMainCaptureSaga(
  action: PayloadAction<{ captureSource: string }>
) {
  yield put(setMainCapturePending());
  try {
    const response: AxiosResponse<Blob> = yield call(
      fetchCaptureApi,
      action.payload.captureSource
    );

    const captureAsPath = response.data
      ? URL.createObjectURL(response.data)
      : null;

    yield put(setMainCapture(captureAsPath));
  } catch (e) {
    const errorMessage = handleRequestError(e);
    yield put(setMainCaptureError(errorMessage));
  }
}

function* fetchContentCaptureSaga(
  action: PayloadAction<{
    captureSource: string;
    contentId: string;
    type: "html" | "image";
  }>
) {
  yield put(
    setContentCapturePending({
      contentId: action.payload.contentId,
      type: action.payload.type,
    })
  );
  try {
    if (action.payload.type === "image") {
      const response: AxiosResponse<Blob> = yield call(
        fetchCaptureApi,
        action.payload.captureSource
      );

      // response.data.type === "image/png"
      const captureAsPath = response.data
        ? URL.createObjectURL(response.data)
        : null;

      yield put(
        setContentCapture({
          capture: captureAsPath,
          contentId: action.payload.contentId,
          type: "image",
        })
      );
    } else {
      const response: AxiosResponse<string> = yield call(
        fetchHtmlApi,
        action.payload.captureSource
      );

      const responseHtml = isString(response.data)
        ? response.data
        : "Something went wrong";

      yield put(
        setContentCapture({
          capture: responseHtml,
          contentId: action.payload.contentId,
          type: "html",
        })
      );
    }
  } catch (e) {
    const errorMessage = handleRequestError(e);
    yield put(
      setContentCaptureError({
        error: errorMessage,
        contentId: action.payload.contentId,
        type: action.payload.type,
      })
    );
  }
}

function* actionWatcher() {
  yield takeLatest(fetchUrlById.toString(), fetchUrlByIdSaga);
  yield takeLatest(fetchContentById.toString(), fetchContentByIdSaga);
  yield takeLatest(fetchMainCapture.toString(), fetchMainCaptureSaga);
  yield takeEvery(fetchContentCapture.toString(), fetchContentCaptureSaga);
}

export function* urlSagas() {
  yield all([actionWatcher()]);
}
