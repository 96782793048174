import { ccKeys } from "../constants";
import { QueryParams } from "../types";

// mapper for api requests to transform start_date and end_date to appropriate query params
export function mapCcStartAndEndDateToCorrectType(
  queryParams: Partial<QueryParams>
) {
  return Object.assign({}, queryParams, {
    [ccKeys.SUBMITTED_AFTER]: queryParams[ccKeys.START_DATE],
    [ccKeys.SUBMITTED_BEFORE]: queryParams[ccKeys.END_DATE],
  });
}
