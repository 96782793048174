import { axiosCsisApi } from "@csis.com/tip/src/App";
import { mapTicketsStartAndEndDateToCorrectType } from "@csis.com/tip/src/pages/Tickets/TicketsSearch/api/utils";
import { QueryParams } from "../../types";

export async function fetchStatusStatisticsApi(
  queryParams: Partial<QueryParams>,
  organizationId: string
) {
  const params = mapTicketsStartAndEndDateToCorrectType(queryParams);

  return axiosCsisApi.getStatisticsStatusApi10TicketStatisticsStatusGet({
    ...params,
    organization_id: organizationId,
  });
}
