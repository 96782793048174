import { axiosCsisApi } from "@csis.com/tip/src/App";
import { STRINGIFY_QUERY_PARAMS } from "@csis.com/tip/src/api/utils";

export async function fetchAsnsApi(
  countries: string[],
  organizationId: string
) {
  const params = { countries: countries };

  return axiosCsisApi.getAsnsApi10PhishingAsnGet(
    {
      ...params,
      organization_id: organizationId,
    },
    STRINGIFY_QUERY_PARAMS
  );
}
