import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { AxiosResponse } from "axios";
import { all, call, put, select, takeLatest } from "redux-saga/effects";
import {
  PortalsFrontendApiReportingDeprecatedRouterListResponseReportType as ListResponseReportType,
  PortalsFrontendApiReportingDeprecatedModelReportType as ReportType,
} from "@csis.com/tip/src/api/openapi/data-contracts";
import { getSelectedOrgId } from "../../../Profile/Security/selectors";
import { fetchReportsTypesApi } from "./api/api";

interface StateSlice {
  reportTypes: ReportType[];
}
const initialState: StateSlice = {
  reportTypes: [],
};

const reportTypesSlice = createSlice({
  name: "reportTypes",
  initialState: initialState,
  reducers: {
    fetchReportTypes() {
      //empty handled by saga
    },
    fetchSuccess(state, action: PayloadAction<ReportType[]>) {
      state.reportTypes = action.payload;
    },
  },
});

export default reportTypesSlice.reducer;

export const { fetchReportTypes, fetchSuccess } = reportTypesSlice.actions;

// Async stuff - sagas

function* fetchReportTypesSaga() {
  try {
    const selectedOrgId: string = yield select(getSelectedOrgId);
    const response: AxiosResponse<ListResponseReportType> = yield call(
      fetchReportsTypesApi,
      { organization_id: selectedOrgId }
    );

    const reportTypes = response.data.payload;
    yield put(fetchSuccess(reportTypes));
  } catch (e) {}
}

function* actionWatcher() {
  yield takeLatest(fetchReportTypes.toString(), fetchReportTypesSaga);
}

export function* reportTypesSagas() {
  yield all([actionWatcher()]);
}
