import { axiosCsisApi } from "@csis.com/tip/src/App";
import { STRINGIFY_QUERY_PARAMS } from "@csis.com/tip/src/api/utils";
import { PortalsFrontendApiReportingDeprecatedModelReportRequest as ReportRequest } from "../../../api/openapi/data-contracts";
import { QueryParams } from "../types";
import { mapReportsStartAndEndDateToCorrectType } from "./utils";

export async function fetchReportsApi(
  queryParams: Partial<QueryParams>,
  organizationId: string
) {
  const params = mapReportsStartAndEndDateToCorrectType(queryParams);

  return axiosCsisApi.getReportsApi10ReportGet(
    {
      ...params,
      organization_id: organizationId,
    },
    STRINGIFY_QUERY_PARAMS
  );
}

export async function downloadReportApi(id: string, organizationId: string) {
  return axiosCsisApi.downloadLatestReportApi10ReportReportExternalIdDownloadGet(
    { reportExternalId: id, organization_id: organizationId },
    {
      format: "blob",
    }
  );
}

export async function generateReportApi(
  newReport: ReportRequest,
  organizationId: string
) {
  return axiosCsisApi.generateReportApi10ReportPost(
    { organization_id: organizationId },
    newReport
  );
}
