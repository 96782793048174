import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { AxiosResponse } from "axios";
import { all, call, put, select, takeLatest } from "redux-saga/effects";
import {
  PortalsFrontendApiReportingDeprecatedRouterListResponseRequestOption as ListResponseRequestOption,
  PortalsFrontendApiReportingDeprecatedModelRequestOption as RequestOption,
} from "@csis.com/tip/src/api/openapi/data-contracts";
import { getSelectedOrgId } from "../../Profile/Security/selectors";
import { fetchNewReportOptionsApi } from "./api/api";

interface StateSlice {
  newReportOptions: RequestOption[];
}
const initialState: StateSlice = {
  newReportOptions: [],
};

const newReportOptionsSlice = createSlice({
  name: "newReportOptions",
  initialState: initialState,
  reducers: {
    fetchNewReportOptions() {
      //empty handled by saga
    },
    fetchSuccess(state, action: PayloadAction<RequestOption[]>) {
      state.newReportOptions = action.payload;
    },
  },
});

export default newReportOptionsSlice.reducer;

export const { fetchNewReportOptions, fetchSuccess } =
  newReportOptionsSlice.actions;

// Async stuff - sagas

function* fetchNewReportOptionsSaga() {
  try {
    const selectedOrgId: string = yield select(getSelectedOrgId);
    const response: AxiosResponse<ListResponseRequestOption> = yield call(
      fetchNewReportOptionsApi,
      { organization_id: selectedOrgId }
    );

    const newReportOptions = response.data.payload;
    yield put(fetchSuccess(newReportOptions));
  } catch (e) {}
}

function* actionWatcher() {
  yield takeLatest(fetchNewReportOptions.toString(), fetchNewReportOptionsSaga);
}

export function* newReportOptionsSagas() {
  yield all([actionWatcher()]);
}
