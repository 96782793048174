import { axiosCsisApi } from "@csis.com/tip/src/App";
import { QueryParams } from "../../types";
import { addGroupByParamBasedOnRange } from "./utils";

export async function fetchMatcherStatisticsHistogramApi(
  queryParams: Partial<QueryParams>,
  organizationId: string
) {
  const params = addGroupByParamBasedOnRange(queryParams);

  return axiosCsisApi.getVictimHistogramApi10CompromisedDataCredentialReportStatisticsMatcherHistogramGet(
    {
      ...params,
      organization_id: organizationId,
    }
  );
}
