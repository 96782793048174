import classNames from "classnames";
import { RADIUS_BIG, RADIUS_NORMAL, RADIUS_SMALL } from "../../radius";
import { LARGE, NORMAL, SMALL } from "../../sizes";
import GhostRows from "./GhostRows";
import TableError from "./TableError/TableError";
import TableHeader from "./TableHeader";
import TableRows from "./TableRows";
import * as types from "./types";

export interface TableInterface<
  ROW extends types.RowInterface, // defines the type of the table row
  COLUMN_KEY extends string, // defines the type of the keys of the columns
  COLUMN_NAME extends string // is the subset of accepted strings that define the column names + their translation/transform function
> {
  name: string;
  size?: SMALL | NORMAL | LARGE;
  radius?: RADIUS_SMALL | RADIUS_NORMAL | RADIUS_BIG;
  isZebra?: boolean;
  isSolid?: boolean;
  isSelectable?: boolean;
  isBookmarkable?: boolean;
  isBorderless?: boolean;
  isFullWidth?: boolean;
  hasHeader?: boolean;
  rows?: ROW[];
  emptyTableText?: string;
  // the order of this array is the source of truth of the cell order
  columns?: types.ColumnInterface<COLUMN_KEY, COLUMN_NAME>[];
  formatters?: types.TableCellFormatters<COLUMN_KEY>;
  selectedRows?: types.SelectedRows;
  bookmarkedRows?: types.BookmarkedRows;
  onSelectRow?: types.OnSelectRow;
  onSelectAllRows?: types.OnSelectAllRows;
  onRowClick?: types.OnRowClick<ROW>;
  onHeaderSettingsClick?: () => void;
  onSortByColumn?: types.OnSortByColumn<COLUMN_KEY>;
  isLoading?: boolean;

  // subelements are elements that are nested under a row
  // they are not part of the row itself, but are shown when the row is expanded
  // here we define the "key" of the column that will be used to show the subelement
  subElementsConfig?: types.SubElementsConfig<COLUMN_KEY>;

  ghostRows?: number;
  // table cells have a max-width property, use this for when you dont want that
  hasUnlimitedCellWidth?: boolean;
  columnNameTransform?: (columnName: COLUMN_NAME) => string; // can be used for translations
  error?: string | null;
  dataTestId?: string;
}

const Table = <
  ROW extends types.RowInterface,
  COLUMN_KEY extends string,
  COLUMN_NAME extends string
>({
  name,
  rows,
  columns,
  formatters,
  isZebra,
  isSelectable,
  isBookmarkable,
  isFullWidth,
  isSolid,
  size = "normal",
  radius = "big",
  hasHeader = true,
  emptyTableText,
  isBorderless,
  selectedRows,
  bookmarkedRows,
  onSelectRow,
  onSelectAllRows,
  onSortByColumn,
  onRowClick,
  subElementsConfig = false,
  isLoading,
  onHeaderSettingsClick,
  ghostRows,
  hasUnlimitedCellWidth,
  columnNameTransform,
  error,
  dataTestId,
}: TableInterface<ROW, COLUMN_KEY, COLUMN_NAME>) => {
  const classes = classNames("table", {
    "table--zebra": isZebra,
    "table--solid": isSolid,
    "table--small": size === "small",
    "table--large": size === "large",
    "table--borderless": isBorderless,
    "table--full-width": isFullWidth,
    "table--no-cell-max-width": hasUnlimitedCellWidth,
    [`table--radius-${radius}`]: radius,
  });

  let colSpan = columns?.filter((column) => !column.isHidden).length || 0;
  if (subElementsConfig) {
    colSpan = colSpan + 1;
  }

  return (
    <table className={classes} data-test-id={dataTestId}>
      {hasHeader && (
        <TableHeader<ROW, COLUMN_KEY, COLUMN_NAME>
          isSelectable={isSelectable}
          columns={columns}
          tableName={name}
          rows={rows}
          size={size}
          subElementsConfig={subElementsConfig}
          selectedRows={selectedRows}
          onSelectAllRows={onSelectAllRows}
          onSortByColumn={onSortByColumn}
          dataTestId={dataTestId}
          columnNameTransform={columnNameTransform}
          onHeaderSettingsClick={onHeaderSettingsClick}
        />
      )}
      <tbody>
        {!isLoading && !error && (
          <TableRows<ROW, COLUMN_KEY>
            isSelectable={isSelectable}
            isBookmarkable={isBookmarkable}
            tableName={name}
            size={size}
            columns={columns}
            colSpan={colSpan}
            rows={rows}
            subElementsConfig={subElementsConfig}
            formatters={formatters}
            selectedRows={selectedRows}
            bookmarkedRows={bookmarkedRows}
            onSelectRow={onSelectRow}
            onRowClick={onRowClick}
            dataTestId={dataTestId}
          />
        )}

        {isLoading && !error && (
          <GhostRows
            columns={columns}
            isSelectable={isSelectable}
            ghostRows={ghostRows}
          />
        )}
        {error && <TableError error={error} colSpan={colSpan} />}
        {!isLoading && !error && rows?.length === 0 && (
          <tr>
            <td colSpan={colSpan}>
              <div className="table__empty-placeholder">
                {emptyTableText ? emptyTableText : "There is nothing here"}
              </div>
            </td>
          </tr>
        )}
      </tbody>
    </table>
  );
};

export default Table;
