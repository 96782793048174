import { axiosCsisApi } from "@csis.com/tip/src/App";
import { STRINGIFY_QUERY_PARAMS } from "@csis.com/tip/src/api/utils";
import { QueryParams } from "../types";
import { mapUrlsStartAndEndDateToCorrectType2 } from "./utils";

export async function fetchUrlsApi(
  queryParams: Partial<QueryParams>,
  organizationId: string
) {
  const params = mapUrlsStartAndEndDateToCorrectType2(queryParams);

  return axiosCsisApi.getUrlsApi10PhishingUrlGet(
    {
      ...params,
      organization_id: organizationId,
    },
    STRINGIFY_QUERY_PARAMS
  );
}

export async function exportUrlsCsvApi(
  queryParams: Partial<QueryParams>,
  organizationId: string
) {
  const params = mapUrlsStartAndEndDateToCorrectType2(queryParams);

  return axiosCsisApi.exportUrlsApi10PhishingUrlExportCsvGet(
    {
      ...params,
      organization_id: organizationId,
    },
    {
      format: "blob",
      ...STRINGIFY_QUERY_PARAMS,
    }
  );
}

export async function bulkPostUrlsApi(url: string[]) {
  return axiosCsisApi.postUrlsApi10PhishingUrlPost(
    { url },
    STRINGIFY_QUERY_PARAMS
  );
}
