import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { AxiosResponse } from "axios";
import { all, call, put, select, takeLatest } from "redux-saga/effects";
import {
  DetailResponseLegacyStatsResponse,
  LegacyStatsResponse,
} from "@csis.com/tip/src/api/openapi/data-contracts";
import { getSelectedOrgId } from "../../../../Profile/Security/selectors";
import { QueryParams } from "../types";
import { fetchLecacyStatsApi } from "./api/api";

interface StateSlice {
  legacyStats: LegacyStatsResponse | null;
  isLegacyStatsPending: boolean;
  legacyStatsFetchError: string | null;
}
const initialState: StateSlice = {
  legacyStats: null,
  isLegacyStatsPending: false,
  legacyStatsFetchError: null,
};

const urlsLegacyStatsSlice = createSlice({
  name: "urlLegacyStats",
  initialState: initialState,
  reducers: {
    fetchLegacyStats(_state, _action: PayloadAction<Partial<QueryParams>>) {
      //empty handled by saga
    },
    setIsLegacyStatsPending(state) {
      state.isLegacyStatsPending = true;
      state.legacyStatsFetchError = null;
    },
    setLegacyStatsFetchError(state) {
      state.isLegacyStatsPending = false;
      state.legacyStatsFetchError = "Something went wrong";
    },
    setFetchLegacyStatsSuccess(
      state,
      action: PayloadAction<LegacyStatsResponse>
    ) {
      state.isLegacyStatsPending = false;
      state.legacyStats = action.payload;
      state.legacyStatsFetchError = null;
    },
  },
});

export default urlsLegacyStatsSlice.reducer;

export const {
  fetchLegacyStats,
  setIsLegacyStatsPending,
  setLegacyStatsFetchError,
  setFetchLegacyStatsSuccess,
} = urlsLegacyStatsSlice.actions;

// Async stuff - sagas

function* fetchLegacyStatsSaga(action: PayloadAction<Partial<QueryParams>>) {
  yield put(setIsLegacyStatsPending());

  try {
    const selectedOrgId: string = yield select(getSelectedOrgId);
    const response: AxiosResponse<DetailResponseLegacyStatsResponse> =
      yield call(fetchLecacyStatsApi, action.payload, selectedOrgId);
    yield put(setFetchLegacyStatsSuccess(response.data.payload));
  } catch (e) {
    yield put(setLegacyStatsFetchError());
  }
}

function* actionWatcher() {
  yield takeLatest(fetchLegacyStats.toString(), fetchLegacyStatsSaga);
}

export function* urlsLegacyStatsSagas() {
  yield all([actionWatcher()]);
}
