import { axiosCsisApi } from "@csis.com/tip/src/App";
import { UpdateUserBody } from "@csis.com/tip/src/api/openapi/data-contracts";

export async function fetchUsersApi() {
  return axiosCsisApi.getUsersApi10OrganizationUserGet();
}

export async function postUserApi(data: UpdateUserBody) {
  return axiosCsisApi.createUserApi10OrganizationUserPost(data);
}

export async function updateUserApi(
  external_id: string,
  data: UpdateUserBody,
  organizationId: string
) {
  return axiosCsisApi.updateUserApi10OrganizationUserExternalIdPut(
    { externalId: external_id, organization_id: organizationId },
    data
  );
}

export async function deleteUserApi(
  external_id: string,
  organizationId: string
) {
  return axiosCsisApi.deleteUserApi10OrganizationUserExternalIdDelete({
    externalId: external_id,
    organization_id: organizationId,
  });
}

export async function unDeleteUserApi(
  external_id: string,
  organizationId: string
) {
  return axiosCsisApi.undeleteUserApi10OrganizationUserExternalIdUndeletePatch({
    externalId: external_id,
    organization_id: organizationId,
  });
}
