import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { AxiosResponse } from "axios";
import { all, call, put, select, takeLatest } from "redux-saga/effects";
import { axiosCsisApi } from "@csis.com/tip/src/App";
import { PortalsFrontendApiAlertsDeprecatedAppAlertPreviewPageDetailResponse as AlertPreviewPageDetailResponse } from "@csis.com/tip/src/api/openapi/data-contracts";
import {
  STRINGIFY_QUERY_PARAMS,
  handleRequestError,
} from "@csis.com/tip/src/api/utils";
import { LatestSearches } from "@csis.com/tip/src/components/shared/SearchComboInput/AdvancedSearchCard/types";
import { PredefinedResultsPerPage } from "@csis.com/tip/src/models/pagination/constants";
import {
  getUserPreferences,
  getUserPreferencesForAlerts,
} from "@csis.com/tip/src/userPreferences/selectors";
import {
  updateUserPreferences,
  fetchSuccess as userPreferencesFetchSuccess,
} from "@csis.com/tip/src/userPreferences/slice";
import {
  SearchPagePreferences,
  UserPreferences,
} from "@csis.com/tip/src/userPreferences/types";
import {
  generateColumnsBasedOnSettings,
  generateColumnsOrderSetting,
  generateColumnsVisibilitySetting,
} from "@csis.com/tip/src/userPreferences/utils";
import { downloadBlobForUser } from "@csis.com/tip/src/utils/downloadBlob";
import { getSelectedOrgId } from "../../Profile/Security/selectors";
import { AlertsColumns, columns } from "./Table/columns";
import { mapAlertsStartAndEndDateToCorrectType } from "./api/utils";
import { getAlertsColumns } from "./selectors";
import { AlertRow, QueryParams } from "./types";

interface StateSlice {
  alerts: AlertRow[] | null;
  hasNextPage: boolean;
  isAlertsPending: boolean;
  alertsFetchError: string | null;
  columns: AlertsColumns;
  isCsvExportPending: boolean;
}
const initialState: StateSlice = {
  alerts: null,
  hasNextPage: false,
  isAlertsPending: false,
  alertsFetchError: null,
  columns: columns,
  isCsvExportPending: false,
};

const alertsSlice = createSlice({
  name: "alerts",
  initialState: initialState,
  reducers: {
    fetchAlerts(_state, _action: PayloadAction<Partial<QueryParams>>) {
      //empty handled by saga
    },
    setAlertsPending(state) {
      state.isAlertsPending = true;
      state.alertsFetchError = null;
      state.alerts = [];
    },
    setFetchAlertsError(state, action: PayloadAction<string>) {
      state.isAlertsPending = false;
      state.alertsFetchError = action.payload;
      state.alerts = [];
    },
    fetchAlertsSuccess(state, action: PayloadAction<AlertRow[]>) {
      state.isAlertsPending = false;
      state.alertsFetchError = null;
      state.alerts = action.payload;
    },
    setHasNextPage(state, action: PayloadAction<boolean>) {
      state.hasNextPage = action.payload;
    },
    reorderColumns(state, action: PayloadAction<AlertsColumns>) {
      state.columns = action.payload;
    },
    setResultsPerPageUserPreference(
      _state,
      _action: PayloadAction<PredefinedResultsPerPage>
    ) {
      //empty handled by saga
    },

    exportAlertsCsv(_state, _action: PayloadAction<Partial<QueryParams>>) {
      //empty handled by saga
    },
    setCsvExportPending(state) {
      state.isCsvExportPending = true;
    },
    setCsvExportComplete(state) {
      state.isCsvExportPending = false;
    },
    setLatestSearchesUserPreference(
      _state,
      _action: PayloadAction<LatestSearches>
    ) {
      //empty handled by saga
    },
  },
});

export default alertsSlice.reducer;

export const {
  fetchAlerts,
  setAlertsPending,
  setFetchAlertsError,
  fetchAlertsSuccess,
  setHasNextPage,
  reorderColumns,
  setResultsPerPageUserPreference,
  exportAlertsCsv,
  setCsvExportPending,
  setCsvExportComplete,
  setLatestSearchesUserPreference,
} = alertsSlice.actions;

// Async stuff - sagas

function* fetchAlertsSaga(action: PayloadAction<Partial<QueryParams>>) {
  yield put(setAlertsPending());

  try {
    const selectedOrgId: string = yield select(getSelectedOrgId);
    const response: AxiosResponse<AlertPreviewPageDetailResponse> = yield call(
      axiosCsisApi.getAlertsApi10AlertGet,
      {
        organization_id: selectedOrgId,
        ...mapAlertsStartAndEndDateToCorrectType(action.payload),
      },
      STRINGIFY_QUERY_PARAMS
    );

    yield put(fetchAlertsSuccess(response.data.payload.page as AlertRow[]));
    yield put(setHasNextPage(response.data.payload.has_next));
  } catch (e) {
    const errorMessage = handleRequestError(e);
    yield put(setFetchAlertsError(errorMessage));
  }
}

function* exportAlertsCsvSaga(action: PayloadAction<Partial<QueryParams>>) {
  yield put(setCsvExportPending());

  try {
    const selectedOrgId: string = yield select(getSelectedOrgId);
    const response: AxiosResponse<Blob> = yield call(
      axiosCsisApi.getExportCsvApi10AlertExportCsvGet,
      {
        ...mapAlertsStartAndEndDateToCorrectType(action.payload),
        organization_id: selectedOrgId,
      },
      {
        format: "blob",
        ...STRINGIFY_QUERY_PARAMS,
      }
    );

    const blob = response.data;
    downloadBlobForUser(blob, "alerts_csv.csv");

    yield put(setCsvExportComplete());
  } catch (e) {
    yield put(setCsvExportComplete());
  }
}

function* reactToColumnsUpdateAndUpdateUserPreferencesSaga(
  action: PayloadAction<AlertsColumns>
) {
  // the user updated the columns so, we "update"
  // the prefs server side on the background
  const newColumns = action.payload;
  const newColumnsOrderSettings = generateColumnsOrderSetting(newColumns);
  const newColumnsVisibilitySettings =
    generateColumnsVisibilitySetting(newColumns);
  const userPrefs: UserPreferences = yield select(getUserPreferences);

  if (userPrefs) {
    const newPrefs = { ...userPrefs };

    newPrefs.alerts = {
      ...newPrefs.alerts,
      columnsVisibility: newColumnsVisibilitySettings,
      columnsOrder: newColumnsOrderSettings,
    };

    yield put(updateUserPreferences(newPrefs));
  }
}

function* reactToUserPreferencesResponse() {
  const userPrefs: SearchPagePreferences | undefined = yield select(
    getUserPreferencesForAlerts
  );
  const columns: AlertsColumns = yield select(getAlertsColumns);

  if (userPrefs && userPrefs.columnsOrder && userPrefs.columnsVisibility) {
    // generate columns based on the saved user preferences
    const columnsFromSettings = generateColumnsBasedOnSettings(
      userPrefs.columnsOrder,
      userPrefs.columnsVisibility,
      columns
    );

    yield put(reorderColumns(columnsFromSettings));
  }
}

function* setResultsPerPageUserPreferenceSaga(
  action: PayloadAction<PredefinedResultsPerPage>
) {
  const userPrefs: UserPreferences = yield select(getUserPreferences);

  if (userPrefs) {
    const newPrefs = { ...userPrefs };

    newPrefs.alerts = {
      ...newPrefs.alerts,
      resultsPerPage: action.payload,
    };

    yield put(updateUserPreferences(newPrefs));
  }
}

function* setLatestSearchesUserPreferenceSaga(
  action: PayloadAction<LatestSearches>
) {
  const userPrefs: UserPreferences = yield select(getUserPreferences);

  if (userPrefs) {
    const newPrefs = { ...userPrefs };

    newPrefs.alerts = {
      ...newPrefs.alerts,
      latestSearches: action.payload,
    };

    yield put(updateUserPreferences(newPrefs));
  }
}

function* actionWatcher() {
  yield takeLatest(fetchAlerts.toString(), fetchAlertsSaga);
  yield takeLatest(exportAlertsCsv.toString(), exportAlertsCsvSaga);
  yield takeLatest(
    reorderColumns.toString(),
    reactToColumnsUpdateAndUpdateUserPreferencesSaga
  );
  yield takeLatest(
    userPreferencesFetchSuccess.toString(),
    reactToUserPreferencesResponse
  );
  yield takeLatest(
    setResultsPerPageUserPreference.toString(),
    setResultsPerPageUserPreferenceSaga
  );
  yield takeLatest(
    setLatestSearchesUserPreference.toString(),
    setLatestSearchesUserPreferenceSaga
  );
}

export function* alertsSagas() {
  yield all([actionWatcher()]);
}
